import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import type { LexicalEditor } from 'lexical';
import { type FocusEventHandler, useState } from 'react';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { EditorContainer } from './base/common';
import { ExternalOnChangePlugin } from './base/plugins/ExternalOnChangePlugin';
import { UpdateEditorPlugin } from './base/plugins/UpdateEditorPlugin';

export function BasicEditor({
  disabled,
  onError,
  helperText,
  error,
  onChange,
  draftState,
  onBlur,
  onFocus,
  placeholder,
}: {
  draftState?: string;
  disabled?: boolean;
  onError?: (error: Error, editor: LexicalEditor) => void;
  helperText?: string;
  error?: string;
  onChange: (data: { plainText: string; html: string; json: string }) => void;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  placeholder?: string;
}) {
  const [isFocused, setIsFocused] = useState(false);

  const hasError = Boolean(error);

  return (
    <div className="flex flex-col gap-2">
      <EditorContainer hasError={hasError} isFocused={isFocused}>
        <LexicalComposer
          initialConfig={{
            namespace: 'basic-editor',
            editable: !disabled,
            onError: (error, editor) => onError?.(error, editor),
          }}
        >
          <RichTextPlugin
            ErrorBoundary={LexicalErrorBoundary}
            placeholder={
              <TextStyle
                as="span"
                variant="base-regular"
                className="pointer-events-none absolute text-gray-7"
              >
                {placeholder ?? ''}
              </TextStyle>
            }
            contentEditable={
              <ContentEditable
                className="text-base font-normal text-gray-9 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0"
                disabled={disabled}
                ariaLabel={'basic-editor-content-editable'}
                onBlur={(args) => {
                  setIsFocused(false);
                  onBlur?.(args);
                }}
                onFocus={(args) => {
                  setIsFocused(true);
                  onFocus?.(args);
                }}
              />
            }
          />
          <UpdateEditorPlugin draftState={draftState} />
          <ExternalOnChangePlugin
            onChange={onChange}
            ignoreSelectionChange={true}
            ignoreHistoryMergeTagChange={true}
          />
        </LexicalComposer>
      </EditorContainer>
      {Boolean(hasError) && (
        <TextStyle variant="sm-regular" className="text-error-7">
          {error}
        </TextStyle>
      )}
      {Boolean(helperText) && !hasError && (
        <TextStyle variant="sm-regular" className="text-gray-8">
          {helperText}
        </TextStyle>
      )}
    </div>
  );
}
