import type { File } from './blockTypes';
import type { MemberDetails, ReactionDetails } from './flowTypes';

export enum LegacyPostTypes {
  Recognition = 'recognition',
  Birthday = 'birthday',
  Anniversary = 'anniversary',
  Award = 'award',
  Bot = 'bot',
  Welcome = 'welcome',
}

export enum BotPostTypes {
  WelcomeBot = 'WELCOME_BOT',
}

type Recipients = {
  criteriaId: string;
  everyone?: { count: number };
  departments?: {
    name: string;
    count: number;
  }[];
  individuals?: MemberDetails[];
};

type LegacyPost = {
  message: string;
  messageHtml?: string;
  postID: string;
  assemblyID: string;
  /**
   * @deprecated
   */
  to: MemberDetails[];
  recipient?: Recipients;
  taggedUsers: MemberDetails[];
  reactions: ReactionDetails[];
  commentsCount: number;
  pointsEach: number;
  isPrivate: boolean;
  isDeleted: boolean;
  gifURL: string;
  imageURL: string;
  createdAt: string;
  updatedAt: string;
  kind: string;
  editedAt: boolean;
  currencyName: string;
  hasReplyDraft?: boolean;
  isMuted?: boolean;
  attachments?: File[];
  bot?: {
    kind: BotPostTypes;
  };
};

export type RecognitionPost = {
  post: LegacyPost & {
    type: LegacyPostTypes.Recognition;
    from: MemberDetails;
    coreValue: string;
  };
  postDTO?: LegacyPost & {
    type: LegacyPostTypes.Recognition;
    from: MemberDetails;
    coreValue: string;
  };
};

export type BirthdayPost = {
  post: LegacyPost & {
    type: LegacyPostTypes.Birthday;
    from: null;
    coreValue: null;
  };
};

export type WorkAnniversaryPost = {
  post: LegacyPost & {
    type: LegacyPostTypes.Anniversary;
    from: null;
    coreValue: null;
  };
};

export type AwardDetail = {
  awardId: string;
  name: string;
  description?: {
    message: string;
    messageHtml: string;
    messageTokens: string;
  };
  image?: {
    location: string;
    name: string;
    size: number;
  };
};

export type AwardPost = {
  post: LegacyPost & {
    type: LegacyPostTypes.Award;
    from: MemberDetails;
    coreValue: string;
    award: AwardDetail;
  };
};

export type BotPost = {
  post: LegacyPost & {
    type: LegacyPostTypes.Bot;
    from: null;
    coreValue: null;
  };
};

export type LegacyPostTypesUnion =
  | RecognitionPost
  | BirthdayPost
  | WorkAnniversaryPost
  | AwardPost
  | BotPost;
