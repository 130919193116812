export function SelectAwardLoader() {
  return (
    <div className="flex flex-col gap-6">
      {[...Array(3)].map((index) => (
        <div className="flex gap-2" key={index}>
          <div className="h-6 w-6 rounded-full bg-gray-4" />
          <div className="flex w-full justify-between gap-2">
            <div
              className="flex w-fit animate-pulse flex-col gap-4"
              key={index}
            >
              <div className="h-4 w-44 rounded-lg bg-gray-4" />
              <div className="h-6 w-16 rounded-full bg-gray-4" />
            </div>
            <div className="h-20 w-40 animate-pulse rounded-2xl bg-gray-4" />
          </div>
        </div>
      ))}
    </div>
  );
}
