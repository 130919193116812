import { defaultAwardImage } from '@assembly-web/assets';
import { type AwardDetail, useDisplayCurrency } from '@assembly-web/services';
import dayjs from 'dayjs';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { CollapsibleContent } from '../../../DesignSystem/Feedback/CollapsibleContent';
import { OverflowText } from '../../../DesignSystem/Feedback/OverflowText';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { RadioGroup } from '../../../DesignSystem/Inputs/RadioGroup';

const messages = defineMessages({
  subTitle: {
    defaultMessage:
      'Give up to <iconTag>{currencyIcon}</iconTag> {amount} each {cycle}',
    id: 'Acyc0J',
  },
  defaultImageAlt: {
    defaultMessage: 'Default Award image',
    id: 'fjU1fT',
  },
  remaining: {
    defaultMessage: '<iconTag>{currencyIcon}</iconTag> 0 remaining',
    id: 'SdQsEy',
  },
  lowBudget: {
    defaultMessage: 'Not enough <iconTag>{currencyIcon}</iconTag> to give',
    id: 'DvxGOr',
  },
  refreshDate: {
    defaultMessage: 'Budget will refresh on {date}',
    id: 'be/eXT',
  },
  day: {
    defaultMessage: 'day',
    id: 'aVqg31',
  },
  week: {
    defaultMessage: 'week',
    id: 'zyFak8',
  },
  month: {
    defaultMessage: 'month',
    id: 'Cu3Cty',
  },
  quarter: {
    defaultMessage: 'quarter',
    id: '46gR9W',
  },
  year: {
    defaultMessage: 'year',
    id: 'd57f4E',
  },
});

export function AwardsList({
  awards,
  selectedAwardId,
  onChange,
  timeZone,
}: {
  selectedAwardId?: string;
  awards: AwardDetail[];
  onChange: (awardId: string) => void;
  timeZone: string;
}) {
  const { formatMessage } = useIntl();
  const currencyIcon = useDisplayCurrency();

  const refreshCycle = {
    daily: formatMessage(messages.day),
    weekly: formatMessage(messages.week),
    monthly: formatMessage(messages.month),
    quarterly: formatMessage(messages.quarter),
    yearly: formatMessage(messages.year),
  };

  return (
    <RadioGroup.Root
      className="flex gap-6 max-sm:mb-[72px]"
      onValueChange={(value: string) => {
        onChange(value);
      }}
    >
      {awards.map((award, index) => {
        const isSelected = award.awardId === selectedAwardId;
        const lowBudget = Boolean(
          award.budget.unitPoints &&
            award.budget.remainingPoints < award.budget.unitPoints
        );
        const noBalance =
          award.budget.totalPoints > 0 && award.budget.remainingPoints <= 0;
        const isDisabled = noBalance || lowBudget;
        const timestamp = dayjs(award.budget.nextRefresh);
        const refreshDate = timestamp.tz(timeZone).format('MMM DD');
        const description = isSelected ? award.post.description?.message : '';

        return (
          <RadioGroup.Item
            key={award.awardId}
            value={award.awardId}
            className="h-6 w-6 flex-shrink-0"
            labelClassName={twMerge(
              'w-full flex justify-between gap-4 overflow-hidden',
              isDisabled && 'text-gray-7 cursor-not-allowed'
            )}
            itemClassName="flex gap-4 w-full items-start"
            disabled={isDisabled}
            dataAttributes={{
              ...(index === 0 && { 'data-focus-on-first-mount': true }),
            }}
          >
            <div className="flex flex-1 flex-col gap-2 overflow-hidden">
              <OverflowText
                variant="base-medium"
                className="truncate max-sm:text-wrap"
                tooltipText={award.name}
              >
                {award.name}
              </OverflowText>
              <OverflowText className="truncate max-sm:text-wrap">
                {formatMessage(messages.subTitle, {
                  currencyIcon,
                  iconTag: () => currencyIcon,
                  amount: award.budget.totalPoints,
                  cycle:
                    refreshCycle[
                      award.budget.refreshCycle.toLowerCase() as keyof typeof refreshCycle
                    ],
                })}
              </OverflowText>
              {isDisabled ? (
                <div className="rounded-lg bg-warning-2 px-2 py-1 text-gray-8">
                  <TextStyle variant="sm-bold">
                    {lowBudget
                      ? formatMessage(messages.lowBudget, {
                          currencyIcon,
                          iconTag: () => currencyIcon,
                        })
                      : formatMessage(messages.remaining, {
                          currencyIcon,
                          iconTag: () => currencyIcon,
                        })}
                  </TextStyle>
                  <TextStyle>
                    {formatMessage(messages.refreshDate, { date: refreshDate })}
                  </TextStyle>
                </div>
              ) : (
                isSelected && <CollapsibleContent content={description} />
              )}
            </div>
            <img
              src={award.post.image?.location ?? defaultAwardImage}
              role="presentation"
              alt=""
              className="h-24 w-40 flex-1 overflow-hidden rounded-lg object-cover"
            />
          </RadioGroup.Item>
        );
      })}
    </RadioGroup.Root>
  );
}
