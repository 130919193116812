import {
  type AssemblyCurrency,
  formatToLocalDateTime,
  isTruthy,
  type MemberDetails,
  MemberState,
  type ReactionDetails,
} from '@assembly-web/services';
import {
  ChatBubbleLeftIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import {
  AnniversaryIcon,
  AnonymousAvatarIcon,
  BirthdayIcon,
  Toolbar,
  type ToolbarItem,
  Tooltip,
  useDeviceInfo,
  useToolbarState,
} from '../../..';
import { Avatar, AvatarSize } from '../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { LineIcon } from '../../../Icons';
import {
  AuthorButton,
  type MemberDetailsForViewProfile,
} from '../../Shared/AuthorButton';
import { cardParentDataAttributes, handleParentClick } from '../Cards/utils';
import { Container } from '../Post/Container';
import {
  ConversationCard,
  type ConversationCardMemberDetails,
} from '../PostAndReplies/ConversationCard';
import { messages } from '../PostAndReplies/messages';
import { PostTypeTag, type PostTypeTagProps } from '../PostTypeTag';

export type ReplyCardProps = {
  content: string;
  commentId: string;
  postOwner: {
    firstName?: string;
    lastName?: string;
    profileStatus: MemberState;
    entityId: string;
    profileImageUrl?: string;
    fullName: string;
    type?: string;
    pronouns?: string;
    email?: string;
    department?: string;
    jobTitle?: string;
    image?: string;
  };
  title?: string;
  handleTitleClick?: () => void;
  flow?: string;
  conversationMemberDetails: ConversationCardMemberDetails<
    MemberDetailsForViewProfile & {
      isAnonymous: false;
      name: string;
    }
  >;
  handleMenuItem: (menu: ToolbarItem) => void;
  handlePostOwnerClick?: () => void;
  toolbarMenuItems: ToolbarItem[];
  handleViewRepliesClick: () => void;
  handleFlowClick?: () => void;
  handleReactionClick: (emoji: ReactionDetails) => void;
  reactions?: ReactionDetails[];
  currentUserId: string;
  commentCount?: number;
  buttonText: string;
  gifURL?: string;
  messageNode?: React.ReactNode;
  postCreatedAt?: string;
  isEdited?: boolean;
  pointsEach?: {
    icon: AssemblyCurrency;
    points?: number;
  };
  notification?: {
    icon: ReactNode;
    text: string;
    cardToolBar?: ToolbarItem[];
    markAsSeen?: () => void;
  };
  onCardClicked: () => void;
  onViewThreadClicked: () => void;
  onSeePostClicked: () => void;
  currencyDetails?: AssemblyCurrency;
  taggedUsers?: MemberDetails[];
  boostedUsers?: { member: MemberDetails; points: number }[];
  onMemberClick: (memberId: string) => void;
  hideFlowName?: boolean;
  translationElement?: ReactNode;
  postType?: PostTypeTagProps['postType'];
};

const labels = defineMessages({
  seePost: {
    defaultMessage: 'See post',
    id: 'TRGq26',
  },
  seeChallenge: {
    defaultMessage: 'See challenge',
    id: 'aQCM2D',
  },
  moreReplies: {
    defaultMessage: `Open thread to see {replyCount} more replies`,
    id: 'x6GBUT',
  },
  oneMoreReply: {
    defaultMessage: `Open thread to see {replyCount} more reply`,
    id: 'Zks2pM',
  },
  youLabel: {
    defaultMessage: 'You',
    id: 'kJ5W29',
  },
  anonymousUserLabel: {
    defaultMessage: 'Anonymous User',
    id: 'GclYG/',
  },
  replyHeader: {
    defaultMessage: `{type, select,
      birthday {<author>{authorName}</author> replied to Happy birthday post}
      anniversary {<author>{authorName}</author> replied to anniversary post}
      award {<author>{authorName}</author> replied to an award}
      other {{isOwnPost, select,
          self {<author>{authorName}</author> replied to your own post{showFlowName, select, true { in <flow>{flowName}</flow>} other {}}}
          sameUser {<author>{authorName}</author> replied to their own post{showFlowName, select, true { in <flow>{flowName}</flow>} other {}}}
          other {<author>{authorName}</author> replied to post by <postOwner>{postOwnerName}</postOwner>{showFlowName, select, true { in <flow>{flowName}</flow>} other {}}}
        }}
    }`,
    id: '4nizL/',
  },
  timestampAndSeePost: {
    defaultMessage: '{timestamp} • {seePostLink}',
    id: '41Vk2O',
  },
});

type OwnerType = 'anonymous' | 'anniversary' | 'birthday';

const avatarIconMap = {
  anonymous: AnonymousAvatarIcon,
  anniversary: AnniversaryIcon,
  birthday: BirthdayIcon,
};

function getRespondentFromMemberDetails(
  details: ReplyCardProps['conversationMemberDetails']
) {
  if (details.isAnonymous) {
    return null;
  }

  return {
    firstName: details.firstName,
    lastName: details.lastName,
    memberID: details.memberID,
    memberState: details.memberState,
    pronouns: details.pronouns,
    email: details.email,
    department: details.department,
    jobTitle: details.jobTitle,
    image: details.image,
  };
}

function getRespondentFromPostOwner(owner: ReplyCardProps['postOwner']) {
  return {
    firstName: owner.firstName ?? owner.fullName.split(' ')[0],
    lastName: owner.lastName ?? (owner.fullName.split(' ')[1] || ''),
    memberID: owner.entityId,
    memberState: owner.profileStatus,
    pronouns: owner.pronouns,
    email: owner.email,
    department: owner.department,
    jobTitle: owner.jobTitle,
    image: owner.profileImageUrl,
  };
}

export function ReplyCard({
  content,
  postOwner,
  commentId,
  flow,
  handleFlowClick,
  conversationMemberDetails,
  handleMenuItem,
  handlePostOwnerClick = () => {},
  toolbarMenuItems,
  handleViewRepliesClick,
  handleReactionClick,
  reactions,
  currentUserId,
  commentCount,
  buttonText,
  gifURL,
  messageNode,
  pointsEach,
  postCreatedAt,
  isEdited,
  onCardClicked,
  onViewThreadClicked,
  onSeePostClicked,
  currencyDetails,
  taggedUsers,
  boostedUsers,
  onMemberClick,
  notification,
  hideFlowName = false,
  translationElement,
  postType,
}: ReplyCardProps) {
  const { formatMessage } = useIntl();
  const { getContainerProps, getToolbarProps } = useToolbarState({
    isAlwaysVisible: true,
  });
  const { deviceType } = useDeviceInfo();
  const isMobile = deviceType === 'mobile';
  let postCreationTime;
  if (postCreatedAt) {
    const createdTimestamp = dayjs(new Date(postCreatedAt)).fromNow();
    const label = messages.ago;
    postCreationTime = formatMessage(label, {
      timeCreatedAt: createdTimestamp,
    });
  }

  return (
    <Container
      onClick={handleParentClick(() => onCardClicked())}
      onKeyDown={handleParentClick((e) => {
        if (e.key === 'Enter' || e.key === 'Space') {
          onCardClicked();
        }
      })}
      {...cardParentDataAttributes}
      {...getContainerProps()}
      className="gap-4 pb-6"
    >
      <header className="flex flex-col-reverse gap-4 px-4 pt-4 @md:flex-row @md:gap-2">
        <div className="flex flex-1 gap-2">
          {!conversationMemberDetails.isAnonymous ? (
            <Avatar
              memberID={conversationMemberDetails.memberID}
              size={AvatarSize.Large}
              name={`${conversationMemberDetails.firstName} ${conversationMemberDetails.lastName}`}
              image={conversationMemberDetails.image}
              className={
                conversationMemberDetails.memberState ===
                MemberState.Deactivated
                  ? 'grayscale'
                  : ''
              }
            />
          ) : (
            <Avatar
              size={AvatarSize.Large}
              name={postOwner.fullName}
              memberID={postOwner.entityId}
              image={avatarIconMap[postOwner.type as OwnerType]}
            />
          )}
          <div className="flex flex-col">
            <div className="flex flex-wrap items-center gap-1 text-sm">
              <div className="h-7 w-7 rounded-full bg-primary-2 p-1">
                <ChatBubbleLeftIcon className="stroke-2 text-primary-9" />
              </div>
              {formatMessage(labels.replyHeader, {
                flowName: flow,
                authorName:
                  conversationMemberDetails.memberID === currentUserId
                    ? formatMessage(labels.youLabel)
                    : !conversationMemberDetails.isAnonymous
                      ? `${conversationMemberDetails.firstName} ${conversationMemberDetails.lastName}`
                      : formatMessage(labels.anonymousUserLabel),
                type: postType || 'other',
                b: (text: ReactNode) => (
                  <TextStyle as="span" variant="xs-bold">
                    {text}
                  </TextStyle>
                ),
                isOwnPost:
                  conversationMemberDetails.memberID === postOwner.entityId
                    ? conversationMemberDetails.memberID === currentUserId
                      ? 'self'
                      : 'sameUser'
                    : 'other',
                postOwnerName:
                  !postOwner.type &&
                  conversationMemberDetails.memberID !== postOwner.entityId
                    ? postOwner.fullName
                    : '',
                flow: () =>
                  isTruthy(flow) ? (
                    <TextStyle
                      variant="sm-medium"
                      data-testid={`flow-for-${commentId}`}
                      className="cursor-pointer text-primary-6 hover:text-primary-5"
                      onClick={handleFlowClick}
                      html={flow}
                    />
                  ) : null,
                author: (chunks: string[]) => (
                  <AuthorButton
                    className="mr-0"
                    label={chunks.join('')}
                    respondent={
                      !conversationMemberDetails.isAnonymous
                        ? getRespondentFromMemberDetails(
                            conversationMemberDetails
                          )
                        : null
                    }
                    onClick={handlePostOwnerClick}
                  />
                ),
                postOwner: () =>
                  !postOwner.type &&
                  conversationMemberDetails.memberID !== postOwner.entityId ? (
                    <AuthorButton
                      className="mr-0"
                      respondent={getRespondentFromPostOwner(postOwner)}
                      onClick={handlePostOwnerClick}
                    />
                  ) : null,
                showFlowName: !hideFlowName,
              })}
            </div>
            {!isMobile && Boolean(postCreatedAt) && (
              <div className="flex flex-wrap gap-1">
                {formatMessage(
                  labels.timestampAndSeePost,
                  {
                    timestamp: (
                      <Tooltip
                        tooltipText={
                          postCreatedAt
                            ? formatToLocalDateTime(postCreatedAt)
                            : ''
                        }
                      >
                        <TextStyle variant="xs-regular" className="text-gray-8">
                          {postCreationTime}
                        </TextStyle>
                      </Tooltip>
                    ),
                    seePostLink: (
                      <TextStyle
                        variant="xs-regular"
                        className="cursor-pointer text-gray-8 underline"
                        onClick={onSeePostClicked}
                      >
                        {formatMessage(
                          flow ? labels.seePost : labels.seeChallenge
                        )}
                      </TextStyle>
                    ),
                  },
                  { ignoreTag: true }
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex w-full items-center justify-between gap-4 @md:w-auto @md:justify-normal @md:self-start">
          {postType ? <PostTypeTag postType={postType} /> : <span />}
          {notification?.cardToolBar?.length ? (
            <Toolbar
              primaryToolbarItems={notification.cardToolBar}
              {...getToolbarProps({
                onMenuItemClick() {
                  notification.markAsSeen?.();
                },
              })}
              secondaryToolbarItems={[]}
              className="w-fit"
            />
          ) : null}
        </div>
      </header>
      <div className="-my-4 pl-[22px] pr-4">
        {commentCount && commentCount > 0 ? (
          <>
            <LineIcon
              className={twMerge(
                'mx-1.5 mb-1 h-3',
                (isMobile || !postCreatedAt) && 'mt-1'
              )}
              strokeLinecap="square"
            />
            <div className="align-center flex gap-3">
              <div className="h-7 w-7 rounded-full bg-primary-2 p-1">
                <ChatBubbleLeftRightIcon className="stroke-2 text-primary-9" />
              </div>
              <TextStyle
                className="cursor-pointer text-sm text-primary-6 underline"
                onClick={onViewThreadClicked}
                variant="base-medium"
              >
                {formatMessage(
                  commentCount > 1 ? labels.moreReplies : labels.oneMoreReply,
                  {
                    replyCount: commentCount,
                  }
                )}
              </TextStyle>
            </div>
            <LineIcon className="mx-1.5 mt-1 h-3" strokeLinecap="round" />
          </>
        ) : (
          <LineIcon
            className={twMerge(
              'mx-1.5 h-4',
              (isMobile || !postCreatedAt) && 'mt-1'
            )}
            strokeLinecap="round"
          />
        )}
      </div>
      <ConversationCard
        className="px-4 pt-1"
        messageNode={messageNode}
        gifURL={gifURL}
        key={commentId}
        cardId={commentId}
        variant="single"
        canShowToolbar={true}
        messageContent={content}
        currentMemberId={currentUserId}
        onReactionClick={handleReactionClick}
        toolbarItems={toolbarMenuItems}
        onMemberClick={onMemberClick}
        memberDetails={conversationMemberDetails}
        onMenuItemClick={handleMenuItem}
        reactions={reactions}
        isGroupedReplyCard={true}
        pointsEach={pointsEach}
        isEdited={isEdited}
        taggedUsers={taggedUsers}
        boost={boostedUsers}
        currencyDetails={currencyDetails}
        translationElement={translationElement}
      />
      <Button
        size="small"
        className="ml-16 w-fit p-3"
        variation="secondaryLite"
        onClick={handleViewRepliesClick}
      >
        {buttonText}
      </Button>
    </Container>
  );
}
