import {
  type FlowPostResponse,
  formatToLocalDateTime,
  MemberState,
} from '@assembly-web/services';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { forwardRef, type ReactNode, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { Avatar, AvatarSize } from '../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { InlineButton } from '../../../DesignSystem/Inputs/InlineButton';
import { AuthorButton } from '../../Shared/AuthorButton';
import { getVisibilityIcon } from '../../Shared/PostCard/PostCardHeader';
import {
  PostCardActions,
  type PostCardProps,
} from '../../Shared/PostCard/PostCardUI';
import type { BoostOptions } from '../Editors/RepliesEditor/RepliesEditor';
import { PostTypeTag, type PostTypeTagProps } from '../PostTypeTag';

export type HeaderProps = {
  respondent: FlowPostResponse['respondent'];
  flowName: FlowPostResponse['flow']['name'];
  createdAt: FlowPostResponse['createdAt'];
  visibility: FlowPostResponse['visibility'];
  flowIcon: FlowPostResponse['flow']['icon']['value'];
  onPostCardHeaderClick: PostCardProps['onPostCardHeaderClick'];
  flowId: string;
  isEdited: boolean;
  showFlowDetails?: boolean;
  openViewInsightsModal?: () => void;
  toolbar?: ReactNode;
  boostOptions?: BoostOptions;
  translationElement?: ReactNode;
  postType?: PostTypeTagProps['postType'];
};

const messages = defineMessages({
  posted: {
    defaultMessage: 'posted',
    id: 'jD4dzv',
  },
  postedIn: {
    defaultMessage: 'posted in',
    id: '19BOio',
  },
  ago: {
    defaultMessage: '{timeCreatedAt}',
    id: 'NhSH4V',
  },
  edited: {
    defaultMessage: 'Edited',
    id: 'OhIqLr',
  },
  anonymous: {
    defaultMessage: 'Anonymous',
    id: 'LXxsbk',
  },
  deactivatedUser: {
    defaultMessage: '(deactivated)',
    id: 'osOx6w',
  },
  customTooltip: {
    defaultMessage: 'Visible to all flow members.',
    id: 'xUCpzp',
  },
  publicTooltip: {
    defaultMessage: 'Visible to everyone in your company.',
    id: 'tR85n9',
  },
  privateTooltip: {
    defaultMessage:
      'Posted privately. Visible to the giver, recipients, and admins.',
    id: 'Ja/XO3',
  },
  title: {
    defaultMessage:
      '<respondent></respondent>{showFlowDetails, select, true {posted in <flowName></flowName>} other {{isAward, select, true {gave an award} other {posted}}}}',
    id: 'UWpCMh',
  },
});

export const Header = forwardRef<HTMLDivElement, HeaderProps>(function Header(
  {
    respondent,
    flowName,
    createdAt,
    visibility,
    flowId,
    isEdited,
    onPostCardHeaderClick,
    boostOptions,
    openViewInsightsModal,
    showFlowDetails,
    toolbar,
    translationElement,
    postType,
  },
  ref
) {
  const { formatMessage } = useIntl();

  const visibilityTooltipText = useMemo(() => {
    if (visibility === 'custom' || visibility === 'onlyParticipants') {
      return formatMessage(messages.customTooltip);
    }
    if (visibility === 'private' || visibility === 'onlyOwners') {
      return formatMessage(messages.privateTooltip);
    }
    return formatMessage(messages.publicTooltip);
  }, [formatMessage, visibility]);

  return (
    <header
      className="flex flex-col-reverse gap-4 p-4 @md:flex-row @md:gap-2"
      ref={ref}
    >
      <div className="flex flex-1 gap-2">
        {respondent === null ? (
          <div className="h-10 w-10 rounded-full bg-error-2" />
        ) : (
          <Avatar
            size={AvatarSize.Large}
            image={respondent.image}
            name={respondent.firstName}
            memberID={respondent.memberID}
            className={twJoin(
              respondent.memberState === MemberState.Deactivated && 'grayscale'
            )}
          />
        )}
        <div className="flex flex-1 flex-col gap-0.5">
          <TextStyle variant="sm-regular">
            {formatMessage(messages.title, {
              showFlowDetails,
              isAward: postType === 'award',
              respondent: () => (
                <AuthorButton
                  wrapName
                  boostOptions={boostOptions}
                  respondent={respondent}
                  textVariant="sm-bold"
                  onClick={() => {
                    if (respondent !== null && respondent.memberID !== '')
                      onPostCardHeaderClick(
                        PostCardActions.PersonClicked,
                        respondent.memberID
                      );
                  }}
                />
              ),
              flowName: () => (
                <InlineButton
                  onClick={() => {
                    if (flowId)
                      onPostCardHeaderClick(
                        PostCardActions.FlowNameClicked,
                        flowId
                      );
                  }}
                >
                  <TextStyle as="span" variant="sm-bold">
                    {flowName}
                  </TextStyle>
                </InlineButton>
              ),
            })}
          </TextStyle>
          <div className="flex flex-wrap items-center gap-1 text-sm text-gray-8">
            <Tooltip tooltipText={formatToLocalDateTime(createdAt)}>
              <button>
                <TextStyle as="span" variant="sm-regular" subdued>
                  {formatMessage(messages.ago, {
                    timeCreatedAt: dayjs(createdAt).fromNow(),
                  })}
                </TextStyle>
              </button>
            </Tooltip>
            <span>•</span>
            <Tooltip tooltipText={visibilityTooltipText}>
              <button aria-label={visibilityTooltipText}>
                <span className="block h-4 w-4">
                  {getVisibilityIcon(visibility)}
                </span>
              </button>
            </Tooltip>
            {Boolean(openViewInsightsModal) && (
              <>
                <span>•</span>
                <button
                  className="h-4 w-4"
                  onClick={openViewInsightsModal}
                  aria-label="View insights"
                >
                  <ChartBarIcon />
                </button>
              </>
            )}
            {Boolean(isEdited) && (
              <>
                <span>•</span>
                <TextStyle variant="xs-regular" subdued>
                  {formatMessage(messages.edited)}
                </TextStyle>
              </>
            )}
            {translationElement}
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-4 @md:w-auto @md:justify-normal @md:self-start">
        {postType ? <PostTypeTag postType={postType} /> : <span />}
        {toolbar}
      </div>
    </header>
  );
});
