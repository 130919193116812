import { lazy, Suspense } from 'react';

import { config } from './config';
import { isTruthy } from './tsUtils';

export function ReactQueryDevTool() {
  const tool = config.devTools.reactQuery;

  const shouldUseRewind = tool === 'all' || tool === 'rewind';
  const shouldUseRq = tool === 'all' || tool === 'rq';

  const TanStackReactQuery = shouldUseRq
    ? lazy(() =>
        import('@tanstack/react-query-devtools').then((module) => ({
          default: module.ReactQueryDevtools,
        }))
      )
    : null;

  const ReactQueryRewind = shouldUseRewind
    ? lazy(() => import('react-query-rewind'))
    : null;

  if (tool === 'none') return;

  return (
    <>
      {isTruthy(ReactQueryRewind) && (
        <Suspense fallback={null}>
          <ReactQueryRewind />
        </Suspense>
      )}
      {isTruthy(TanStackReactQuery) && (
        <Suspense fallback={null}>
          <TanStackReactQuery />
        </Suspense>
      )}
    </>
  );
}
