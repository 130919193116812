import { type ReactionDetails, ReactionTypes } from '@assembly-web/services';
import { FaceSmileIcon } from '@heroicons/react/24/outline';
import { Root as ToggleButton } from '@radix-ui/react-toggle';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { DummyCurrentMember } from '../../Shared/PostCard/data/flowsData';
import { ReactionEmoji } from '../../Shared/Reactions/ReactionEmoji';
import { ReactionPicker } from '../../Shared/Reactions/ReactionPicker';
import { getReactedMembersTooltipText } from '../../Shared/utils';

export type ReactionsBarProps = {
  currentMemberId: string;
  reactions: ReactionDetails[];
  variant: 'default' | 'compact' | 'default-next';
  onReactionClick: (reaction: ReactionDetails) => void;
  className?: string;
};

const messages = defineMessages({
  react: {
    defaultMessage: 'React',
    id: 'uX6eKe',
  },
});

export function ReactionsBar(props: ReactionsBarProps) {
  const {
    reactions,
    currentMemberId,
    onReactionClick,
    className,
    variant = 'default',
  } = props;
  const { formatMessage } = useIntl();
  const disableReactionPicker = reactions.length >= 30;
  return (
    <div
      className={twMerge(
        'mt-2 flex flex-wrap items-center gap-2 self-baseline leading-none',
        className
      )}
    >
      {reactions.map((reaction) => {
        const isSelected = reaction.members.some(
          (i) => i.memberID === currentMemberId
        );
        return (
          <Tooltip
            key={reaction.name}
            tooltipText={getReactedMembersTooltipText(
              reaction,
              currentMemberId,
              formatMessage
            )}
          >
            <ToggleButton
              onPressedChange={() => onReactionClick(reaction)}
              className={twMerge(
                'flex h-fit w-fit items-center border border-gray-5 bg-gray-1 hover:bg-gray-2',
                variant === 'default'
                  ? 'rounded-lg px-3 py-1'
                  : 'rounded-md px-2',
                isSelected &&
                  variant !== 'default-next' &&
                  'border-primary-6 bg-primary-2 hover:bg-primary-2',
                variant === 'default-next' &&
                  'rounded-3xl border-0 bg-primary-1 px-2 py-1'
              )}
              pressed={isSelected}
            >
              <ReactionEmoji
                reaction={reaction}
                count={reaction.members.length}
                isSelected={isSelected}
                className="font-medium text-gray-9"
              />
            </ToggleButton>
          </Tooltip>
        );
      })}
      <ReactionPicker
        onReactionClick={(emoji) => {
          onReactionClick({
            name: emoji.shortcodes,
            value: emoji.id,
            type: ReactionTypes.Regular,
            displayName: emoji.id,
            members: [
              {
                ...DummyCurrentMember,
                memberID: currentMemberId,
              },
            ],
          });
        }}
        triggerButton={
          <div
            className={twMerge(
              'rounded-lg border border-gray-5 bg-gray-1 hover:bg-gray-3',
              variant === 'default' ? 'px-1.5 py-[5px]' : 'p-1',
              variant === 'default-next' &&
                'flex items-center gap-2 rounded-3xl border-0 bg-gray-3 px-2 py-1 hover:bg-gray-2'
            )}
          >
            {variant === 'default-next' && (
              <span className="text-sm font-medium text-gray-9">
                {formatMessage(messages.react)}
              </span>
            )}
            <FaceSmileIcon
              className={twMerge(
                'text-gray-8',
                variant === 'default' && 'h-5 w-5',
                variant === 'compact' && 'h-4 w-4',
                variant === 'default-next' && 'h-6 w-6',
                disableReactionPicker && 'text-gray-7'
              )}
            />
          </div>
        }
        disabled={disableReactionPicker}
        contentType={variant === 'default' ? 'post' : 'reply'}
      />
    </div>
  );
}
