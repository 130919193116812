import camelCase from 'lodash/camelCase';

import type { SSOProvider } from './sso';
import type { ApproveDenyAwardPayload } from './types/award';
import type { FileDownloadRequest } from './types/flowTypes';

export const DoraAPIEndpoints = {
  askDora: '/v1/qna/ask',
  chatDora: '/v1/qna/chat',
  chatReportDora: '/v1/qna/chat/report',
  flowSummary: '/v1/summary/generateSummary',
  collectFeedback: '/v1/qna/feedback',
  getChatHistory: '/v1/qna/chat/history',
  maxFlowSummaryTimeInterval: '/v1/summary/calculateTokenLimitDate',
  searchSuggestedQuestions: '/v3/search/searchQuestions',
  suggestedQuestions: '/v1/qna/suggestedQuestions',
  chatReportHealth: '/v1/qna/chat/report/health',
  generateReportThreadTitle: '/v1/reports/thread/name',
  getThreadDetails: '/v1/reports/thread',
  getThreadsSummary: '/v1/reports/threads',
  getSavedReports: '/v1/reports/reports',
  generateSavedReportTitle: '/v1/reports/report/name',
  savePrompt: '/v1/reports/report',
  recognitionBuilder: '/v1/recognition/builder',
  translateContent: '/v1/translation',
} as const;

export const APIEndpoints = {
  // Onboarding
  generateEmailVerificationCode: '/v3/users/verificationCode',
  verifyEmailVerificationCode: '/v3/users/verify',
  updateUserDetails: '/v3/users/profile',
  verifyGoogleSSO: '/v3/users/identity/gsuite/oneTap',
  verifyEmailWithSSO: (
    name: SSOProvider,
    authorizationFlow: 'login' | 'signup',
    isEnforcedAuth: boolean
  ) =>
    `/v3/users/identity/${camelCase(name)}/sso${
      isEnforcedAuth ? '/enforced' : ''
    }/${authorizationFlow}`,

  accountSetup: '/v3/feed/jobs/accountSetup',
  onboardingJobsList: '/v3/feed/flows/onboardingJob',
  onboardingJobsStatus: (ids: string[]) =>
    `/v3/monitoring/job/status?ids=${ids.join(',')}`,
  updateUserSettings: `/v2/user/settings/updateaccount`,

  assemblySettings: '/v2/employer/employer_info/settings',

  // Workspaces
  listWorkspaces: '/v3/users/workspace',
  createWorkspace: '/v3/assembly/create',
  acceptWorkspaceInvite: '/v3/users/onboard/invite',
  joinWorkspace: '/v3/users/assembly/join',
  loginToWorkspace: '/v3/users/session/login',
  logout: '/v3/users/session/clearCookies',

  userDetails: '/v3/assembly/members/info',
  registerMagicLinkUser: '/v3/users/register/magiclink',
  registerMarketingEvents: '/v2/metrics/events/record',
  assemblyInfoFromSlugShortCode: (slugShortCode: string) =>
    `/v3/assembly/workspace/${slugShortCode}/info`,

  signOut: '/v3/users/session/logout',

  // templates
  listTemplates: '/v3/feed/external/flows/template',
  listCategories: '/v3/feed/external/flows/template/type/category',
  getTemplate: (templateId: string) =>
    `v3/feed/external/flows/template/${templateId}`,
  createFlowWithOneClick: `v3/feed/flows/oneclick`,

  // plans
  getPlanFeatures: '/v3/billing/planFeatures',

  // assembly post oEmbeds
  assemblyFlowPost: ({
    flowId,
    responseId,
  }: {
    flowId: string;
    responseId: string;
  }) => `/v3/feed/flows/${flowId}/response/${responseId}`,
  assemblyRecognitionPost: (responseId: string) =>
    `/v3/feed/posts/${responseId}`,
  assemblyPostReplies: ({
    flowId,
    responseId,
  }: {
    flowId: string;
    responseId: string;
  }) =>
    `/v3/feed/posts/${responseId}/replySummary?type=${
      flowId === 'recognition' ? 'POST' : 'RESPONSE'
    }`,
  assemblyPostReaction: ({
    flowId,
    responseId,
    action,
  }: {
    flowId: string;
    responseId: string;
    action: string;
  }) =>
    flowId === 'recognition'
      ? `/v3/feed/posts/${responseId}/react/${action}`
      : `/v3/feed/flows/${flowId}/response/${responseId}/react/${action}`,

  assemblyCommentReaction: ({
    commentId,
    action,
  }: {
    commentId: string;
    action: string;
  }) => `/v3/feed/comments/${commentId}/react/${action}`,

  assemblyChallengeReaction: ({
    challengeId,
    action,
  }: {
    challengeId: string;
    action: string;
  }) => `/v3/feed/challenges/${challengeId}/react/${action}`,

  searchIndex: '/v3/search/searchindex',
  getFileDownloadURL: (request: FileDownloadRequest) => {
    const { flowId, responseId, blockId, fileName } = request;
    return `v3/feed/flows/${flowId}/response/${responseId}/block/${blockId}/file/${fileName}`;
  },
  downloadLeaderBoard: `v3/monitoring/pointsActivity/downloadRecognitionData`,

  // Rewards endpoints
  rewardsDetails: ({ category }: { category: string }) =>
    `/v2/rewards/getreward/tango?category=${encodeURI(
      category
    )}&sort=popular:desc`,
  redeem: '/v3/billing/checkRedeem',
  getExchangeRates: '/v2/rewards/exchangeRates',
  rewardCashOut: '/v2/rewards/cashout',
  customRewardCashOut: '/v2/rewards/cashout/custom',
  countries: '/v3/search/tangoFilter',
  swagCategories: '/v3/rewards/integrations/AXOMO/rewardCategory',
  swagDetails: (swagId: string, storeId: string) =>
    `/v3/rewards/integrations/AXOMO/${storeId}/${swagId}`,
  stateList: (countryCode: string) =>
    `/v3/rewards/integrations/country/${countryCode}/stateList`,
  axomoShippingCountries: `/v3/rewards/integrations/AXOMO/shippingCountries`,
  axomoPlaceOrder: `/v3/rewards/integrations/AXOMO/placeOrder`,
  myRewards: '/v2/user/settings/rewards',
  resendEmail: '/v2/user/settings/resendEmail',
  axomoDetails: (orderId: string) =>
    `/v3/rewards/integrations/order/${orderId}/shippingDetails`,
  rewardsHistory: '/v2/user/settings/history',
  axomoStoreInfo: `/v3/rewards/integrations/axomo/info`,
  rewardsIntegrationsInfo: '/v3/rewards/integrations/info',
  toggleAxomoTabON: '/v3/rewards/integrations/AXOMO/visibility/ON',
  listAmazonProducts: '/v3/rewards/amazon/products',
  amazonConnections: '/v3/rewards/amazon/connections',
  toggleAmazonRewards: '/v3/rewards/amazon/visibility',
  getAmazonProduct: '/v3/rewards/amazon/productById',
  amazonPlaceOrder: '/v3/rewards/amazon/placeOrder',
  amazonShippingDetails: (orderId: string) =>
    `/v3/rewards/amazon/order/${orderId}/shippingDetails`,
  getSwitchFlyToken: '/v3/rewards/switchFly/userToken',
  toggleCovverVisibility: (visibility: 'ON' | 'OFF') =>
    `/v3/rewards/integrations/COVVER/visibility/${visibility}`,
  getInfoCovver: '/v3/rewards/integrations/covver/info',
  rewardItemCovverInfo: (uid: string) =>
    `/v3/rewards/integrations/COVVER/assembly/${uid}`,
  getShippingCountriesCovver:
    '/v3/rewards/integrations/COVVER/shippingCountries',
  placeOrderSwagCovver: '/v3/rewards/integrations/COVVER/placeOrder',

  // Important section
  getImportantActivities: '/v3/notification/cards',
  dismissImportantActivity: (cardId: string) =>
    `/v3/notification/cards/${cardId}`,
  getActivityByCardId: (cardId: string) => `/v3/notification/cards/${cardId}`,
  getCarouselListByCardId: (cardId: string) =>
    `v3/feed/carousel/cards/${cardId}`,
  getChallengeCarouselListByCardId: (cardId: string) =>
    `v3/feed/carousel/cards/challenge/${cardId}`,
  getActiveFlows: '/v3/feed/flows/list/authorized',
  getFlowsToAnswerNow: '/v3/feed/flows/list/todo',
  getFlowsToAnswerAnytime: '/v3/feed/flows/list/shortcut',
  getFlowsNotInCollection: '/v3/feed/flows/list/notInCollection',
  getFlowsToDoCount: '/v3/feed/flows/count/todo',
  getUserFeed: '/v3/search/getUserFeed',
  updatePostNotification: (postId: string) =>
    `/v3/feed/posts/${postId}/notificationPreference`,
  updateFlowResponseNotification: (flowId: string, responseId: string) =>
    `/v3/feed/flows/${flowId}/response/${responseId}/notificationPreference`,
  updateChallengeNotification: (challengeId: string) =>
    `/v3/feed/challenges/${challengeId}/notificationPreference`,
  updateCarouselPostByCardId: (cardId: string) =>
    `v3/notification/cards/${cardId}/carousel`,
  getPendingAwardApprovals: `v3/feed/awards/approvals`,

  // Notebook endpoints
  updateNotebookTask: (view: string) => `/v3/feed/notebook/task/${view}`,

  // Post/Reply endpoints
  archiveComment: '/v3/feed/comments/archive',
  archiveFlowPost: (flowId: string, responseId: string) =>
    `/v3/feed/flows/${flowId}/response/${responseId}`,
  archivePost: '/v3/feed/posts/archive',

  getCanPostFlows: '/v3/feed/flows/activityFeed',

  getUnreadFlowActivities: '/v3/feed/flows/activity',

  // Connections endpoints
  connectionPool: '/v3/assembly/integration/connection/pool',
  disconnectApp: (connectionId: string) =>
    `/v3/assembly/integration/connection/${connectionId}/disconnect`,
  linkToken: '/v3/assembly/integration/merge/member/linkToken',
  listConnections: '/v3/assembly/integration/connection/list',

  // Collections endpoints
  deleteCollection: (collectionId: string) =>
    `/v3/assembly/members/collection/${collectionId}/delete`,
  listCollections: '/v3/assembly/members/collection/list',
  listCollectionItems: (collectionId: string) =>
    `/v3/assembly/members/collection/${collectionId}/list/items`,
  updateCollection: '/v3/assembly/members/collection',
  updateCollectionItems: ({
    collectionId,
    action,
  }: {
    collectionId: string;
    action: string;
  }) => `/v3/assembly/members/collection/${collectionId}/items/${action}`,
  getCollectionShareCriteria: (collectionId: string) =>
    `/v3/assembly/members/collection/${collectionId}/sharing`,
  previewShareCriteria: `/v3/assembly/members/criteria/preview`,
  previewMembersForShareCriteria: (previewId: string) =>
    `/v3/assembly/members/criteria/preview/${previewId}`,
  reorderCollections: (collectionId: string) =>
    `/v3/assembly/members/collection/${collectionId}/reorder`,
  reorderCollectionItems: (collectionId: string, itemId: string) =>
    `/v3/assembly/members/collection/${collectionId}/list/items/${itemId}`,

  // Recognition
  getCriteriaCount: () => '/v3/assembly/members/criteria/count',
  getGroups: '/v3/assembly/groups',
  getPostDetails: (postId: string) => `/v3/feed/posts/${postId}`,
  saveRecognitionPost: '/v3/feed/posts/recognition',
  editRecognitionPost: (postId: string) =>
    `/v3/feed/posts/recognition/${postId}`,

  // Flow endpoints
  createFlow: '/v3/feed/flows',
  editFlow: (flowId: string) => `/v3/feed/flows/${flowId}`,
  editPostInteractionSettings: (flowId: string, responseId: string) =>
    `/v3/feed/flows/${flowId}/response/${responseId}/settings`,
  saveAnnouncement: '/v3/feed/announcements',
  getAnnouncements: '/v3/feed/announcements',
  getEntityPermissions: '/v3/feed/entity/permissions',
  endAnnouncement: (announcementId: string) =>
    `/v3/feed/announcements/${announcementId}/end`,
  seenAnnouncement: (announcementId: string) =>
    `/v3/feed/announcements/${announcementId}/seen`,
  getAnnouncementInsightsViewers: (announcementId: string) =>
    `/v3/feed/announcements/${announcementId}/insights`,
  getAnnouncementById: (announcementId: string) =>
    `/v3/feed/announcements/${announcementId}`,
  archiveFlow: (flowId: string) => `/v3/feed/flows/${flowId}/archive`,
  unArchiveFlow: (flowId: string) => `/v3/feed/flows/${flowId}/unarchive`,
  deleteFlow: (flowId: string) => `/v3/feed/flows/${flowId}/delete`,

  getMembers: '/v3/assembly/members',
  getMembersByIds: `/v3/assembly/members/membersDetails`,
  getDepartments: '/v3/assembly/departments',
  postProfileData: '/v3/assembly/profileData',
  postedBy: '/v3/feed/filter/postedBy',
  flowBlocks: '/v3/feed/filter/blocks',
  flowFeed: (flowId: string) => `/v3/feed/flow/${flowId}`,
  downloadFlowData: (flowId: string) => `/v3/feed/flows/${flowId}/downloadData`,
  endOccurrence: (flowId: string) => `/v3/feed/flows/${flowId}/endOccurrence`,
  getPostDepartmentMembers: (postId: string) =>
    `/v3/feed/posts/${postId}/recipients`,

  //Secondary filters
  getSecondaryFilters: '/v3/search/secondaryFilter',

  // Chats
  getChatMessagesByMemberId: (memberId: string) =>
    `/v3/assembly/memberChats/getDirectMessages/${memberId}`,
  addChatMessage: '/v3/feed/richReplies',
  getUnreadChatMessages: '/v3/assembly/memberChats/getUnreadMessageCount',

  // Insights
  getTeamRecognitionInsights: '/v3/analytics/team/recognition',
  getBlockInsights: '/v3/analytics/flows/blocks/insights',
  getFlowParticipationInsights: '/v3/analytics/flows/participation/summary',

  // Nav
  getNavItems: '/v3/assembly/members/nav/items',
  pinItemToNav: '/v3/assembly/members/nav/pinItem',
  markSoftPinViewed: (itemId: string) =>
    `/v3/assembly/members/nav/pinItem/${itemId}/markSoftPinViewed`,
  updateNavItems: (itemId: string) =>
    `/v3/assembly/members/nav/pinItem/${itemId}`,
  updatePinnedCollectionsToggleState: `/v3/assembly/members/nav/pinnedItems`,
  inviteMembers: '/v3/assembly/members/invite/create',
  // Replies
  getFlowDetailsById: (flowId: string) => `/v3/feed/flows/${flowId}/details`,
  findDraft: `/v3/feed/drafts/search`,
  addReply: '/v3/feed/richReplies',
  deleteReply: '/v3/feed/richReplies/archive',
  updateReply: (replyId: string) => `/v3/feed/richReplies/${replyId}`,
  saveDrafts: '/v3/feed/drafts',
  getRepliesForFlowPost: (flowId: string, responseId: string) =>
    `/v3/feed/flows/${flowId}/response/${responseId}/comment`,
  getRepliesForRecognitionPost: (postId: string) =>
    `/v3/feed/posts/${postId}/comments`,
  getRepliesForChallenge: (challengeId: string) =>
    `/v3/feed/challenges/${challengeId}/replies`,
  searchMembersForFlow: (flowId: string) =>
    `/v3/feed/flows/${flowId}/members/search`,

  // Challenges
  createChallenge: 'v3/feed/challenges',
  launchChallenge: '/v3/feed/challenges/launch',
  endChallenge: '/v3/feed/challenges/end',
  archiveChallenge: '/v3/feed/challenges/archive',
  unArchiveChallenge: '/v3/feed/challenges/unarchive',
  fetchUploadURI: '/v3/file/getUploadUri',
  searchChallengeMembers: (challengeId: string) =>
    `/v3/feed/challenges/${challengeId}/members/search`,
  fetchUploadURIForChallenge: (challengeId: string) =>
    `/v3/feed/challenges/${challengeId}/claims/proofUploadUri`,
  getChallengeDetails: (challengeId: string) =>
    `/v3/feed/challenges/${challengeId}/details`,
  getChallengeById: (challengeId: string) =>
    `/v3/feed/challenges/${challengeId}/view`,
  editChallenge: (challengeId: string) =>
    `/v3/feed/challenges/${challengeId}/edit`,
  getReplySummaryForChallenge: (challengeId: string) =>
    `/v3/feed/posts/${challengeId}/replySummary?type=CHALLENGE`,
  editChallengeInteractionSettings: (challengeId: string) =>
    `/v3/feed/challenges/${challengeId}/settings`,

  // Challenge Claims
  submitClaim: '/v3/feed/challenges/claims',
  getClaims: '/v3/feed/challenges/claims',
  approveClaim: (claimId: string) =>
    `/v3/feed/challenges/claims/${claimId}/approve`,
  denyClaim: (claimId: string) => `/v3/feed/challenges/claims/${claimId}/deny`,
  claimsFilter: `/v3/feed/challenges/claims/filter`,
  getAllClaimedChallenges: `/v3/feed/challenges/search`,
  downloadClaimsData: (params?: string) =>
    `/v3/feed/challenges/claims/download?${params}`,
  jobStatus: (jobId: string) => `/v3/monitoring/job/${jobId}/status`,

  // Challenge insights modal
  getChallengeInsights: (challengeId: string) =>
    `/v3/feed/challenges/${challengeId}/insights`,
  getChallengeParticipationInsights: (challengeId: string) =>
    `/v3/feed/challenges/${challengeId}/insights/participation`,
  remindParticipant: (challengeId: string) =>
    `/v3/feed/challenges/${challengeId}/remind`,
  getClaimDetails: (claimId: string) =>
    `/v3/feed/challenges/claims/${claimId}/details`,

  // Awards
  getAwardsCatalog: '/v3/feed/awards/catalog',
  getAward: (awardId: string) => `/v3/feed/awards/${awardId}/details`,
  giveAward: `/v3/feed/awards/give`,
  approveDenyAward: ({ awardApprovalId, action }: ApproveDenyAwardPayload) =>
    `/v3/feed/awards/approvals/${awardApprovalId}/${action}`,
} as const;

export const AdminAPIEndpoints = {
  // **** Admin Members ****
  membersDetails: '/v3/assembly/members/a',
  profileData: '/v3/assembly/profileData',
  getAssemblyDepartments: '/v3/assembly/departments',
  countMembers: '/v3/assembly/members/count',
  deactivateMember: (source: string) =>
    `/v2/employer/employee/user?source=${source}`,
  reactiveMember: `/v3/assembly/members/bulkActivate`,
  rejectMember: `/v3/assembly/members/invite/reject`,
  sendRemindMember: `/v3/assembly/members/invite/sendRemindersBulk`,
  approveMember: `/v3/assembly/members/invite/approve`,
  getIdentityManager: `/v3/assembly/integration/identityManagerData`,
  editMember: (memberId: string) => `/v2/employer/employee/user/${memberId}`,
  addMember: '/v3/assembly/members/invite/create?source=API',
  getHomeUserInfo: '/v2/user/home/info/',

  //**** Admin Allowance */
  saveDefaultAllowanceFrequency: '/v3/assembly/allowance/settings',
  getDefaultAllowanceFrequency: '/v3/assembly/allowance/settings',
  createAllowanceCustomRule: '/v3/assembly/allowance/customRule',
  getListAllowanceCustomRule: '/v3/assembly/allowance/customRules',
  deleteAllowanceCustomRule: (criterialId: string) =>
    `/v3/assembly/allowance/customRule/${criterialId}`,
  updateAllowanceCustomRule: '/v3/assembly/allowance/customRule',
  updateCustomCurrency: '/v2/employer/employer_info/setCustomCurrency',
  getUploadUriCurrencyEmoji:
    '/v2/employer/employer_info/customCurrencyUploadUri',
  getAllowanceBreakup: '/v3/assembly/allowance/breakup',
  getAllUserAllowanceCustom: (criterialId: string) =>
    `/v3/assembly/members/criteria/${criterialId}/members`,
  updateExchangeRate: '/v3/assembly/exchangeRate',
  addOnetimeDeposit: '/v3/assembly/allowance/onetime',
  pointsReset: '/v3/assembly/allowance/pointsReset',
  monitoringJob: '/v3/monitoring/job/status',

  // **** Admin Deposit */
  getAllDepositHistory: '/v3/monitoring/pointsActivity',
  sendDepositForReceivers: '/v3/assembly/depositOrRemovePoints',
  sendDepositForEveryone: '/v3/assembly/depositOrRemovePoints',
  generateCSVTemplate: '/v3/assembly/downloadDepositTemplateCsv',

  // **** Admin Auth User ****
  getInfoUser: '/v2/auth/user/info',

  // **** Admin Anniversary ****
  saveChangesAnniversary: '/v2/employer/rewards/anniversary',
  // **** Admin Rewards */
  turnOnOffExperiences: '/v3/rewards/switchfly/settings',

  // **** Admin Awards */
  getAllAwards: '/v3/feed/awards/list',
  getAllAwardApprovals: (awardId: string) =>
    `/v3/feed/awards/${awardId}/approvals`,
  getAwardDetails: (awardId: string) => `/v3/feed/awards/${awardId}/details`,
  deleteAward: (awardId: string) => `/v3/feed/awards/${awardId}/delete`,
  updateStateAward: (awardId: string) =>
    `/v3/feed/awards/${awardId}/changeState`,
  createAward: '/v3/feed/awards',
  editAward: (awardId: string) => `/v3/feed/awards/${awardId}/edit`,
  duplicateAward: (awardId: string) => `/v3/feed/awards/${awardId}/duplicate`,

  // **** PLANS */
  getPlanFeatures: '/v3/billing/planFeatures',
  getAllPlans: '/v2/employer/billing/plans',

  // **** SWAG */
  getInfoCovver: '/v3/rewards/integrations/covver/info',
  connectToCovver: '/v3/rewards/integrations/covver/connect',
  disconnectCovver: '/v3/rewards/integrations/covver/disconnect',
  getInfoAxomo: '/v3/rewards/integrations/axomo/info',
  disconnectAxomo: '/v3/rewards/integrations/axomo/disconnect',
  toggleCovverVisibility: (visibility: 'ON' | 'OFF') =>
    `/v3/rewards/integrations/COVVER/visibility/${visibility}`,
  getRewardItemsCovver: '/v3/rewards/integrations/COVVER/rewardItems',
  toggleVisibilitySwagItems: (visibility: 'ON' | 'OFF') =>
    `/v3/rewards/toggle/${visibility}`,
  toggleVisibilityAllSwagItems: (visibility: 'ON' | 'OFF') =>
    `/v3/rewards/toggle/${visibility}`,
  updatePointsRewardItem: (storeUID: string, uid: string) =>
    `/v3/rewards/integrations/COVVER/${storeUID}/${uid}`,
  // **** Welcome bot */
  getBotList: '/v3/feed/bot/list',
  saveInfoWelcomeBot: '/v3/assembly/bot/template/saveWelcome',
  deactivateBot: (botId: string) => `/v3/assembly/bot/${botId}/deactivate`,
} as const;
