import { type BaseEmoji, mapHexCodeToEmoticon } from '@assembly-web/services';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';

import { classNames } from '../../../DesignSystem/Utils/classNames';
import { ReactionEmojiPicker } from '../Emoji/ReactionEmojiPicker';
import { Popover } from '../Popover';

export function EmojiPicker({
  emoji,
  onChange,
  buttonColor,
  className,
}: {
  emoji: string;
  onChange: (emoji: string) => void;
  buttonColor: string;
  className?: string;
}) {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const onEmojiSelect = useCallback(
    (emoji: BaseEmoji) => {
      onChange(emoji.unified);
      setShowEmojiPicker(false);
    },
    [onChange]
  );

  return (
    <div className={className}>
      <Popover
        onTriggerButtonClick={toggleEmojiPicker}
        trigger={
          <div
            className={classNames(
              'flex items-center justify-center rounded px-3 py-3 text-[24px] leading-6',
              buttonColor
            )}
          >
            {mapHexCodeToEmoticon(emoji)}
            <ChevronDownIcon className="ml-1 h-4 w-4" />
          </div>
        }
        open={showEmojiPicker}
        onOpenChange={setShowEmojiPicker}
      >
        <ReactionEmojiPicker onEmojiSelect={onEmojiSelect} />
      </Popover>
    </div>
  );
}
