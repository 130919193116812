import type { BaseEmoji } from '@assembly-web/services';
import { useLocale } from '@assembly-web/services';
import Picker from '@emoji-mart/react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

export function ReactionEmojiPicker({
  onEmojiSelect,
}: {
  onEmojiSelect: (emoji: BaseEmoji) => void;
}) {
  const { locale } = useLocale();
  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if ((e.target as Element).closest('[data-scrollable]')) return;
      e.stopPropagation();
    };

    const handleTouchMove = (e: TouchEvent) => {
      if ((e.target as Element).closest('[data-scrollable]')) return;
      e.stopPropagation();
    };

    document.addEventListener('wheel', handleWheel, true);
    document.addEventListener('touchmove', handleTouchMove, true);

    return () => {
      document.removeEventListener('wheel', handleWheel, true);
      document.removeEventListener('touchmove', handleTouchMove, true);
    };
  }, []);

  return (
    <Picker
      onEmojiSelect={onEmojiSelect}
      set="apple"
      locale={locale}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={true}
      title={<FormattedMessage defaultMessage="Pick your emoji" id="LkJOqf" />}
      emoji="point_up"
      theme="light"
    />
  );
}
