import { forwardRef, type HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

export const Container = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { readOnly?: boolean }
>(function Container({ className, readOnly, ...rest }, ref) {
  return (
    <div
      {...rest}
      className={twMerge(
        'flex flex-col rounded-2xl bg-gray-1 pb-2 shadow-feed-post @container',
        readOnly && 'pointer-events-none select-none',
        className
      )}
      ref={ref}
    />
  );
});
