import { mapHexCodeToEmoticon } from './flowUtils';

export const getBaseEmojiFromIcon = (icon: string) => {
  return {
    id: icon,
    skin: 1,
    name: '',
    shortcodes: '',
    unified: icon,
    emoticons: [],
    native: mapHexCodeToEmoticon(icon),
  } as BaseEmoji;
};

export type EmojiSkin = 1 | 2 | 3 | 4 | 5 | 6;

export type BaseEmoji = {
  id: string;
  name: string;
  shortcodes: string;
  /** Reverse mapping to keyof emoticons */
  emoticons: string[];
  unified: string;
  skin: EmojiSkin | null;
  native: string;
};
