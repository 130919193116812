import type { SwitchProps } from '@radix-ui/react-switch';
import { Root, Thumb } from '@radix-ui/react-switch';
import { forwardRef } from 'react';
import { twJoin } from 'tailwind-merge';
import type { MergeExclusive } from 'type-fest';

import { Tooltip } from '../Feedback/Tooltip';

type ToggleSwitchProps = MergeExclusive<
  { ['aria-label']: string; tooltipText?: string },
  { ['aria-describedby']: string }
> &
  SwitchProps;

export const ToggleSwitch = forwardRef<HTMLButtonElement, ToggleSwitchProps>(
  function ToggleSwitch(
    { checked, disabled, tooltipText, onCheckedChange, className, ...props },
    ref
  ) {
    return (
      <Root
        {...props}
        className={twJoin(
          'group relative inline-flex h-[24px] w-[44px] flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out radix-state-checked:bg-primary-6 radix-state-unchecked:bg-gray-6',
          disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
        )}
        checked={checked}
        disabled={disabled}
        onCheckedChange={onCheckedChange}
        ref={ref}
      >
        <Tooltip tooltipText={tooltipText}>
          <Thumb
            className={twJoin(
              'inline-block h-[20px] w-[20px] transform rounded-full bg-gray-2 shadow-lg-down ring-0 transition duration-200 ease-in-out group-radix-state-checked:translate-x-5 group-radix-state-unchecked:translate-x-0',
              disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            )}
          />
        </Tooltip>
      </Root>
    );
  }
);
