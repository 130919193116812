import { createContext, type ReactNode, useContext, useState } from 'react';

const LocaleContext = createContext<{
  locale: string;
  setLocale: (locale: string) => void;
} | null>(null);

export const LocaleProvider = ({
  children,
  locale: passedLocale,
}: {
  children: ReactNode;
  locale: string;
}) => {
  const [locale, setLocale] = useState(passedLocale);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocale = () => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }
  return context;
};
