import { SVGReactComponents } from '@assembly-web/assets';
import {
  type PropsWithChildren,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useDeviceInfo } from '../../hooks/useDeviceInfo';
import { V3Modal } from '../../Shared/Modal/V3Modal';
import { Title } from './Title';

type AwardsModalProps = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
  ctaSection: ReactNode;
}>;

export function AwardsModal({
  children,
  isOpen,
  onClose,
  title,
  ctaSection,
}: AwardsModalProps) {
  const titleRef = useRef<HTMLDivElement | null>(null);
  const [titleHeight, setTitleHeight] = useState(0);
  const isMobileView = useDeviceInfo().deviceType === 'mobile';

  useEffect(() => {
    if (titleRef.current) {
      setTitleHeight(titleRef.current.offsetHeight);
    }
  }, [title]);

  // fixed footer and dynamic header
  const bodyHeight = `calc(100vh - ${titleHeight + 104}px)`;

  return (
    <V3Modal
      isOpen={isOpen}
      onClose={onClose}
      title={<Title name={title} ref={titleRef} key={title} />}
      theme="cyan"
      size="medium"
      icon={<SVGReactComponents.AwardMedalIcon className="size-12" />}
      ctaSectionClassName="p-4 sm:px-6 sm:pb-4 max-sm:bottom-0 absolute sm:relative"
      headerClassName="p-4"
      className="w-full max-w-full rounded-lg p-0 max-sm:h-full sm:max-w-xl"
      bodyClassName={'p-0 h-screen sm:max-h-[60vh] max-sm:pb-16'}
      bodyStyle={{ ...(isMobileView && { maxHeight: bodyHeight }) }}
      ctaSection={ctaSection}
      onOpenAutoFocus={(e) => {
        e.preventDefault();
      }}
      focusElementOnFirstMount
    >
      {children}
    </V3Modal>
  );
}
