import { assemblyColors } from '@assembly-web/tailwind-preset/lib/colors';
import { type BarDatum, ResponsiveBar } from '@nivo/bar';
import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

type BarChartProps = {
  data: BarDatum[];
  chartLabel: string;
  indexBy: string;
  keys: string[];
  layout: 'horizontal' | 'vertical';
  bottomAxisLabel?: string;
  leftAxisLabel?: string;
};

const charWidth = 8;
const colorScheme = [
  assemblyColors.blue[10],
  assemblyColors.blue[9],
  assemblyColors.blue[8],
  assemblyColors.blue[7],
  assemblyColors.blue[6],
  assemblyColors.blue[5],
  assemblyColors.blue[4],
  assemblyColors.blue[3],
  assemblyColors.blue[2],
];
const smallDatasetColorScheme = colorScheme
  .filter((_, i) => i % 2 === 0)
  .slice(0, 5); //Use every other color to make it easier to distinguish on small datasets
const maxLabelLength = 40;
const maxDataPoints = 15; //TODO: Most flows have less than 15 blocks, use this as a default for now

const messages = defineMessages({
  count: {
    defaultMessage: 'Count: {count}',
    id: 'WjAPLr',
  },
  percentage: {
    defaultMessage: 'Percentage: {percentage}%',
    id: 'R+TUym',
  },
  noData: {
    defaultMessage: 'No Data to Display',
    id: 'QsQIef',
  },
});

function getHorizontalGraphLeftMargin(data: BarDatum[], indexBy: string) {
  const longestLabelLength = data.reduce((max, datum) => {
    return Math.max(max, datum[indexBy].toString().length);
  }, 5);

  return Math.min(longestLabelLength * charWidth, 300);
}

function getTickValues(dataLength: number) {
  return dataLength > 1 ? Math.floor(dataLength / 2) : undefined;
}

function formatAxisLabel(value: string) {
  if (value.length > maxLabelLength) {
    return `${value.slice(0, maxLabelLength)}...`;
  }
  return value;
}

export function BarChart({
  data,
  chartLabel,
  keys = ['value'],
  indexBy,
  layout,
  bottomAxisLabel,
  leftAxisLabel,
}: BarChartProps) {
  const { formatMessage } = useIntl();

  if (data.length === 0) {
    return formatMessage(messages.noData);
  }

  const tickValues = useMemo(() => {
    return getTickValues(data.length);
  }, [data.length]);

  const marginLeft = useMemo(() => {
    return layout === 'horizontal'
      ? getHorizontalGraphLeftMargin(data, indexBy)
      : 60;
  }, [layout, data, indexBy]);

  const processedData = useMemo(() => {
    if (layout === 'horizontal') {
      return [...data].slice(0, maxDataPoints).reverse();
    }
    return data.slice(0, maxDataPoints);
  }, [data, layout]);

  const colors = useMemo(() => {
    return data.length > 5 ? colorScheme : smallDatasetColorScheme;
  }, [data.length]);

  const chartHeight = useMemo(() => {
    if (layout === 'vertical') {
      return 450;
    }
    return Math.min(40 * data.length + 60, 450);
  }, [data.length, layout]);

  return (
    <div className="my-5 w-full max-w-[900px]" style={{ height: chartHeight }}>
      {/* TODO: Make height dynamic based off screen size */}
      <ResponsiveBar
        data={processedData}
        keys={keys}
        indexBy={indexBy}
        role="application"
        ariaLabel={chartLabel}
        colors={colors}
        colorBy={'indexValue'}
        theme={{
          text: {
            fontFamily: 'Roboto',
            fontSize: 14,
          },
          axis: {
            legend: {
              text: {
                fontSize: 16,
                fontWeight: 'bold',
              },
            },
            domain: {
              line: {
                stroke: '#dddddd',
                strokeWidth: 1,
              },
            },
          },
        }}
        layout={layout}
        enableTotals={false}
        borderRadius={10}
        enableLabel={false}
        enableGridX={layout === 'horizontal'}
        enableGridY={layout === 'vertical'}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickValues,
          legend: bottomAxisLabel,
          legendPosition: 'middle',
          legendOffset: 40,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickValues,
          format: layout === 'horizontal' ? formatAxisLabel : undefined,
          legend: leftAxisLabel,
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        margin={{ top: 10, right: 30, bottom: 50, left: marginLeft }}
        tooltip={({ value, data }) => (
          <div className="max-w-[250px] flex-col items-center rounded-md bg-gray-9 px-4 py-2 text-xs text-gray-1 shadow-2xl-down">
            <p className="line-clamp-2 text-sm font-medium">{data[indexBy]}</p>
            <p className="text-sm font-medium">
              {formatMessage(messages.count, {
                count: value,
              })}
            </p>
            {Boolean(data.percentage) && (
              <p className="text-sm font-medium">
                {formatMessage(messages.percentage, {
                  percentage: data.percentage,
                })}
              </p>
            )}
          </div>
        )}
      />
    </div>
  );
}
