import '@uppy/image-editor/dist/style.css';

import { Dashboard } from '@uppy/react';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { Button } from '../../../../DesignSystem/Inputs/Button';
import { LoaderIcon } from '../../../assets/icons';
import { useDeviceInfo } from '../../../hooks/useDeviceInfo';
import { V3Modal } from '../../Modal/V3Modal';
import { useUppyStore } from '../useUploadStore';

type ImageEditorModalProps = {
  open: boolean;
  domain: string;
  onCloseClick: () => void;
  onUploadClick: () => void;
};

const messages = defineMessages({
  title: {
    defaultMessage: 'Edit your image',
    id: 'qfIvjw',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  upload: {
    defaultMessage: 'Upload',
    id: 'p4N05H',
  },
});

export function ImageEditorModal({
  domain,
  open,
  onCloseClick,
  onUploadClick,
}: ImageEditorModalProps) {
  const { uppyInstances } = useUppyStore();
  const uppy = uppyInstances[domain];
  const isMobileView = useDeviceInfo().deviceType === 'mobile';
  const { formatMessage } = useIntl();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      const timer = setTimeout(() => setIsLoading(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [open]);

  const onCloseButtonClick = useCallback(() => {
    const backButtons = document.getElementsByClassName(
      'uppy-DashboardContent-back'
    );

    if (backButtons.length > 1) {
      const targetButton = backButtons[1];

      if (targetButton instanceof HTMLButtonElement) {
        targetButton.click();
      }
    }

    onCloseClick();
  }, [onCloseClick]);

  return (
    <V3Modal
      isOpen={open}
      onClose={onCloseButtonClick}
      className={twJoin(
        'z-20',
        isMobileView && 'flex min-h-screen w-full flex-col'
      )}
      title={formatMessage(messages.title)}
      ctaSection={
        <div className="flex w-full justify-end gap-3">
          <Button
            variation="secondaryLite"
            isFullWidth
            onClick={onCloseButtonClick}
          >
            {formatMessage(messages.cancel)}
          </Button>
          <Button
            isFullWidth
            onClick={() => {
              const saveButtons = document.getElementsByClassName(
                'uppy-DashboardContent-save'
              );
              if (
                saveButtons.length > 0 &&
                saveButtons[0] instanceof HTMLButtonElement
              ) {
                saveButtons[0].click();
              }

              const primaryButtons =
                document.getElementsByClassName('uppy-c-btn-primary');
              if (
                primaryButtons.length > 0 &&
                primaryButtons[0] instanceof HTMLButtonElement
              ) {
                primaryButtons[0].click();
              }

              onUploadClick();
            }}
          >
            {formatMessage(messages.upload)}
          </Button>
        </div>
      }
    >
      <div className="relative">
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: isLoading ? 1 : 0 }}
          transition={{ duration: 0.3 }}
          className="absolute inset-0 flex items-center justify-center"
        >
          <img alt="loader" src={LoaderIcon} className="h-8 w-8 animate-spin" />
        </motion.div>

        {uppy !== undefined && (
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: isLoading ? 0 : 1 }}
            transition={{ duration: 0.3 }}
            className="flex flex-col gap-4"
          >
            <Dashboard
              uppy={uppy}
              height={isMobileView ? `100%` : 400}
              autoOpen="imageEditor"
              hideUploadButton
              hideCancelButton
              showSelectedFiles={false}
              hidePauseResumeButton
              disableThumbnailGenerator
              plugins={['ImageEditor']}
              proudlyDisplayPoweredByUppy={false}
              metaFields={[
                { id: 'name', name: 'Name', placeholder: 'File name' },
              ]}
            />
          </motion.section>
        )}
      </div>
    </V3Modal>
  );
}
