import { botImage, defaultAwardImage } from '@assembly-web/assets';
import {
  BlockResponseState,
  BlockTypes,
  LegacyPostTypes,
  type MemberDetails,
  type OpenEndedBlockResponseDetails,
} from '@assembly-web/services';
import { type MouseEvent, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { PostCard } from '../../..';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import type { ToolbarProps } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { useToolbarState } from '../../../DesignSystem/Feedback/Toolbar/useToolbarState';
import { HorizontalRule } from '../../../DesignSystem/Layout/HorizontalRule';
import type { PostCardHeaderProps } from '../../Shared/PostCard/PostCardHeader';
import type { PostCardProps } from '../../Shared/PostCard/PostCardUI';
import type { BoostOptions } from '../Editors/RepliesEditor/RepliesEditor';
import { FlowPost } from '../Post/FlowPost';
import type { ReactionsBarProps } from './ReactionsBar';

export type PostProps = {
  postedAt: string;
  postHeaderProps: PostCardHeaderProps;
  postBodyProps: Pick<
    PostCardProps,
    | 'currency'
    | 'onFileClick'
    | 'flowResponse'
    | 'onPostCardBodyClick'
    | 'onBlockToolbarItemClick'
  > & { repliesCount: number };
  boostOptions?: BoostOptions;
  reactionsBarProps: ReactionsBarProps;
  onMemberClick: (memberId: string) => void;
  onPostToggleClick: (isExpanded: boolean) => void;
  canHidePoints?: boolean;
  handleInternalLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  currentUser: MemberDetails;
} & Pick<ToolbarProps, 'secondaryToolbarItems' | 'onMenuItemClick'>;

const messages = defineMessages({
  originalPost: {
    defaultMessage: 'Original post',
    id: 'p1pzyX',
  },
  postedAt: {
    defaultMessage: 'Posted {postedAt} ago',
    id: 'xwpf2a',
  },
  repliesTurnedOff: {
    defaultMessage: 'Replies have been turned off',
    id: 'h9h1y6',
  },
  replyCount: {
    defaultMessage:
      '{count, plural, =0 {No Replies} one {1 reply} other {{count} replies}}',
    id: 'Sn3666',
  },
  postedJustNow: {
    defaultMessage: 'Posted just now',
    id: '+0gpoj',
  },
  celebrationBot: {
    defaultMessage: 'Celebration Bot',
    id: 'imtvA0',
  },
});

export function Post(props: PostProps) {
  const { formatMessage } = useIntl();

  const {
    onMemberClick,
    postBodyProps,
    onMenuItemClick,
    postHeaderProps,
    reactionsBarProps,
    secondaryToolbarItems,
    boostOptions,
    canHidePoints,
    handleInternalLinkClick,
    currentUser,
  } = props;

  const { getToolbarProps } = useToolbarState({ isAlwaysVisible: true });

  const commonProps = useMemo(() => {
    const responseBlock = (() => {
      const response = postBodyProps.flowResponse.responses.find(
        (block) => block.type === BlockTypes.OpenEnded
      ) as OpenEndedBlockResponseDetails | undefined;
      if (response?.state === BlockResponseState.Skipped) {
        return undefined;
      }
      return response;
    })();

    return {
      containerProps: { className: 'shadow-none -mx-6' },
      toolbarProps: {
        ...getToolbarProps({ onMenuItemClick }),
        secondaryToolbarItems,
        onToolbarMenuItemClick: onMenuItemClick,
      },
      bodyProps: {
        onMemberClick,
        response: responseBlock?.response,
        handleInternalLinkClick,
        blockId: responseBlock?.blockId ?? '',
        onFileClick: postBodyProps.onFileClick,
        onToolbarItemClick: postBodyProps.onBlockToolbarItemClick,
      },
      metaProps: {
        coreValue: postBodyProps.flowResponse.coreValue,
        points: postBodyProps.flowResponse.points,
        onPostCardHeaderClick: postHeaderProps.onPostCardHeaderClick,
        hiddenPoints: canHidePoints,
        recipients: (() => {
          const recipients = postBodyProps.flowResponse.responses.find(
            (block) => block.type === BlockTypes.PersonSelector
          );
          if (!recipients || recipients.state === BlockResponseState.Skipped) {
            return undefined;
          }
          return 'recipients' in recipients.response
            ? recipients.response.recipients
            : [];
        })(),
      },
      reactionProps: reactionsBarProps,
      currentMember: currentUser,
      currency: postBodyProps.currency,
      showDivide: false,
    };
  }, [
    getToolbarProps,
    onMenuItemClick,
    secondaryToolbarItems,
    onMemberClick,
    handleInternalLinkClick,
    postBodyProps.onFileClick,
    postBodyProps.onBlockToolbarItemClick,
    postBodyProps.flowResponse.coreValue,
    postBodyProps.flowResponse.points,
    postBodyProps.flowResponse.responses,
    postBodyProps.currency,
    postHeaderProps.onPostCardHeaderClick,
    canHidePoints,
    reactionsBarProps,
    currentUser,
  ]);

  return (
    <div className="flex w-full flex-col items-center gap-2 bg-gray-1">
      {postBodyProps.flowResponse.postType === LegacyPostTypes.Award ? (
        <PostCard
          postType={postBodyProps.flowResponse.postType}
          awardBanner={{
            title: postBodyProps.flowResponse.award?.name ?? '',
            description: postBodyProps.flowResponse.award?.description,
            bannerImage: {
              src:
                postBodyProps.flowResponse.award?.image?.location ??
                defaultAwardImage,
              alt: '',
            },
          }}
          headerProps={{
            ...postHeaderProps,
            showFlowDetails: false,
            boostOptions,
          }}
          {...commonProps}
        />
      ) : postBodyProps.flowResponse.postType ===
        LegacyPostTypes.Recognition ? (
        <PostCard
          postType={postBodyProps.flowResponse.postType}
          headerProps={{
            ...postHeaderProps,
            showFlowDetails: false,
            boostOptions,
          }}
          {...commonProps}
          metaProps={{
            ...commonProps.metaProps,
            coreValue: postBodyProps.flowResponse.responses.find(
              (x) => x.type === BlockTypes.Dropdown
            )?.response?.value[0].value,
            points: postBodyProps.flowResponse.responses.find(
              (x) => x.type === BlockTypes.GivePointsStack
            )?.response?.value,
          }}
        />
      ) : postBodyProps.flowResponse.postType === LegacyPostTypes.Birthday ||
        postBodyProps.flowResponse.postType === LegacyPostTypes.Anniversary ||
        postBodyProps.flowResponse.postType === LegacyPostTypes.Welcome ? (
        <PostCard
          postType={postBodyProps.flowResponse.postType}
          {...commonProps}
          headerProps={{
            ...postHeaderProps,
            showFlowDetails: false,
            boostOptions: boostOptions,
            respondent: {
              ...postHeaderProps.respondent,
              firstName: formatMessage(messages.celebrationBot),
              name: formatMessage(messages.celebrationBot),
              image: botImage,
            } as MemberDetails,
          }}
          metaProps={{
            ...commonProps.metaProps,
            points: postBodyProps.flowResponse.responses.find(
              (x) => x.type === BlockTypes.GivePointsStack
            )?.response?.value,
          }}
        />
      ) : (
        <FlowPost
          {...commonProps}
          headerProps={{
            ...postHeaderProps,
            boostOptions,
            showFlowDetails: true,
          }}
          bodyProps={{
            ...postBodyProps,
            onMemberClick,
            canHidePoints,
            handleInternalLinkClick,
          }}
        />
      )}
      <div className="grid w-full items-center gap-2">
        <HorizontalRule>
          <TextStyle variant="sm-regular" className="text-gray-8">
            {postBodyProps.flowResponse.hideReplies
              ? formatMessage(messages.repliesTurnedOff)
              : formatMessage(messages.replyCount, {
                  count: postBodyProps.repliesCount,
                })}
          </TextStyle>
        </HorizontalRule>
      </div>
    </div>
  );
}
