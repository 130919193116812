import { useInfiniteQuery } from '@tanstack/react-query';

import { APIEndpoints } from '../../../APIEndpoints';
import { assemblyAPI } from '../../../assemblyAPI';
import type { PendingAwardApprovalResponse } from '../../../types/awards';

export function useGetPendingAwardApprovals() {
  return useInfiniteQuery<PendingAwardApprovalResponse>({
    queryKey: ['pendingAwardApproval'],
    queryFn: async ({ pageParam }) => {
      const params: Record<string, unknown> = {};
      if (pageParam) {
        params.cursor = pageParam;
      }
      const response = await assemblyAPI.get(
        APIEndpoints.getPendingAwardApprovals,
        {
          params,
        }
      );
      return response.data;
    },
    initialPageParam: undefined,
    getNextPageParam: (page: PendingAwardApprovalResponse) =>
      page.metadata.pagination.cursor.next,
    getPreviousPageParam: (page: PendingAwardApprovalResponse) =>
      page.metadata.pagination.cursor.previous,
  });
}
