import { defaultAwardImage, warningCircleIcon } from '@assembly-web/assets';
import {
  type ApprovalInfo,
  getIconElement,
  type MemberAPIResponse,
  parsePostResponseRecipients,
  VisibilityTypes,
} from '@assembly-web/services';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { type ReactNode, useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { LoadingSpinner } from '../../../DesignSystem/Feedback/Icons/LoadingSpinner';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { V3Modal } from '../../Shared/Modal/V3Modal';
import { PostCardActions } from '../../Shared/PostCard/PostCardUI';
import { Post as PostCard } from '../../Web/Post/Post';

const messages = defineMessages({
  title: {
    defaultMessage: 'Review {awardName} post',
    id: 'GPGtXV',
  },
  whoToCelebrateQn: {
    id: '2iD0/h',
    defaultMessage: 'Who would you like to celebrate?',
  },
  approve: {
    id: 'WCaf5C',
    defaultMessage: 'Approve',
  },
  deny: {
    id: 'htvX+Z',
    defaultMessage: 'Deny',
  },
  saving: {
    defaultMessage: 'Submitting...',
    id: 'txkW56',
  },
  disabledPermission: {
    defaultMessage: 'You are not an approver of this award',
    id: 'njUJp5',
  },
});

const content = defineMessages({
  prefix: {
    defaultMessage: 'You are approver level {currentLevel} of {totalLevels}.',
    id: '0/p4yZ',
  },
  individuals: {
    defaultMessage: `If approved, {count, plural, =0 {} =1 {{recipient1}} other {{recipient1} and {otherRecipients} {otherRecipients, plural, =1 {other} other {others}}}} will earn <iconTag>{currencyIcon}</iconTag> {points}`,
    id: 'YveYeG',
  },
  department: {
    defaultMessage: `If approved, {totalCount} people from {count, plural, =0 {} =1 {{department1}} other {{department1} and {otherDepartments} {otherDepartments, plural, =1 {other department} other {other departments}}}} will earn <iconTag>{currencyIcon}</iconTag> {points}`,
    id: 'IC6WAx',
  },
  memberCount: {
    defaultMessage: `If approved, {memberCount} people will earn <iconTag>{currencyIcon}</iconTag> {points}`,
    id: 'ILNkwD',
  },
  midLevelSuffix: {
    defaultMessage: `upon {balanceLevel} more {balanceLevel, plural, =0 {} =1 {level} other {levels}} of approval`,
    id: 'BfP3uJ',
  },
});

function BannerMessage({
  award,
  userDetails,
}: {
  award: ApprovalInfo;
  userDetails: MemberAPIResponse;
}) {
  if (award.state !== 'PENDING') {
    return null;
  }
  const { formatMessage } = useIntl();
  const currencyIcon = getIconElement(userDetails.assembly.currency);
  const message = useMemo(() => {
    let prefix = '';
    let body: ReactNode = '';
    let suffix = '';
    const recipient = award.recipient;
    if (award.approvals.levels.current <= award.approvals.levels.total) {
      prefix = formatMessage(content.prefix, {
        currentLevel: award.approvals.levels.current,
        totalLevels: award.approvals.levels.total,
      });
    }
    if (recipient?.everyone?.count) {
      // everyone
      body = formatMessage(content.memberCount, {
        memberCount: recipient.everyone.count,
        iconTag: () => currencyIcon,
        currencyIcon,
        points: award.post.pointsEach,
      });
    } else if (
      recipient?.departments &&
      recipient.departments.length > 0 &&
      recipient.individuals?.length === 0
    ) {
      // only from departments
      body = formatMessage(content.department, {
        count: recipient.departments.length,
        department1: recipient.departments[0].name,
        otherDepartments: recipient.departments.length - 1,
        totalCount: recipient.totalCount,
        iconTag: () => currencyIcon,
        points: award.post.pointsEach,
        currencyIcon,
      });
    } else if (
      recipient?.individuals &&
      recipient.individuals.length > 0 &&
      recipient.departments?.length === 0
    ) {
      // only from individuals
      body = formatMessage(content.individuals, {
        count: recipient.individuals.length,
        recipient1: recipient.individuals[0].name,
        otherRecipients: recipient.individuals.length - 1,
        iconTag: () => currencyIcon,
        currencyIcon,
        points: award.post.pointsEach,
      });
    } else {
      // mix of department and individuals
      body = formatMessage(content.memberCount, {
        memberCount: recipient?.totalCount,
        currencyIcon,
        points: award.post.pointsEach,
        iconTag: () => currencyIcon,
      });
    }
    if (award.approvals.levels.current < award.approvals.levels.total) {
      suffix = formatMessage(content.midLevelSuffix, {
        balanceLevel:
          award.approvals.levels.total - award.approvals.levels.current,
      });
    }
    return { prefix, body, suffix };
  }, [award, currencyIcon, formatMessage]);

  return (
    <TextStyle
      variant="base-medium"
      className="rounded-lg bg-upgrade-2 px-6 py-2 text-center"
    >
      {award.canApprove ? (
        <>
          {message.prefix} {message.body} {message.suffix}
        </>
      ) : (
        <>{formatMessage(messages.disabledPermission)}</>
      )}
    </TextStyle>
  );
}

function AwardPostReview({
  award,
  navigateToUserFeed,
  userDetails,
}: {
  award: ApprovalInfo;
  navigateToUserFeed: (memberId: string) => void;
  userDetails: MemberAPIResponse;
}) {
  const handleAssemblyPostRedirection = useCallback(
    (action: PostCardActions, id: string) => {
      if (action === PostCardActions.PersonClicked) {
        navigateToUserFeed(id);
      }
    },
    [navigateToUserFeed]
  );

  return (
    <div className="flex flex-col gap-4">
      <PostCard
        awardBanner={{
          title: award.awardName,
          bannerImage: {
            src: award.post.image?.location ?? defaultAwardImage,
            alt: '',
          },
          ...(award.awardDescription.message && {
            description: {
              message: award.awardDescription.message,
              messageHtml: award.awardDescription.messageHtml,
              messageTokens: award.awardDescription.messageTokens,
            },
          }),
        }}
        postType="award"
        bodyProps={{
          onMemberClick: navigateToUserFeed,
          response: {
            files: [],
            taggedUsers: [],
            valueHtml: award.post.messageHtml,
          },
          blockId: '',
        }}
        showDivide={false}
        headerProps={{
          createdAt: award.createdAt,
          flowIcon: '1F389',
          flowId: 'recognition',
          flowName: 'Give recognition',
          isEdited: false,
          onPostCardHeaderClick: handleAssemblyPostRedirection,
          respondent: award.createdBy,
          visibility: award.isPrivate
            ? VisibilityTypes.Private
            : VisibilityTypes.Everyone,
        }}
        metaProps={{
          coreValue: award.post.coreValue,
          points: award.post.pointsEach,
          hiddenPoints: false,
          recipients: parsePostResponseRecipients(
            award.recipient,
            award.awardApprovalId
          ),
        }}
        currentMember={userDetails.member}
        currency={userDetails.assembly.currency}
      />
      <BannerMessage award={award} userDetails={userDetails} />
    </div>
  );
}

export function ViewAwardPost({
  award,
  isOpen,
  onClose,
  handleApproval,
  approvalState,
  ...props
}: {
  isOpen: boolean;
  onClose: () => void;
  award: ApprovalInfo;
  navigateToUserFeed: (memberId: string) => void;
  userDetails: MemberAPIResponse;
  handleApproval: (type: 'approve' | 'deny') => void;
  approvalState: {
    action: 'approve' | 'deny' | null;
    status: 'error' | 'idle' | 'pending' | 'success';
  };
}) {
  const { formatMessage } = useIntl();
  const disableActions = !award.canApprove;
  const denyInProgress = useMemo(() => {
    return (
      approvalState.status === 'pending' && approvalState.action === 'deny'
    );
  }, [approvalState]);

  const approveInProgress = useMemo(() => {
    return (
      approvalState.status === 'pending' && approvalState.action === 'approve'
    );
  }, [approvalState]);

  return (
    <V3Modal
      isOpen={isOpen}
      onClose={onClose}
      headerClassName="pt-4 pb-2"
      // TODO: shamli to update back button
      title={
        <div className="flex items-center gap-2">
          <img src={warningCircleIcon} alt={formatMessage(messages.title)} />
          <TextStyle variant="xl-bold">
            {formatMessage(messages.title, {
              awardName: award.awardName,
            })}
          </TextStyle>
        </div>
      }
      className="!w-[849px] !max-w-full overflow-scroll !rounded-lg px-4"
      bodyClassName="px-0 py-4"
      ctaSection={
        Boolean(award.state === 'PENDING') && (
          <div className="flex w-full gap-6">
            <Tooltip
              tooltipText={
                Boolean(disableActions) &&
                formatMessage(messages.disabledPermission)
              }
            >
              <Button
                variation="dangerLite"
                size="large"
                className="w-full"
                onClick={() => handleApproval('deny')}
                disabled={denyInProgress || disableActions}
              >
                {denyInProgress ? (
                  <>
                    <LoadingSpinner />
                    {formatMessage(messages.saving)}
                  </>
                ) : (
                  <>{formatMessage(messages.deny)}</>
                )}
              </Button>
            </Tooltip>
            <Tooltip
              tooltipText={
                Boolean(disableActions) &&
                formatMessage(messages.disabledPermission)
              }
            >
              <Button
                variation="primary"
                size="large"
                onClick={() => handleApproval('approve')}
                disabled={approveInProgress || disableActions}
                className="w-full"
              >
                {approveInProgress ? (
                  <>
                    <LoadingSpinner />
                    {formatMessage(messages.saving)}
                  </>
                ) : (
                  <>
                    {formatMessage(messages.approve)}
                    <CheckCircleIcon className="h-4 w-4" />
                  </>
                )}
              </Button>
            </Tooltip>
          </div>
        )
      }
    >
      <AwardPostReview award={award} {...props} />
    </V3Modal>
  );
}
