import {
  type MemberDetails,
  MemberRole,
  MemberState,
  type RepliesResponse,
} from '@assembly-web/services';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { defineMessages, useIntl } from 'react-intl';

import { Avatar, AvatarSize } from '../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Recipients } from '../Recipients';

export type RepliesProps = {
  onReplyButtonClick: () => void;
  showDraft: boolean;
  repliesData: RepliesResponse;
  currentMember: MemberDetails;
};

const messages = defineMessages({
  draftLabel: {
    defaultMessage: 'Draft',
    id: 'W6nwjo',
  },
  repliesTrigger: {
    defaultMessage:
      '{commentsCount, plural, =0 {Reply to this post} =1 {1 reply} other {# replies}}',
    id: 'O/Y2UF',
  },
  viewReplies: {
    defaultMessage: 'View replies',
    id: 'hvCo0f',
  },
  lastRepliedAt: {
    defaultMessage: 'Last reply {timeAgo}',
    id: 'nZtCXw',
  },
});

export function Replies({
  currentMember,
  onReplyButtonClick,
  repliesData,
  showDraft,
}: RepliesProps) {
  const { formatMessage } = useIntl();

  const {
    count: commentsCount,
    initialRespondents,
    respondentsCount,
    lastRepliedAt = '',
  } = repliesData;
  const { memberID: currentMemberId, image, name } = currentMember;

  return (
    <section className="px-4 py-2">
      <button
        onClick={onReplyButtonClick}
        className="group flex w-full flex-wrap items-center gap-1 rounded border border-transparent p-2 hover:border-primary-6 hover:bg-primary-1 @lg:flex-nowrap"
      >
        {respondentsCount > 0 ? (
          <Recipients
            recipients={initialRespondents.map((respondent) => ({
              type: 'member',
              image: respondent.image,
              memberID: respondent.memberID,
              name: respondent.name,
              firstName: respondent.name,
              lastName: respondent.name,
              memberState: MemberState.Active,
              role: [MemberRole.Employee],
              username: respondent.name,
            }))}
            type="avatar"
            size={AvatarSize.Medium}
            showDetails={false}
          />
        ) : (
          <Avatar
            size={AvatarSize.Medium}
            image={image}
            name={name}
            memberID={currentMemberId}
          />
        )}
        <TextStyle
          as="span"
          variant={commentsCount > 0 ? 'base-medium' : 'base-regular'}
          className="text-primary-6"
        >
          {formatMessage(messages.repliesTrigger, { commentsCount })}
        </TextStyle>
        {commentsCount > 0 && lastRepliedAt !== '' && (
          <>
            <span className="w-84 hidden text-left text-primary-6 @md:group-hover:inline @md:group-focus:inline">
              {formatMessage(messages.viewReplies)}
            </span>
            <span className="w-84 text-left text-gray-8 @md:group-hover:hidden @md:group-focus:hidden">
              {formatMessage(messages.lastRepliedAt, {
                timeAgo: dayjs(lastRepliedAt).fromNow(),
              })}
            </span>
          </>
        )}
        {Boolean(showDraft) && (
          <TextStyle
            variant="sm-regular"
            className="hidden rounded-md bg-gray-3 px-2 text-gray-9 group-hover:bg-primary-2 group-hover:text-primary-6 @sm:block"
          >
            {formatMessage(messages.draftLabel)}
          </TextStyle>
        )}
        <ChevronRightIcon className="ml-auto hidden h-4 text-primary-6 @sm:group-hover:inline" />
      </button>
    </section>
  );
}
