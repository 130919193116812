import { MemberState } from '../types/flowTypes';
import type {
  Criteria,
  CriteriaDataType,
  DepartmentCriteria,
  EveryoneCriteria,
  ManagerCriteria,
  MemberResponseCriteria,
  Recipients,
  RoleCriteria,
  WorkLocationCriteria,
} from '../types/recognitionParticipation';

type GroupedDataType = {
  department: Recipients['department'];
  member: Recipients['individuals'];
  role: Recipients['role'];
  workLocation: Recipients['workLocation'];
  manager: Recipients['manager'];
};

function groupDataByFiled(data: CriteriaDataType[]) {
  const result = data.reduce<GroupedDataType>(
    (acc, item) => {
      if (item.type === 'department') {
        acc.department.push(item);
      } else if (item.type === 'member') {
        acc.member.push(item);
      } else if (item.type === 'role') {
        acc.role.push(item);
      } else if (item.type === 'workLocation') {
        acc.workLocation.push(item);
      } else {
        acc.manager.push(item);
      }
      return acc;
    },
    {
      department: [] as Recipients['department'],
      member: [] as Recipients['individuals'],
      role: [] as Recipients['role'],
      workLocation: [] as Recipients['workLocation'],
      manager: [] as Recipients['manager'],
    }
  );

  const filteredResult = Object.fromEntries(
    Object.entries(result).filter(
      ([, value]) => Array.isArray(value) && value.length > 0
    )
  );

  return filteredResult;
}

export function generateCriteriaRules(
  data: CriteriaDataType[],
  includeRecipientMeta = false,
  perm: 'recipient' | 'approver' | 'giver' = 'recipient'
): Criteria {
  const everyoneSelected = data.some(
    (item) => item.type === 'department' && item.id === 'everyone'
  );

  // filter out everyone if everyone is selected

  data = everyoneSelected
    ? data.filter(
        (item) =>
          (item.type === 'department' && item.id !== 'everyone') ||
          item.type === 'member' ||
          item.type === 'manager' ||
          item.type === 'role' ||
          item.type === 'workLocation'
      )
    : data;

  const rolesHasManager = data.some(
    (item) => item.type === 'role' && item.name === 'manager'
  );

  const groupedData = groupDataByFiled(
    rolesHasManager
      ? [...data, { type: 'manager' as const, name: 'Manager' }]
      : data
  );

  const formattedCriteria = Object.entries(groupedData).map(
    ([field, values]) => {
      if (field === 'manager') {
        return {
          field: 'manager',
          values: [
            {
              value: true,
              operator: 'isManager',
              perm: 'approver',
            },
          ],
        } satisfies Criteria['include'][number];
      }
      if (field === 'department') {
        return {
          field: 'department',
          values: values.map((value) => ({
            value: value.name,
            operator: 'is',
            perm,
          })),
        } satisfies Criteria['include'][number];
      }
      if (field === 'role') {
        return {
          field: 'role',
          values: values.map((value) => ({
            value: value.name,
            operator: 'is',
            perm,
          })),
        } satisfies Criteria['include'][number];
      }
      if (field === 'workLocation') {
        return {
          field: 'workLocation',
          values: values.map((value) => ({
            value: value.name,
            operator: 'is',
            perm,
          })),
        } satisfies Criteria['include'][number];
      }
      return {
        field: 'member',
        values: values.map((value) => ({
          ...(includeRecipientMeta &&
            value.type === 'member' && {
              meta: {
                email: value.email,
                firstName: value.firstName,
                lastName: value.lastName,
                memberId: value.memberId,
                memberID: value.memberID,
                memberState: value.memberState,
                name: value.name,
                role: value.role,
                status: value.status,
                type: 'member',
                department: value.department,
                image: value.image,
                jobTitle: value.jobTitle,
                pronouns: value.pronouns,
                username: value.username,
              },
            }),
          value: value.type === 'member' ? value.memberID : value.name,
          operator: 'is',
          perm,
        })),
      } satisfies Criteria['include'][number];
    }
  );

  return {
    include: [
      ...formattedCriteria,
      ...(everyoneSelected
        ? [
            {
              field: 'everyone',
              value: true,
              operator: 'is',
              perm,
            } satisfies Criteria['include'][number],
          ]
        : []),
    ],
  };
}

export function convertCriteriaToDataArray(
  data?: (
    | ManagerCriteria
    | DepartmentCriteria
    | RoleCriteria
    | MemberResponseCriteria
    | WorkLocationCriteria
    | EveryoneCriteria
  )[]
): CriteriaDataType[] {
  if (!data) return [];
  const individuals =
    data
      .filter((item) => item.field === 'member')[0]
      ?.values.map((item) => ({
        type: 'member' as const,
        name: item.meta?.name ?? item.value,
        memberID: item.meta?.memberId ?? '',
        memberState: item.meta?.state ?? MemberState.Pending,
        image: item.meta?.image ?? '',
        email: item.meta?.email ?? '',
        department: item.meta?.department ?? '',
        // TODO: @meghana to clean this up
        role: [],
        firstName: item.meta?.name ?? '',
        lastName: item.meta?.name ?? '',
        username: item.meta?.name ?? '',
      })) ?? [];

  // if everyone is included add to departments
  const everyone = data.find((item) => item.field === 'everyone');
  const departments =
    data
      .filter((item) => item.field === 'department')[0]
      ?.values.map((item) => {
        return {
          type: 'department' as const,
          name: item.value,
          id: item.value,
          count: 0,
        };
      }) ?? [];

  if (everyone) {
    departments.push({
      type: 'department' as const,
      name: 'Everyone',
      id: 'everyone',
      count: 0,
    });
  }

  const manager =
    data
      .filter((item) => item.field === 'manager')[0]
      ?.values.map(() => ({
        type: 'manager' as const,
        // TODO: @meghana to update this to right variants based on the level
        name: 'Manager',
      })) ?? [];

  const roles =
    data
      .filter((item) => item.field === 'role')[0]
      ?.values.map((item) => ({
        type: 'role' as const,
        name: item.value,
      })) ?? [];

  const workLocation =
    data
      .filter((item) => item.field === 'workLocation')[0]
      ?.values.map((item) => ({
        type: 'workLocation' as const,
        name: item.value,
      })) ?? [];

  return [
    ...individuals,
    ...departments,
    ...manager,
    ...roles,
    ...workLocation,
  ];
}

export function getGroupedDataFromCriteria(
  data?: (
    | ManagerCriteria
    | DepartmentCriteria
    | RoleCriteria
    | MemberResponseCriteria
    | WorkLocationCriteria
    | EveryoneCriteria
  )[]
): {
  department?: Recipients['department'];
  member?: Recipients['individuals'];
  role?: Recipients['role'];
  workLocation?: Recipients['workLocation'];
  manager?: Recipients['manager'];
} {
  const dataArray = convertCriteriaToDataArray(data);

  return groupDataByFiled(dataArray);
}
