import type { BlockType } from '@assembly-web/services';
import {
  ArrowUpTrayIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  GifIcon,
  LinkIcon,
  PlusIcon,
  TrophyIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as RadixSelect from '@radix-ui/react-select';
import {
  createContext,
  forwardRef,
  type ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { GaugeIcon, LinkedScale, TextAlignLeft } from '../../../Icons';
import { Icon } from './BlockIcon';
import { messages } from './messages';

type ChildrenProp = {
  children: ReactNode;
};

export type SelectConstructProps = {
  disabled?: boolean;
  defaultValue?: string;
  onValueChange?: (value: string) => void;
  value?: string;
  label: string;
  children: ReactNode;
  portal?: boolean | HTMLElement | null;
  displayValue?: string;
  triggerVariant: 'border' | 'transparent';
  showLinkIcon?: boolean;
  onClick?: () => void;
};

export type SelectProps = {
  defaultValue?: BlockType;
  disabled?: boolean;
  onValueChange?: (value: string) => void;
  children: ReactNode;
  portal?: boolean;
  isLinkedBlock?: boolean;
  triggerVariant: Parameters<typeof SelectConstruct>[0]['triggerVariant'];
  onClick?: () => void;
};

type ItemProps = {
  label: ReactNode;
  icon?: ReactNode;
  value: string;
  onSelect?: (event: Event) => void;
  disabled?: boolean;
};

type ExposedItemProps = Pick<ItemProps, 'onSelect' | 'disabled'>;

const DropdownTypeContext = createContext<'MENU' | 'SELECT' | null>(null);

const useDropdownType = () => {
  const type = useContext(DropdownTypeContext);

  if (!type) {
    throw new Error('useDropdownType must be used within a Dropdown component');
  }

  return type;
};

const CommonContainerClasses = 'flex items-center gap-2 px-3';

const LabelClasses = `py-1 text-xs text-gray-7 ${CommonContainerClasses}`;
const ItemClasses = `group/item relative cursor-pointer py-2 focus:outline-none data-[highlighted]:bg-gray-3 [&_svg:not([data-unstyled])]:stroke-gray-8 [&_svg]:h-4 [&_svg]:w-4 data-[disabled]:cursor-not-allowed data-[disabled]:invert ${CommonContainerClasses}`;

function Container({
  align = 'start',
  children,
}: ChildrenProp & Pick<DropdownMenu.DropdownMenuContentProps, 'align'>) {
  const type = useDropdownType();

  const className =
    'min-w-[185px] rounded-lg bg-gray-1 py-1 shadow-lg-down ring-1 ring-gray-8 ring-opacity-5 z-20';

  if (type === 'MENU') {
    return (
      <DropdownMenu.Content className={className} align={align}>
        {children}
      </DropdownMenu.Content>
    );
  }

  return (
    <RadixSelect.Content position="popper" className={className}>
      <RadixSelect.Viewport>{children}</RadixSelect.Viewport>
    </RadixSelect.Content>
  );
}

const MenuTrigger = forwardRef<
  HTMLButtonElement,
  {
    variant: 'primary' | 'icon' | 'overflow';
  } & React.ButtonHTMLAttributes<HTMLButtonElement>
>(function MenuTrigger({ variant, ...tail }, ref) {
  const { formatMessage } = useIntl();

  if (variant === 'primary') {
    return (
      <button
        {...tail}
        className="group flex w-max items-center gap-2 rounded-lg border border-primary-6 bg-gray-1 px-3 py-1 text-sm font-medium text-primary-6 hover:bg-gray-3 focus:bg-gray-3 disabled:cursor-not-allowed disabled:border-gray-7 disabled:bg-gray-2 disabled:text-gray-7"
        ref={ref}
      >
        <PlusIcon className="h-4 w-4 fill-current" />
        {formatMessage(messages.addQuestion)}
      </button>
    );
  }

  if (variant === 'overflow') {
    return (
      <IconButton {...tail} variation="secondaryLite" size="xSmall" ref={ref}>
        <EllipsisHorizontalIcon />
      </IconButton>
    );
  }

  return (
    <IconButton
      {...tail}
      ref={ref}
      variation="secondaryEmphasized"
      size="xSmall"
    >
      <PlusIcon />
    </IconButton>
  );
});

function Menu({
  align,
  children,
  disabled,
  portal = true,
  trigger,
  triggerTooltip,
}: ChildrenProp &
  Pick<DropdownMenu.DropdownMenuContentProps, 'align'> & {
    portal?: boolean;
    trigger: ReactNode;
    triggerTooltip?: string;
    disabled?: boolean;
  }) {
  return (
    <DropdownTypeContext.Provider value="MENU">
      <DropdownMenu.Root>
        <Tooltip tooltipText={triggerTooltip}>
          <DropdownMenu.Trigger disabled={disabled} asChild>
            {trigger}
          </DropdownMenu.Trigger>
        </Tooltip>
        {portal ? (
          <DropdownMenu.Portal>
            <Container align={align}>{children}</Container>
          </DropdownMenu.Portal>
        ) : (
          <Container align={align}>{children}</Container>
        )}
      </DropdownMenu.Root>
    </DropdownTypeContext.Provider>
  );
}

const MenuLabel = forwardRef<HTMLDivElement, ChildrenProp>(function LabelImpl(
  { children, ...tail },
  ref
) {
  return (
    <DropdownMenu.Label className={LabelClasses} {...tail} ref={ref}>
      {children}
    </DropdownMenu.Label>
  );
});

const MenuItem = forwardRef<HTMLDivElement, ItemProps>(function ItemImpl(
  { label, icon, ...tail },
  ref
) {
  return (
    <DropdownMenu.Item
      {...tail}
      className={ItemClasses}
      textValue={tail.value}
      ref={ref}
    >
      {icon}
      <TextStyle as="span" variant="sm-regular" subdued>
        {label}
      </TextStyle>
    </DropdownMenu.Item>
  );
});

const MenuGroup = DropdownMenu.Group;

function MenuSeparator() {
  return <DropdownMenu.Separator className="h-[1px] bg-gray-5" />;
}

function Select({
  children,
  defaultValue = 'OPEN_ENDED',
  disabled,
  onValueChange,
  portal = true,
  isLinkedBlock,
  triggerVariant,
  onClick,
}: SelectProps) {
  const { formatMessage } = useIntl();
  const [value, setValue] = useState(defaultValue);

  const formattedMessageMap = useMemo(
    () =>
      ({
        DROPDOWN: formatMessage(messages.dropdown),
        SCALE: formatMessage(messages.scale),
        OPEN_ENDED: formatMessage(messages.openEnded),
        MULTI_CHOICE: formatMessage(messages.multipleChoice),
        FILE_UPLOAD: formatMessage(messages.fileUpload),
        GIF: formatMessage(messages.gifSelect),
        GIVE_POINTS_STACK: formatMessage(messages.givePoints),
        PERSON_SELECTOR: formatMessage(messages.personSelect),
        NPS: formatMessage(messages.nps),
        POINTS: formatMessage(messages.givePoints),
      }) satisfies Record<BlockType, string>,
    [formatMessage]
  );

  return (
    <DropdownTypeContext.Provider value="SELECT">
      <SelectConstruct
        defaultValue={defaultValue}
        disabled={disabled}
        displayValue={formattedMessageMap[value]}
        label={formatMessage(messages.blockTypeSelector)}
        onClick={onClick}
        onValueChange={(value) => {
          setValue(value as BlockType);
          onValueChange?.(value);
        }}
        portal={portal}
        showLinkIcon={isLinkedBlock}
        triggerVariant={triggerVariant}
        value={value}
      >
        {children}
      </SelectConstruct>
    </DropdownTypeContext.Provider>
  );
}

const SelectConstruct = forwardRef<HTMLButtonElement, SelectConstructProps>(
  function SelectConstruct(
    {
      children,
      defaultValue,
      disabled,
      displayValue,
      label,
      onClick,
      onValueChange,
      portal = false,
      value,
      showLinkIcon,
      triggerVariant,
      ...tail
    },
    ref
  ) {
    return (
      <div className="flex items-center gap-2">
        <RadixSelect.Root
          disabled={disabled}
          defaultValue={defaultValue}
          onValueChange={onValueChange}
          value={value}
        >
          <RadixSelect.Trigger asChild aria-label={label} onClick={onClick}>
            <button
              className={twMerge(
                'group flex w-max items-center gap-2 rounded-lg text-sm font-normal disabled:bg-gray-2',
                triggerVariant === 'transparent' &&
                  'px-2 py-1 text-gray-8 hover:bg-gray-3',
                triggerVariant === 'border' &&
                  'border border-gray-5 px-3 py-2 text-gray-9'
              )}
              ref={ref}
              {...tail}
            >
              <Icon type={value as BlockType} className="h-4 w-4" />
              <RadixSelect.Value asChild>
                <TextStyle as="span" variant="sm-regular" subdued>
                  {displayValue ?? value}
                </TextStyle>
              </RadixSelect.Value>
              <ChevronDownIcon
                className={twMerge(
                  'h-4 w-4 stroke-current group-data-[state=open]:rotate-180',
                  triggerVariant === 'transparent' && 'stroke-gray-8',
                  triggerVariant === 'border' && 'stroke-gray-9'
                )}
              />
            </button>
          </RadixSelect.Trigger>
          {portal ? (
            <RadixSelect.Portal
              container={typeof portal !== 'boolean' ? portal : document.body}
            >
              <Container>{children}</Container>
            </RadixSelect.Portal>
          ) : (
            <Container>{children}</Container>
          )}
        </RadixSelect.Root>
        {Boolean(showLinkIcon) && (
          <LinkIcon className="h-4 w-4 stroke-current text-gray-9" />
        )}
      </div>
    );
  }
);

const SelectLabel = forwardRef<HTMLDivElement, ChildrenProp>(function LabelImpl(
  { children, ...tail },
  ref
) {
  return (
    <RadixSelect.Label className={LabelClasses} {...tail} ref={ref}>
      {children}
    </RadixSelect.Label>
  );
});

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(function ItemImpl(
  { label, icon, value, onSelect: _, ...tail },
  ref
) {
  return (
    <RadixSelect.Item value={value} className={ItemClasses} {...tail} ref={ref}>
      {icon}
      <RadixSelect.ItemText asChild>
        <TextStyle as="span" variant="sm-regular" subdued>
          {label}
        </TextStyle>
      </RadixSelect.ItemText>
      <RadixSelect.ItemIndicator asChild>
        <span className="absolute right-3">
          <CheckIcon className="h-4 w-4 stroke-primary-6" data-unstyled />
        </span>
      </RadixSelect.ItemIndicator>
    </RadixSelect.Item>
  );
});

const SelectGroup = RadixSelect.Group;

const Label = forwardRef<HTMLDivElement, ChildrenProp>(
  function LabelImpl(props, ref) {
    const type = useDropdownType();

    if (type === 'MENU') {
      return <MenuLabel {...props} ref={ref} />;
    }

    return <SelectLabel {...props} ref={ref} />;
  }
);

const Item = forwardRef<HTMLDivElement, ItemProps>(
  function ItemImpl(props, ref) {
    const type = useDropdownType();

    if (type === 'MENU') {
      return <MenuItem {...props} ref={ref} />;
    }

    return <SelectItem {...props} ref={ref} />;
  }
);

const Group = forwardRef<HTMLDivElement, ChildrenProp>(
  function GroupImpl(props, ref) {
    const type = useDropdownType();

    if (type === 'MENU') {
      return <MenuGroup {...props} ref={ref} />;
    }

    return <SelectGroup {...props} ref={ref} />;
  }
);

const OpenEndedItem = forwardRef<HTMLDivElement, ExposedItemProps>(
  function OpenEndedImpl(props, ref) {
    const { formatMessage } = useIntl();

    return (
      <Item
        icon={<TextAlignLeft />}
        label={formatMessage(messages.openEnded)}
        value="OPEN_ENDED"
        {...props}
        ref={ref}
      />
    );
  }
);

const DropdownItem = forwardRef<HTMLDivElement, ExposedItemProps>(
  function DropdownImpl(props, ref) {
    const { formatMessage } = useIntl();

    return (
      <Item
        icon={<ChevronDownIcon className="stroke-2" />}
        label={formatMessage(messages.dropdown)}
        value="DROPDOWN"
        {...props}
        ref={ref}
      />
    );
  }
);

const ScaleItem = forwardRef<HTMLDivElement, ExposedItemProps>(
  function ScaleImpl(props, ref) {
    const { formatMessage } = useIntl();

    return (
      <Item
        icon={<LinkedScale className="text-gray-8" data-unstyled />}
        label={formatMessage(messages.scale)}
        value="SCALE"
        {...props}
        ref={ref}
      />
    );
  }
);

const MultiChoiceItem = forwardRef<HTMLDivElement, ExposedItemProps>(
  function MultiChoiceImpl(props, ref) {
    const { formatMessage } = useIntl();

    return (
      <Item
        icon={<CheckCircleIcon className="stroke-2" />}
        label={formatMessage(messages.multipleChoice)}
        value="MULTI_CHOICE"
        {...props}
        ref={ref}
      />
    );
  }
);

const FileUploadItem = forwardRef<HTMLDivElement, ExposedItemProps>(
  function FileUploadImpl(props, ref) {
    const { formatMessage } = useIntl();

    return (
      <Item
        icon={<ArrowUpTrayIcon className="stroke-2" />}
        label={formatMessage(messages.fileUpload)}
        value="FILE_UPLOAD"
        {...props}
        ref={ref}
      />
    );
  }
);

const PersonSelectItem = forwardRef<HTMLDivElement, ExposedItemProps>(
  function PersonSelect(props, ref) {
    const { formatMessage } = useIntl();

    return (
      <Item
        icon={<UserIcon className="stroke-2" />}
        label={formatMessage(messages.personSelect)}
        value="PERSON_SELECTOR"
        {...props}
        ref={ref}
      />
    );
  }
);

const GIFItem = forwardRef<HTMLDivElement, ExposedItemProps>(
  function GIFSelect(props, ref) {
    const { formatMessage } = useIntl();

    return (
      <Item
        icon={<GifIcon className="stroke-2" />}
        label={formatMessage(messages.gifSelect)}
        value="GIF"
        {...props}
        ref={ref}
      />
    );
  }
);

const GivePointsItem = forwardRef<HTMLDivElement, ExposedItemProps>(
  function GivePoints({ disabled, ...tail }, ref) {
    const { formatMessage } = useIntl();

    return (
      <Item
        icon={<TrophyIcon className="stroke-2" />}
        label={
          disabled
            ? formatMessage(messages.disabledGivePoints)
            : formatMessage(messages.givePoints)
        }
        value="GIVE_POINTS_STACK"
        disabled={disabled}
        {...tail}
        ref={ref}
      />
    );
  }
);

const NPSItem = forwardRef<HTMLDivElement, ExposedItemProps>(
  function NPSImpl(props, ref) {
    const { formatMessage } = useIntl();

    return (
      <Item
        icon={<GaugeIcon />}
        label={formatMessage(messages.nps)}
        value="NPS"
        {...props}
        ref={ref}
      />
    );
  }
);

const AdvancedQuestionLabel = forwardRef<HTMLDivElement>(
  function AdvancedQuestionLabelImpl(props, ref) {
    const { formatMessage } = useIntl();

    return (
      <Label {...props} ref={ref}>
        {formatMessage(messages.advancedQuestion)}
      </Label>
    );
  }
);

const QuestionTypesLabel = forwardRef<HTMLDivElement>(
  function QuestionTypesLabelImpl(props, ref) {
    const { formatMessage } = useIntl();

    return (
      <Label {...props} ref={ref}>
        {formatMessage(messages.questionTypes)}
      </Label>
    );
  }
);

export const BlockOptionsDropdown = {
  AdvancedQuestionLabel,
  DropdownItem,
  DropdownTypeContextProvider: DropdownTypeContext.Provider,
  FileUploadItem,
  GIFItem,
  GivePointsItem,
  Group,
  Item,
  Label,
  Menu,
  MenuSeparator,
  MenuTrigger,
  MultiChoiceItem,
  NPSItem,
  OpenEndedItem,
  PersonSelectItem,
  QuestionTypesLabel,
  ScaleItem,
  Select,
  SelectConstruct,
  UnstyledMenuItem: DropdownMenu.Item,
  useDropdownType,
};
