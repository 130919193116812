import { getMinutesLimit, reduceMinutesFromDeadline } from './dateTimeUtils';
import { supportedUploadFileTypes } from './fileUtils';
import { PermissionType } from './types/criteriaTypes';
import {
  type AllowedOpenEndedMediaTypes,
  type BasicFlowBlockAttributes,
  BlockTypeMap,
  type ContentBlockBaseState,
  type ContentBuilderBlockData,
  type ContentDropdownBlockState,
  type ContentFileUploadBlockState,
  type ContentGifBlockState,
  type ContentGivePointsBlockState,
  type ContentMultiChoiceBlockState,
  type ContentNPSBlockState,
  type ContentOpenEndedBlockState,
  type ContentPersonSelectorBlockState,
  type ContentScaleBlockState,
  type CriteriaGroup,
  type CriteriaGroups,
  type DropdownFlowBlock,
  type FileUploadFlowBlock,
  type FlowBlockContent,
  type FlowBlockContentPayload,
  type FlowBuilderState,
  type GifUploadFlowBlock,
  type GiveExactPointsRule,
  type GivePointsStackFlowBlock,
  type GivePointsStackRules,
  type MultiChoiceFlowBlock,
  type OpenEndedFlowBlock,
  type OptionItemProps,
  type OptionsSelectObject,
  type PersonSelectorFlowBlockPayload,
  type SaveFlowPayload,
  type ScaleFlowBlock,
  type ScheduleRule,
  SelectablePeopleMap,
  type SelectablePeopleTypes,
  type TriggerBuilderBlockData,
  TriggerMap,
  type TriggerType,
  type VisibilityBuilderBlockData,
} from './types/flowBuilder';
import type {
  CriteriaResponse,
  DeadlineType,
  FlowResponseType,
  MilestoneFrequencyType,
  MilestoneType,
  RemindersType,
  ResponseFrequencyType,
  Rule,
  TimeUnit,
} from './types/flowTypes';
import { CriteriaRuleType, Operator } from './types/shareSheet';

type SerializeBuilderBlockData = FlowBuilderState & {
  canIncludeNewInviteMembers?: boolean;
  isInSchedulerMode?: boolean;
};

export const KnownErrorKeys = {
  Schedule: 'Schedule',
  FlowName: 'FlowName',
} as const;

export function isPersonSelectorBlock(
  block: ContentBlockBaseState
): block is ContentPersonSelectorBlockState {
  return block.type === 'PERSON_SELECTOR';
}

export function isMultiChoiceOrDropdownBlock(
  block: ContentBlockBaseState
): block is ContentMultiChoiceBlockState | ContentDropdownBlockState {
  return block.type === 'DROPDOWN' || block.type === 'MULTI_CHOICE';
}

export function isScaleBlock(
  block: ContentBlockBaseState
): block is ContentScaleBlockState {
  return block.type === 'SCALE';
}

export function isOpenEndedBlock(
  block: ContentBlockBaseState
): block is ContentOpenEndedBlockState {
  return block.type === 'OPEN_ENDED';
}

export function isGivePointsBlock(
  block: ContentBlockBaseState
): block is ContentGivePointsBlockState {
  return block.type === 'GIVE_POINTS_STACK';
}

export function isLinkedBlock(
  block: ContentBlockBaseState
): block is ContentPersonSelectorBlockState {
  return isPersonSelectorBlock(block) && block.isLinkedBlock;
}

function getEndTimeInMinutesForSaveFlowPayload({
  deadlineType,
  flowResponseType,
  responseTimeUnit,
  responseTimeValue,
}: {
  deadlineType: DeadlineType;
  responseTimeValue: number;
  responseTimeUnit: TimeUnit;
  flowResponseType: FlowResponseType;
}) {
  if (deadlineType !== 'schedule' || flowResponseType !== 'deadline') {
    return 1440;
  }

  switch (responseTimeUnit) {
    case 'minutes':
      return Math.max(responseTimeValue, 5);
    case 'hours':
      return responseTimeValue * 60;
    case 'days':
      return responseTimeValue * 24 * 60;
    case 'weeks':
      return responseTimeValue * 7 * 24 * 60;
    default:
      throw new Error('Invalid time unit');
  }
}

function sanitizePlainText(text: string) {
  return text.trim().replace(/\n/g, ' ');
}

export const visibilityTypes = {
  EntireOrganization: 'EVERYONE',
  Everyone: 'EVERYONE', // Just a bit of redundancy here in case any instances of .EVERYONE still exist, pretty sure I caught them all but not 100% and don't want to break the flow builder accidentally over a text change. Once we're sure we should delete this option.
  ParticipantsOnly: 'PARTICIPANTS_ONLY',
  OwnerAndCollaboratorsOnly: 'OWNER_AND_COLLABORATORS_ONLY',
  Custom: 'CUSTOM',
} as const;

export function getMultiOptionsRuleLimit(
  currentOptionSelectObject: OptionsSelectObject
) {
  switch (currentOptionSelectObject.type) {
    case 'UNLIMITED_OPTIONS': {
      return { noLimit: true };
    }
    case 'EXACT_NUMBER': {
      return { exact: currentOptionSelectObject.exactOptions };
    }
    case 'RANGE': {
      return {
        range: {
          max: currentOptionSelectObject.maxOptions,
          min: currentOptionSelectObject.minOptions,
        },
      };
    }
    default: {
      return;
    }
  }
}

function getRuleValue(rule: Rule) {
  if (rule.field === 'everyone') {
    return [];
  }
  return rule.field === 'manager'
    ? [
        {
          id: window.crypto.randomUUID(),
          value:
            rule.value.length > 0 ? 'memberIsManager' : 'memberIsNotManager',
        },
      ]
    : rule.value.map((val) => ({ id: val, value: val }));
}

export function mapCriteriaResponseToBlockData(
  criteriaResponse: CriteriaResponse,
  isSimplifiedShareSheetTreatmentOn: boolean
): CriteriaGroups {
  const { criteria } = criteriaResponse;

  if (criteria.custom) {
    const newCriteriaGroups: CriteriaGroups = {
      groups: [],
      groupsCondition: criteria.custom.condition,
    };

    criteria.custom.rules.forEach((groupRule) => {
      newCriteriaGroups.groups.push({
        groupId: window.crypto.randomUUID(),
        groupCondition: groupRule.condition,
        groupRules: groupRule.rules.map((x) => ({
          value: getRuleValue(x),
          operator: x.operator,
          field: x.field,
          ruleId: window.crypto.randomUUID(),
        })),
      });
    });

    return newCriteriaGroups;
  }

  if (criteria.everyone) {
    const groups: CriteriaGroup[] = [
      {
        groupId: window.crypto.randomUUID(),
        groupCondition: isSimplifiedShareSheetTreatmentOn ? 'or' : 'and',
        groupRules: [
          {
            value: [{ id: 'everyone', value: 'everyone' }],
            operator: 'is',
            field: 'everyone',
            ruleId: window.crypto.randomUUID(),
          },
        ],
      },
    ];
    return {
      groups,
      groupsCondition: isSimplifiedShareSheetTreatmentOn ? 'or' : 'and',
    };
  }

  return {
    groups: [],
    groupsCondition: 'and',
  };
}

export function serializeBlocks(
  contentBlockData: ContentBuilderBlockData
): FlowBlockContentPayload[] {
  const { contentBlocks = [] } = contentBlockData;
  // this ID should be between 5 to 12 characters long
  const randomId = `${Math.random().toString().slice(2, 11)}`;
  return contentBlocks.map((block) => {
    const basicBlockAttributes: BasicFlowBlockAttributes = {
      // Title should have a default value of ... if it does not have a value
      title: sanitizePlainText(block.title) || '...',
      type: BlockTypeMap.Dropdown,
      blockId: block.blockId,
      description: block.description
        ? {
            text: block.description.trim(),
          }
        : undefined,
    };
    switch (block.type) {
      case BlockTypeMap.PersonSelector: {
        const {
          isLinkedBlock,
          optionType,
          selectedBlockParticipants,
          optionSelectObject,
          criteriaGroups,
        } = block;

        const selectSinglePerson = optionType === 'SINGLE';
        return {
          ...basicBlockAttributes,
          type: block.type,
          select_type: selectSinglePerson ? 'SINGLE_PERSON' : 'MULTI_PERSON',
          key: isLinkedBlock ? randomId : undefined,
          rules: {
            required: block.isRequired,
            select: selectedBlockParticipants,
            limit: !selectSinglePerson
              ? getMultiOptionsRuleLimit(optionSelectObject)
              : undefined,
            criteria: criteriaGroups
              ? {
                  custom: {
                    exclude: [],
                    include: criteriaGroups.include.map((group) => {
                      if (group.field === CriteriaRuleType.Everyone) {
                        return {
                          field: CriteriaRuleType.Everyone,
                          value: true,
                          perm: PermissionType.Custom,
                          operator: Operator.Is,
                        };
                      }
                      return {
                        field: group.field,
                        values: group.values.map(({ value, operator }) => ({
                          value: value as string,
                          operator,
                          perm: PermissionType.Custom,
                        })),
                      };
                    }),
                  },
                }
              : undefined,
          },
        } satisfies PersonSelectorFlowBlockPayload;
      }
      case BlockTypeMap.OpenEnded: {
        const {
          maximumCharacters,
          minimumCharacters,
          isRequired,
          openEndedOptions,
        } = block;
        const allowedMedia: AllowedOpenEndedMediaTypes[] = [];
        if (openEndedOptions.attachments) {
          allowedMedia.push('FILES');
        }
        if (openEndedOptions.emojis) {
          allowedMedia.push('EMOJI');
        }
        if (openEndedOptions.gifs) {
          allowedMedia.push('GIF');
        }
        if (openEndedOptions.mentions) {
          allowedMedia.push('MENTION');
        }
        if (openEndedOptions.tasks) {
          allowedMedia.push('TASKS');
        }
        return {
          ...basicBlockAttributes,
          type: block.type,
          rules: {
            max: (maximumCharacters ?? 0) > 0 ? maximumCharacters : undefined,
            min: (minimumCharacters ?? 0) > 0 ? minimumCharacters : undefined,
            required: isRequired,
            allowedMedia,
            fileType: openEndedOptions.attachments
              ? supportedUploadFileTypes
              : undefined,
          },
        } satisfies OpenEndedFlowBlock;
      }
      case BlockTypeMap.Dropdown: {
        const { options, optionSelectObject } = block;
        return {
          ...basicBlockAttributes,
          type: block.type,
          options: options.map(({ value, label, defaultLabel }) => ({
            id: value,
            value:
              label !== '' ? sanitizePlainText(label) : (defaultLabel ?? ''),
          })),
          rules: {
            required: block.isRequired,
            limit: getMultiOptionsRuleLimit(optionSelectObject),
          },
        } satisfies DropdownFlowBlock;
      }
      case BlockTypeMap.MultiChoice: {
        const { options, optionSelectObject } = block;
        const filteredOptions = options.filter(
          (option) => option.value.toLowerCase() !== 'other'
        );
        const optionType =
          optionSelectObject.type === 'EXACT_NUMBER' &&
          optionSelectObject.exactOptions === 1
            ? 'SINGLE'
            : 'MULTI';
        const allowOther = filteredOptions.length < options.length;
        return {
          ...basicBlockAttributes,
          type: block.type,
          options: filteredOptions.map(({ value, label, defaultLabel }) => ({
            id: value,
            value:
              label.trim() !== ''
                ? sanitizePlainText(label)
                : (defaultLabel ?? ''),
          })),
          rules: {
            required: block.isRequired,
            limit: getMultiOptionsRuleLimit(optionSelectObject),
            allowOther,
          },
          optionType,
        } satisfies MultiChoiceFlowBlock;
      }
      case BlockTypeMap.Scale: {
        const {
          minimumRange,
          maximumRange,
          lowLabel,
          highLabel,
          middleLabel,
          isRequired,
        } = block;
        const isAtleastOneLabelPresent = Boolean(
          lowLabel || middleLabel || highLabel
        );
        return {
          ...basicBlockAttributes,
          type: block.type,
          min: minimumRange,
          max: maximumRange,
          rules: {
            required: isRequired,
          },
          labels: isAtleastOneLabelPresent
            ? {
                low: lowLabel || undefined,
                middle: middleLabel || undefined,
                high: highLabel || undefined,
              }
            : undefined,
          isNPSEnabled: false,
        } satisfies ScaleFlowBlock;
      }
      case BlockTypeMap.Nps: {
        const { isRequired } = block;
        return {
          ...basicBlockAttributes,
          type: BlockTypeMap.Scale,
          min: 0,
          max: 10,
          rules: {
            required: isRequired,
          },
          isNPSEnabled: true,
          labels: {
            low: 'Not likely at all',
            high: 'Very likely',
          },
        } satisfies ScaleFlowBlock;
      }
      case BlockTypeMap.Gif: {
        return {
          ...basicBlockAttributes,
          type: block.type,
          rules: {
            required: block.isRequired,
          },
        } satisfies GifUploadFlowBlock;
      }
      case BlockTypeMap.FileUpload: {
        return {
          ...basicBlockAttributes,
          type: block.type,
          rules: {
            required: block.isRequired,
          },
        } satisfies FileUploadFlowBlock;
      }
      case BlockTypeMap.GivePointsStack: {
        const { hideCurrencyValues, limitAmountDetails } = block;
        let pointsRule: GivePointsStackRules['points'];
        if (limitAmountDetails) {
          if (limitAmountDetails.type === 'EXACT') {
            pointsRule = {
              limitType: 'EXACT_VALUE',
              limit: limitAmountDetails.value,
              noLimit: false,
            };
          } else {
            pointsRule = {
              limitType: 'PERCENTAGE',
              limit: limitAmountDetails.value,
              noLimit: false,
            };
          }
        } else {
          pointsRule = {
            noLimit: true,
          };
        }
        return {
          ...basicBlockAttributes,
          type: block.type,
          dependentKeys: [randomId],
          rules: {
            required: block.isRequired,
            hidePoints: hideCurrencyValues,
            points: pointsRule,
          },
        } satisfies GivePointsStackFlowBlock;
      }
    }
  });
}

function returnKind({
  deadlineType,
  currentSchedule,
  flowResponseType,
}: {
  deadlineType: DeadlineType;
  flowResponseType: FlowResponseType;
  currentSchedule: ScheduleRule | undefined;
}): { kind: TriggerType; schedule?: ScheduleRule; shortcut: boolean } {
  if (flowResponseType === 'milestone') {
    return {
      kind: 'MILESTONE',
      shortcut: false,
    };
  }

  if (flowResponseType === 'anytime') {
    return {
      kind: 'NO_TRIGGER',
      shortcut: true,
    };
  } else {
    if (deadlineType === 'manual') {
      return {
        kind: 'ONDEMAND',
        shortcut: false,
      };
    } else {
      return {
        kind: 'SCHEDULED',
        shortcut: false,
        schedule: currentSchedule,
      };
    }
  }
}

function returnMilestoneSettings({
  skipWeekend,
  selectedMilestone,
  flowResponseType,
  responseFrequencyType,
  selectedMilestoneFrequency,
  numberOfResponseForMilestone,
}: {
  skipWeekend: boolean;
  selectedMilestone: MilestoneType;
  flowResponseType: FlowResponseType;
  numberOfResponseForMilestone: number | null;
  responseFrequencyType: ResponseFrequencyType;
  selectedMilestoneFrequency: MilestoneFrequencyType;
}): SaveFlowPayload['milestoneSettings'] {
  if (flowResponseType !== 'milestone') {
    return undefined;
  }

  const afterDaysMap: Record<MilestoneType, number> = {
    startDate: 0,
    oneWeekAfterStartDate: 7,
    oneMonthAfterStartDate: 30,
    oneYearAfterStartDate: 365,
    twoMonthsAfterStartDate: 60,
    threeMonthsAfterStartDate: 90,
  };

  const intervalMap: Record<MilestoneFrequencyType, number> = {
    everyDay: 1,
    everyWeek: 7,
    everyYear: 365,
    everyMonth: 30,
    everyQuarter: 90,
  };

  const afterDays = afterDaysMap[selectedMilestone];
  const interval = intervalMap[selectedMilestoneFrequency];

  const settings = {
    weekendAllowed: !skipWeekend,
    afterDays: selectedMilestone === 'startDate' ? undefined : afterDays,
    triggeredOn:
      selectedMilestone === 'startDate'
        ? ('onDate' as const)
        : ('afterDate' as const),
  };

  if (responseFrequencyType === 'once') {
    return {
      ...settings,
      count: 1,
      interval: 1,
    };
  }

  return {
    ...settings,
    interval,
    count: numberOfResponseForMilestone ?? 1,
  };
}

function returnReminder({
  remindersType,
  remindersCount,
  reminderSchedule,
  flowResponseType,
}: {
  remindersType: RemindersType;
  remindersCount: number | null;
  reminderSchedule: string | null;
  flowResponseType: FlowResponseType;
}): SaveFlowPayload['reminder'] {
  if (
    reminderSchedule &&
    remindersType === 'automate' &&
    flowResponseType === 'anytime'
  ) {
    const reminder: SaveFlowPayload['reminder'] = {
      type: 'AUTOMATED',
      rrule: reminderSchedule,
    };

    return reminder;
  }

  if (flowResponseType === 'deadline') {
    if (remindersType === 'automate') {
      return {
        type: 'AUTOMATED',
        numberOfReminders: remindersCount,
      } satisfies SaveFlowPayload['reminder'];
    } else {
      return {
        type: 'MANUAL',
      } satisfies SaveFlowPayload['reminder'];
    }
  }

  return {
    type: 'MANUAL',
  } satisfies SaveFlowPayload['reminder'];
}

export function serializeBuilderBlockData({
  flowName,
  description,
  owner,
  emoji,
  blockData,
  templateId,
  currentSchedule,
  inEditMode,
  collaborators,
  deadlineType,
  repeatFrequency,
  flowResponseType,
  responseTimeValue,
  responseTimeUnit,
  numberOfResponses,
  remindersCount,
  reminderSchedule,
  remindersType,
  responseFrequencyType,
  selectedMilestone,
  skipWeekend,
  selectedMilestoneFrequency,
  numberOfResponseForMilestone,
  allowMemberLevelOccurrence,
}: SerializeBuilderBlockData): SaveFlowPayload {
  let isEndTimeInMinutesUpdated = false;
  let endTimeInMinutes = getEndTimeInMinutesForSaveFlowPayload({
    deadlineType,
    responseTimeUnit,
    flowResponseType,
    responseTimeValue,
  });

  if (
    repeatFrequency &&
    deadlineType === 'schedule' &&
    flowResponseType === 'deadline'
  ) {
    const maxMinutesLimit = getMinutesLimit(repeatFrequency);
    if (endTimeInMinutes === maxMinutesLimit) {
      endTimeInMinutes = reduceMinutesFromDeadline(endTimeInMinutes);
      isEndTimeInMinutesUpdated = true;
    }
  }

  return {
    name: flowName ?? '',
    ownerId: owner[0]?.memberID,
    collaborators: collaborators.map((collaborator) => collaborator.memberID),
    description: description,
    endTimeInMinutes,
    icon: {
      kind: 'HEX_CODE',
      value: emoji.unified,
    },
    action: {
      kind: 'FORM',
      blocks: serializeBlocks(blockData.CONTENT),
      templateId: templateId && !inEditMode ? templateId : undefined,
    },
    numberOfResponses:
      deadlineType === 'schedule' ? numberOfResponses : undefined,
    ...returnKind({
      deadlineType,
      currentSchedule,
      flowResponseType,
    }),
    reminder: returnReminder({
      remindersType,
      remindersCount,
      reminderSchedule,
      flowResponseType,
    }),
    allowMemberLevelOccurrence,
    metaData: {
      responseFrequencyType,
      isEndTimeInMinutesUpdated,
    },
    milestoneSettings: returnMilestoneSettings({
      flowResponseType,
      selectedMilestone,
      responseFrequencyType,
      selectedMilestoneFrequency,
      numberOfResponseForMilestone,
      skipWeekend: Boolean(skipWeekend),
    }),
  };
}

export function getHTMLFromPlainText(plainText: string) {
  return `<p dir="ltr"><span style="white-space: pre-wrap;">${sanitizePlainText(plainText)}</span></p>`;
}

export function getJSONFromPlainText(plainText: string) {
  return `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"${sanitizePlainText(plainText)}","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`;
}

export function mapContentBlockFromTemplateResponse(block: FlowBlockContent) {
  const blockBase: ContentBlockBaseState = {
    id: block.blockId ?? window.crypto.randomUUID(),
    type: 'SCALE',
    title: block.title,
    blockId: block.blockId,
    description: block.description?.text ?? '',
    isRequired: Boolean(block.rules?.required),
    isLinkedBlock: 'key' in block ? Boolean(block.key) : false,
  };

  switch (block.type) {
    case BlockTypeMap.Nps: {
      return {
        ...blockBase,
        type: block.type,
      } satisfies ContentNPSBlockState;
    }
    case BlockTypeMap.Scale: {
      return {
        ...blockBase,
        type: block.type,
        minimumRange: block.min,
        maximumRange: block.max,
        lowLabel: block.labels?.low ?? '',
        middleLabel: block.labels?.middle ?? '',
        highLabel: block.labels?.high ?? '',
      } satisfies ContentScaleBlockState;
    }
    case BlockTypeMap.Gif: {
      return {
        ...blockBase,
        type: block.type,
      } satisfies ContentGifBlockState;
    }
    case BlockTypeMap.FileUpload: {
      return {
        ...blockBase,
        type: block.type,
      } satisfies ContentFileUploadBlockState;
    }
    case BlockTypeMap.OpenEnded: {
      return {
        ...blockBase,
        type: block.type,
        minimumCharacters: block.rules?.min,
        maximumCharacters: block.rules?.max,
        openEndedOptions: {
          gifs: Boolean(block.rules?.allowedMedia?.includes('GIF')),
          emojis: Boolean(block.rules?.allowedMedia?.includes('EMOJI')),
          attachments: Boolean(block.rules?.allowedMedia?.includes('FILES')),
          mentions: Boolean(block.rules?.allowedMedia?.includes('MENTION')),
          tasks: Boolean(block.rules?.allowedMedia?.includes('TASKS')),
        },
      } satisfies ContentOpenEndedBlockState;
    }
    case BlockTypeMap.Dropdown:
    case BlockTypeMap.MultiChoice: {
      let currentOptionSelectObject: OptionsSelectObject = {
        type: 'UNLIMITED_OPTIONS',
      };

      if (block.rules && block.rules.limit && !block.rules.limit.noLimit) {
        if (block.rules.limit.range) {
          currentOptionSelectObject = {
            type: 'RANGE',
            maxOptions: block.rules.limit.range.max,
            minOptions: block.rules.limit.range.min,
          };
        } else {
          currentOptionSelectObject = {
            type: 'EXACT_NUMBER',
            exactOptions: block.rules.limit.exact ? block.rules.limit.exact : 1,
          };
        }
      }

      let options: OptionItemProps[] = block.options.map((option) => ({
        value: option.id,
        label: option.value,
      }));

      if (block.type === 'MULTI_CHOICE' && block.rules?.allowOther) {
        options = [
          ...options,
          {
            value: 'other',
            autoFocus: true,
            label: '',
            defaultLabel: `Option ${block.options.length}`,
          },
        ];
      }

      return {
        ...blockBase,
        type: block.type,
        optionType: block.rules?.limit?.exact === 1 ? 'SINGLE' : 'MULTI',
        options,
        maximumSelectableOptions: block.rules?.limit?.range?.max
          ? block.rules.limit.range.max
          : block.options.length,
        optionSelectObject: currentOptionSelectObject,
        choices: [],
      } satisfies ContentMultiChoiceBlockState | ContentDropdownBlockState;
    }
    case BlockTypeMap.GivePointsStack: {
      const isGivePointsCurrencyType = (
        detail: unknown
      ): detail is GiveExactPointsRule => {
        return (
          detail !== undefined &&
          detail !== null &&
          typeof detail === 'object' &&
          'limitType' in detail
        );
      };

      return {
        ...blockBase,
        type: block.type,
        hideCurrencyValues: Boolean(block.rules.hidePoints),
        limitAmountDetails: isGivePointsCurrencyType(block.rules.points)
          ? {
              value: block.rules.points.limit || 20,
              type:
                block.rules.points.limitType === 'EXACT_VALUE'
                  ? 'EXACT'
                  : 'PERCENT',
            }
          : undefined,
        isLinkedBlock: true,
      } satisfies ContentGivePointsBlockState;
    }
    case BlockTypeMap.PersonSelector: {
      let optionSelectObject: OptionsSelectObject = {
        type: 'UNLIMITED_OPTIONS',
      };

      if (block.rules && block.rules.limit && !block.rules.limit.noLimit) {
        if (block.rules.limit.range) {
          optionSelectObject = {
            type: 'RANGE',
            maxOptions: block.rules.limit.range.max,
            minOptions: block.rules.limit.range.min,
          };
        } else {
          optionSelectObject = {
            type: 'EXACT_NUMBER',
            exactOptions: block.rules.limit.exact ? block.rules.limit.exact : 1,
          };
        }
      }

      return {
        ...blockBase,
        type: block.type,
        isRequired: Boolean(block.rules?.required),
        selectedBlockParticipants: block.rules?.select ?? 'EVERYONE',
        optionType: block.select_type === 'SINGLE_PERSON' ? 'SINGLE' : 'MULTI',
        ...(block.rules?.select === 'CUSTOM' &&
          block.rules.criteria && {
            criteriaGroups: block.rules.criteria.custom,
          }),
        optionSelectObject,
        initialized: block.rules?.select !== 'CUSTOM',
      } satisfies ContentPersonSelectorBlockState;
    }
  }
}

export function getContent<T extends Partial<{ blocks: FlowBlockContent[] }>>(
  data: T
): ContentBuilderBlockData {
  return {
    contentBlocks:
      data.blocks?.map((block) =>
        mapContentBlockFromTemplateResponse(
          block.type === BlockTypeMap.Scale && block.isNPSEnabled
            ? { ...block, type: 'NPS' }
            : block
        )
      ) ?? [],
    errors: null,
  };
}

export function getVisibility<T extends Partial<{ viewing: CriteriaResponse }>>(
  data: T
): VisibilityBuilderBlockData {
  if (data.viewing) {
    const { criteria } = data.viewing;
    let type: SelectablePeopleTypes = SelectablePeopleMap.Everyone;
    if (criteria.onlyOwnersAndCollaborators) {
      type = SelectablePeopleMap.Viewers;
    }

    if (criteria.onlyParticipants) {
      type = SelectablePeopleMap.Participants;
    }

    if (criteria.custom) {
      type = SelectablePeopleMap.Custom;
    }

    return {
      type,
      errors: null,
      custom: Boolean(criteria.custom),
      everyone: Boolean(criteria.everyone),
      onlyOwnersAndCollaborators: Boolean(criteria.onlyOwnersAndCollaborators),
      onlyParticipants: Boolean(criteria.onlyParticipants),
      criteriaGroups: mapCriteriaResponseToBlockData(data.viewing, true),
    };
  }
  return {
    type: SelectablePeopleMap.Everyone,
    everyone: true,
    onlyParticipants: false,
    onlyOwnersAndCollaborators: false,
    custom: false,
  };
}

export function getTrigger<
  T extends Partial<{
    shortcut: boolean;
    schedule: { rule: string };
    endTimeInMinutes: number;
    kind: string;
  }>,
>(data: T): TriggerBuilderBlockData {
  let triggerType: TriggerType = TriggerMap.OnDemand;
  if (data.kind === TriggerMap.NoTrigger) {
    triggerType = TriggerMap.NoTrigger;
  }
  return {
    selectedCustomRecurrenceTypes: undefined,
    shortcut: data.shortcut ?? false,
    schedule: data.schedule,
    endTimeInMinutes: data.endTimeInMinutes ? data.endTimeInMinutes : 0,
    triggerType,
    isSchedulerTouched: false,
  };
}

export const flowEditorValidators = {
  constants: {
    REQUIRED: 'required',
    TOO_LONG: 'too long',
    INVALID: 'invalid',
  },
  title: (title: string | undefined) => {
    const titleLength = title?.trim().length || 0;

    if (!title || titleLength === 0) {
      return flowEditorValidators.constants.REQUIRED;
    }

    if (titleLength > 30) {
      return flowEditorValidators.constants.TOO_LONG;
    }

    return null;
  },
  questionText: (questionText: string | undefined) => {
    const questionTextLength = questionText?.trim().length || 0;

    if (!questionText || questionTextLength === 0) {
      return flowEditorValidators.constants.REQUIRED;
    }

    return null;
  },
  openEndedBlockLimits: ({
    min,
    max,
  }: {
    min: number | undefined;
    max: number | undefined;
  }) => {
    if (max && min && min > max) {
      return flowEditorValidators.constants.INVALID;
    }
    if (max && max < 2) {
      return flowEditorValidators.constants.INVALID;
    }
    return null;
  },
  selectBlockOptions: (options: unknown[]) => {
    if (options.length === 0) {
      return flowEditorValidators.constants.REQUIRED;
    }

    return null;
  },
  selectBlockOptionText: (option: OptionItemProps) => {
    if (
      option.value !== 'other' &&
      (!option.label || option.label.trim().length === 0)
    ) {
      return flowEditorValidators.constants.REQUIRED;
    }

    return null;
  },
  scaleLabels: (labels: { low: string; middle: string; high: string }) => {
    const maxLabelLength = 30;

    if (!labels.low || labels.low.trim().length === 0) {
      return flowEditorValidators.constants.REQUIRED;
    }
    if (!labels.middle || labels.middle.trim().length === 0) {
      return flowEditorValidators.constants.REQUIRED;
    }
    if (!labels.high || labels.high.trim().length === 0) {
      return flowEditorValidators.constants.REQUIRED;
    }

    if (
      labels.low.trim().length > maxLabelLength ||
      labels.high.trim().length > maxLabelLength ||
      labels.middle.trim().length > maxLabelLength
    ) {
      return flowEditorValidators.constants.TOO_LONG;
    }

    return null;
  },
  pointsPercent: (points: number) => {
    if (points < 0 || points > 100) {
      return flowEditorValidators.constants.INVALID;
    }

    return null;
  },
  selectionRange: (option: OptionsSelectObject) => {
    if (option.type === 'EXACT_NUMBER') {
      if (!option.exactOptions || option.exactOptions < 1) {
        return flowEditorValidators.constants.INVALID;
      }
    }
    if (option.type === 'RANGE') {
      if (!option.minOptions || option.minOptions < 0) {
        return flowEditorValidators.constants.INVALID;
      }
      if (!option.maxOptions || option.maxOptions < 1) {
        return flowEditorValidators.constants.INVALID;
      }
    }
  },
} as const;
