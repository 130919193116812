import type { MergeExclusive } from 'type-fest';

import type {
  DropdownBlockSettings,
  FileUploadBlockSettings,
  GifBlockSettings,
  GivePointsBlockSettings,
  MultiChoiceBlockSettings,
  OpenEndedBlockSettings,
  PersonSelectorBlockSettings,
  ScaleBlockSettings,
} from './blockSettingsTypes';
import type {
  BlockResponseState,
  BlockTypes,
  MemberDetails,
  MemberState,
} from './flowTypes';

export type File = {
  thumbnails?:
    | {
        256: string;
      }
    | {
        360: string;
      };
  type: string;
  location: string;
  name: string;
  size: number; // in bytes
  createdAt: string;
  _id: string;
  originalName?: string;
};

export enum TaskState {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
}

export type TaskContentFromAPITypes = 'text' | 'break';

export type BasicTaskContentFromAPI = {
  type: TaskContentFromAPITypes;
};

export type TextTaskContent = {
  type: 'text';
  value: string;
  props?: { link?: string };
} & BasicTaskContentFromAPI;

export type BreakTaskContent = {
  type: 'break';
} & BasicTaskContentFromAPI;

export type TaskContent = TextTaskContent | BreakTaskContent;

type Task = {
  dueDate?: string;
  id: string;
  state: TaskState;
  title: TaskContent[];
};

type Mention = {
  email?: string;
  firstName: string;
  lastName: string;
  memberId: string;
  memberID?: string;
  pointsGiven?: number;
  totalPointsGiven?: number;
  username?: string;
  role?: string[];
  memberState: MemberState;
  image?: string;
  status?: string;
};

type Tag = { tag: string; displayText: string };

export type OpenEndedBlockResponse = MergeExclusive<
  {
    value: string;
    mentions?: Mention[];
  },
  {
    valueHtml: string;
    taggedUsers: MemberDetails[];
  }
> & {
  files?: File[];
  gifUrl?: string;
  tags?: Tag[];
  tasks?: Task[];
};

type FileUploadBlockResponse = {
  files: File[];
};

type ScaleBlockResponse = {
  value: number;
};

type GifBlockResponse = {
  value: string;
};

type MultiChoiceBlockResponse = {
  value: { id: string; value: string }[];
};

export type PersonSelectorBlockResponse = {
  persons: {
    name: { firstName: string; lastName?: string };
    memberId: string;
    memberState: MemberState;
    image?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    pointsGiven?: number;
    totalPointsGiven?: number;
    username?: string;
    role?: string[];
    status?: string;
  }[];
};

export type DepartmentChipData = {
  type: 'department';
  id?: string;
  name: string;
  count: number;
  criteriaId?: string;
  postId?: string;
};
export type MemberChipData = { type: 'member' } & MemberDetails;

export type GroupOrUserRecipientData = DepartmentChipData | MemberChipData;

export type ExtendedGroupOrUserRecipientData =
  | GroupOrUserRecipientData
  | { type: 'everyone'; count: number; name: string }
  | { type: 'manager'; count: number; name: string }
  | { type: 'role'; count: number; name: string };

export type GroupAndPersonSelectorBlockResponse = {
  recipients: GroupOrUserRecipientData[];
};

type GivePointsStackBlockResponse = {
  value: number;
};

type BaseBlockResponseDetails = {
  blockId: string;
  edited: boolean;
};

type DropdownBlockResponse = {
  value: { id: string; value: string }[];
};

type DropdownBlockResponseDetails =
  | (BaseBlockResponseDetails & {
      type: BlockTypes.Dropdown;
      state: BlockResponseState.Responded;
      response: DropdownBlockResponse;
      content: DropdownBlockSettings;
    })
  | (BaseBlockResponseDetails & {
      type: BlockTypes.Dropdown;
      state: BlockResponseState.Skipped;
      content: DropdownBlockSettings;
      response: null;
    });

export type OpenEndedBlockResponseDetails =
  | (BaseBlockResponseDetails & {
      type: BlockTypes.OpenEnded;
      state: BlockResponseState.Skipped;
      response: {
        files: [];
      };
      content: OpenEndedBlockSettings;
    })
  | (BaseBlockResponseDetails & {
      type: BlockTypes.OpenEnded;
      state: BlockResponseState.Responded;
      response: OpenEndedBlockResponse;
      content: OpenEndedBlockSettings;
    });

export type FileUploadBlockResponseDetails = BaseBlockResponseDetails & {
  type: BlockTypes.FileUpload;
  response: FileUploadBlockResponse;
  content: FileUploadBlockSettings;
  state: BlockResponseState;
};

type ScaleBlockResponseDetails =
  | (BaseBlockResponseDetails & {
      type: BlockTypes.Scale;
      state: BlockResponseState.Responded;
      response: ScaleBlockResponse;
      content: ScaleBlockSettings;
    })
  | (BaseBlockResponseDetails & {
      type: BlockTypes.Scale;
      state: BlockResponseState.Skipped;
      response: null;
      content: ScaleBlockSettings;
    });

type GifBlockResponseDetails =
  | (BaseBlockResponseDetails & {
      type: BlockTypes.Gif;
      state: BlockResponseState.Responded;
      response: GifBlockResponse;
      content: GifBlockSettings;
    })
  | (BaseBlockResponseDetails & {
      type: BlockTypes.Gif;
      state: BlockResponseState.Skipped;
      response: null;
      content: GifBlockSettings;
    });

type MultiChoiceBlockResponseDetails =
  | (BaseBlockResponseDetails & {
      type: BlockTypes.MultiChoice;
      state: BlockResponseState.Responded;
      response: MultiChoiceBlockResponse;
      content: MultiChoiceBlockSettings;
    })
  | (BaseBlockResponseDetails & {
      type: BlockTypes.MultiChoice;
      state: BlockResponseState.Skipped;
      response: null;
      content: MultiChoiceBlockSettings;
    });

type PersonSelectorBlockResponseDetails =
  | (BaseBlockResponseDetails & {
      type: BlockTypes.PersonSelector;
      state: BlockResponseState.Responded;
      response: PersonSelectorBlockResponse;
      content: PersonSelectorBlockSettings;
    })
  | (BaseBlockResponseDetails & {
      type: BlockTypes.PersonSelector;
      state: BlockResponseState.Responded;
      response: GroupAndPersonSelectorBlockResponse;
      content: PersonSelectorBlockSettings;
    })
  | (BaseBlockResponseDetails & {
      type: BlockTypes.PersonSelector;
      state: BlockResponseState.Skipped;
      response: null;
      content: PersonSelectorBlockSettings;
    });

type GivePointsStackBlockResponseDetails =
  | (BaseBlockResponseDetails & {
      type: BlockTypes.GivePointsStack;
      state: BlockResponseState.Responded;
      response: GivePointsStackBlockResponse;
      content: GivePointsBlockSettings;
    })
  | (BaseBlockResponseDetails & {
      type: BlockTypes.GivePointsStack;
      state: BlockResponseState.Skipped;
      response: null;
      content: GivePointsBlockSettings;
    });

export type BlockResponseDetails =
  | DropdownBlockResponseDetails
  | OpenEndedBlockResponseDetails
  | FileUploadBlockResponseDetails
  | ScaleBlockResponseDetails
  | GifBlockResponseDetails
  | MultiChoiceBlockResponseDetails
  | PersonSelectorBlockResponseDetails
  | GivePointsStackBlockResponseDetails;
