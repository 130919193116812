import type {
  AmazonOrderPayload,
  AmazonOrderResponse,
  AmazonProduct,
  AmazonRedemptionFormDetails,
  AmazonRewardDetailsForm,
  Amount,
  ProductVariation,
  Quantity,
  SearchableSelectOptionType,
  ShippingCountry,
  ShippingDetailsForm,
} from '@assembly-web/services';
import {
  generateDescriptionString,
  getSelectedVariationId,
  getShippingAndTaxDetails,
} from '@assembly-web/services';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import {
  lazy,
  type ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Banner } from '../../../DesignSystem/Feedback/Banner';
import { LoadingSpinner } from '../../../DesignSystem/Feedback/Icons/LoadingSpinner';
import { Modal } from '../../../DesignSystem/Feedback/Modal';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { HorizontalRule } from '../../../DesignSystem/Layout/HorizontalRule';
import { RewardPlaceholderImage } from '../../assets/images';
import {
  G2ReviewModal,
  type G2ReviewModalProps,
} from '../Modals/G2ReviewModal/G2ReviewModal';
import { AmazonRedemptionForm } from './AmazonRedemptionForm';
import { RedemptionDetailsModalSkeletonLoader } from './RedemptionDetailsModalSkeletonLoader';
import { RedemptionModalState } from './RedemptionModal';
import { ShipToDetails } from './ShipToDetails';

const AmazonRewardRedemptionSuccessful = lazy(() =>
  import('./AmazonRewardRedemptionSuccessful').then((module) => ({
    default: module.AmazonRewardRedemptionSuccessful,
  }))
);

const RewardRedemptionModalError = lazy(() =>
  import('./RewardRedemptionErrorModal').then((module) => ({
    default: module.RewardRedemptionModalError,
  }))
);

const messages = defineMessages({
  redeemReward: {
    defaultMessage: 'Redeem reward',
    id: 'Yo4bLe',
  },
  cost: {
    defaultMessage: 'Cost',
    id: 'fBG/Ge',
  },
  redeem: {
    defaultMessage: 'Redeem',
    id: 'XSdWHA',
  },
  continueToShipping: {
    defaultMessage: 'Continue to shipping',
    id: 'DgnS8R',
  },
  finalizeDetails: {
    defaultMessage: 'Finalize details',
    id: 'G+LU2+',
  },
  back: {
    defaultMessage: 'Back',
    id: 'cyR7Kh',
  },
  placeOrder: {
    defaultMessage: 'Place order',
    id: 'JItzoH',
  },
  description: {
    defaultMessage: 'Description',
    id: 'Q8Qw5B',
  },
  noDescriptionFound: {
    defaultMessage: 'No description found',
    id: 'EDZNX1',
  },
  shippingDisclaimerText: {
    defaultMessage: `We automatically choose the best shipping method for you, so you don’t have to worry about that.
Please confirm that your order and address are correct, and then click “Place order” to finalize your purchase.`,
    id: 'oYo/AN',
  },
  noReturnsOrCancellations: {
    defaultMessage: 'No returns or cancellations available for this order.',
    id: 'rJLURn',
  },
  aboutShipping: {
    defaultMessage: 'About shipping',
    id: 'mK1cLi',
  },
  details: {
    defaultMessage: 'Details',
    id: 'Lv0zJu',
  },
  shippingDetailsHeader: {
    defaultMessage: 'Shipping details',
    id: 'iEDa4y',
  },
  confirmDetailsHeader: {
    defaultMessage: 'Confirm details',
    id: 'Qb3uSJ',
  },
  shippingCostTooltip: {
    defaultMessage:
      'Shipping and taxes not included and will be calculated after you input your shipping address.',
    id: '2lJ4pZ',
  },
  notEnoughPoints: {
    defaultMessage:
      'You do not have enough {currencies} to purchase this item after selecting this option. Try selecting a different option.',
    id: '232dJq',
  },
  total: {
    defaultMessage: '{currency} {value}',
    id: 'tlK2Zz',
  },
  totalOnConfirm: {
    defaultMessage: '{currency} {value} total',
    id: 't8LNZe',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  confirmAndRedeem: {
    defaultMessage: 'Confirm and redeem',
    id: 'dBp3Wo',
  },
  finalReminderText: {
    defaultMessage:
      'This is your final reminder that this purchase is final and cannot bereturned or refunded. Click ‘Confirm and redeem’ to confirm, or ‘Cancel’ to adjust your order.',
    id: '8dX5Rh',
  },
  confirmModalTitle: {
    defaultMessage: 'Confirm no returns or exchanges',
    id: 'A24Tkv',
  },
});

export enum AmazonRedemptionFormStep {
  AmazonRewardDetails = 'amazon-reward-details',
  ShippingDetails = 'shipping-details',
  ConfirmDetails = 'confirm-details',
}

export type AmazonRewardDetailsProps = {
  rewardDetails?: string;
  availableQuantities?: Quantity[];
  shippableCountries?: ShippingCountry[];
  stateList?: SearchableSelectOptionType[];
  onCountryChange?: (country: string) => void;
  selectedVariationId?: string;
  handleVariationChange?: (variationId: string | undefined) => void;
  productDetails?: AmazonProduct;
  productCost?: string;
  pointsEarned?: number;
  currencies?: string;
  assemblyName?: string;
  amazonOrderResponse?: AmazonOrderResponse;
  confirmDetailsData?: {
    shippingCost: number;
    tax: number;
    totalCost: number;
    principalCost: number;
    currencyCode: string;
    totalCostInCarrots: number;
    principalCostInCarrots: number;
    shippingCostInCarrots: number;
    taxCostInCarrots: number;
  };
};

export type AmazonRewardRedemptionInProgressProps = {
  rewardCardImage?: string;
  rewardName: string;
  rewardDescription: string;
  redemptionInfoBannerText?: string;
  onPlaceOrderClick: (
    payload: AmazonOrderPayload,
    details: AmazonRedemptionFormDetails
  ) => void;
  onFinalizeDetails: (
    payload: AmazonOrderPayload,
    amazonFormData: AmazonRewardDetailsForm
  ) => void;
  email: string;
  isPlaceOrderInProgress?: boolean;
  isFinalizeDetailsInProgress?: boolean;
  cost: string;
  currencyIcon: ReactNode;
  id: string;
  itemId: string;
  isDetailsLoading?: boolean;
  isError?: boolean;
  isAmazonDetailsSuccess?: boolean;
  isStateListLoading?: boolean;
  shippingCost?: number;
  handleStepChange?: (newStep: AmazonRedemptionFormStep) => void;
  setTitle?: (title: string) => void;
  variations: ProductVariation;
  maxQuantity: number;
  selectedCountry: string;
  amount: Amount;
  amazonOrderResponse?: AmazonOrderResponse;
  needMorePoints?: boolean;
  amazonRedemptionError?: string;
  details?: string[];
  resetRedemptionErrors: () => void;
  showNoReturnAlert?: boolean;
};

export type AmazonRewardRedemptionSuccessfulProps = {
  rewardCardImage?: string;
  rewardName: string;
  rewardDescription: string;
  email: string;
  cost: string;
  currencyIcon: ReactNode;
  id: string;
  itemId: string;
  redemptionInfoBannerText?: string | ReactNode | undefined;
  orderDetails?: AmazonRedemptionFormDetails;
  shippingCost?: number;
  setTitle?: (title: string) => void;
  maxQuantity: number;
  selectedCountry: string;
  amount: Amount;
  amazonOrderResponse?: AmazonOrderResponse;
  totalCost: string;
  redemptionDetails: string;
};

export type AmazonRewardRedemptionErrorProps = {
  itemId: string;
  errorText?: string;
  errorDescription?: string;
  setTitle?: (title: string) => void;
  maxQuantity: number;
  selectedCountry: string;
  amount: Amount;
  amazonOrderResponse?: AmazonOrderResponse;
};

type G2ReviewProps = {
  showG2Review: boolean;
} & G2ReviewModalProps;

export type AmazonRewardRedemptionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  error?: unknown;
  handleStepChange?: (newStep: AmazonRedemptionFormStep) => void;
} & (
  | ({
      state: RedemptionModalState.Redeeming;
    } & AmazonRewardRedemptionInProgressProps &
      AmazonRewardDetailsProps)
  | ({
      state: RedemptionModalState.Redeemed;
      g2Review: G2ReviewProps;
    } & AmazonRewardRedemptionSuccessfulProps)
  | ({
      state: RedemptionModalState.Error;
    } & AmazonRewardRedemptionErrorProps)
);

export function AmazonRewardRedemptionModal({
  isOpen,
  onClose,
  isLoading,
  ...props
}: AmazonRewardRedemptionModalProps) {
  const [title, setTitle] = useState<string | null>(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      className="flex flex-col"
      headerClassName="pb-4 border-b border-gray-4 z-50"
      bodyClassName="p-0 flex flex-col flex-1"
    >
      {props.state === RedemptionModalState.Redeeming && (
        <AmazonRewardRedemptionInProgress setTitle={setTitle} {...props} />
      )}
      {props.state === RedemptionModalState.Redeemed && (
        <Suspense fallback={<RedemptionDetailsModalSkeletonLoader />}>
          <AmazonRewardRedemptionSuccessful
            {...props}
            setTitle={setTitle}
            handleRedemptionModalClose={onClose}
          />
          {Boolean(props.g2Review.showG2Review) && (
            <G2ReviewModal {...props.g2Review} />
          )}
        </Suspense>
      )}
      {props.state === RedemptionModalState.Error && (
        <Suspense fallback={<RedemptionDetailsModalSkeletonLoader />}>
          <RewardRedemptionModalError {...props} setTitle={setTitle} />
        </Suspense>
      )}
    </Modal>
  );
}

function AmazonRewardRedemptionInProgress({
  rewardCardImage,
  rewardName,
  rewardDescription,
  cost,
  currencyIcon,
  redemptionInfoBannerText,
  availableQuantities,
  stateList,
  onCountryChange,
  onPlaceOrderClick,
  onFinalizeDetails,
  isPlaceOrderInProgress,
  isFinalizeDetailsInProgress,
  isDetailsLoading,
  isStateListLoading,
  shippingCost,
  handleStepChange: onStepChange,
  setTitle,
  shippableCountries,
  variations,
  selectedCountry,
  handleVariationChange,
  productDetails,
  productCost,
  selectedVariationId,
  pointsEarned,
  currencies,
  itemId,
  amount,
  assemblyName,
  amazonOrderResponse,
  confirmDetailsData,
  needMorePoints,
  amazonRedemptionError,
  details,
  resetRedemptionErrors,
  showNoReturnAlert,
}: AmazonRewardRedemptionInProgressProps & AmazonRewardDetailsProps) {
  const title = productDetails?.title ?? rewardName;
  const image = productDetails?.image ?? rewardCardImage;
  const productVariations = productDetails?.productVariations ?? variations;
  const productDescription = productDetails?.description ?? rewardDescription;
  const amazonProductDetails = productDetails?.features ?? details;
  const rewardCost = productDetails && productCost ? productCost : cost;
  const dimensions = productVariations.dimensions.map((d) => {
    return {
      name: d.displayString,
      options: d.dimensionValues.map((v) => {
        return {
          id: `index:${d.index},value:${v.index}`,
          name: v.displayString,
          value: v.index,
        };
      }),
    };
  });
  const availableOptions = [...dimensions];
  if (availableQuantities) {
    availableOptions.push({
      name: 'quantity',
      options: availableQuantities.map((q) => {
        return {
          id: q.id,
          name: q.name,
          value: q.value as number,
        };
      }),
    });
  }
  const { formatMessage } = useIntl();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [step, setStep] = useState(
    AmazonRedemptionFormStep.AmazonRewardDetails
  );
  const [shippingDetails, setShippingDetails] =
    useState<ShippingDetailsForm | null>(null);
  const [selectedRewardInfo, setSelectedRewardInfo] =
    useState<AmazonRewardDetailsForm | null>(null);
  const [amazonFormData, setAmazonFormData] = useState<AmazonRewardDetailsForm>(
    {}
  );

  const selectedQuantity =
    typeof selectedRewardInfo?.quantity === 'object' &&
    'value' in selectedRewardInfo.quantity
      ? (selectedRewardInfo.quantity.value as number)
      : 1;

  const cannotRedeemReward =
    (pointsEarned ?? 0) < parseInt(rewardCost, 10) * selectedQuantity;

  useEffect(() => {
    if (cannotRedeemReward) {
      setIsContinueToShippingDisabled(true);
    }
  }, [cannotRedeemReward]);

  const titleMap = useMemo(
    () => ({
      [AmazonRedemptionFormStep.AmazonRewardDetails]: formatMessage(
        messages.redeemReward
      ),
      [AmazonRedemptionFormStep.ShippingDetails]: formatMessage(
        messages.shippingDetailsHeader
      ),
      [AmazonRedemptionFormStep.ConfirmDetails]: formatMessage(
        messages.confirmDetailsHeader
      ),
    }),
    [formatMessage]
  );

  const handleConfirmModalClose = useCallback(() => {
    setOpenConfirmModal(false);
  }, []);

  useEffect(() => {
    setTitle?.(titleMap[AmazonRedemptionFormStep.AmazonRewardDetails]);
  }, [setTitle, titleMap]);

  const [isContinueToShippingDisabled, setIsContinueToShippingDisabled] =
    useState<boolean>(true);
  const [isConfirmDetailsDisabled, setIsConfirmDetailsDisabled] =
    useState<boolean>(true);
  const [isPlaceOrderDisabled, setIsPlaceOrderDisabled] =
    useState<boolean>(false);

  useEffect(() => {
    if (amazonRedemptionError) {
      if (step === AmazonRedemptionFormStep.ShippingDetails) {
        setIsConfirmDetailsDisabled(true);
      } else if (step === AmazonRedemptionFormStep.ConfirmDetails) {
        setIsPlaceOrderDisabled(true);
      }
    }
  }, [amazonRedemptionError, step]);

  useEffect(() => {
    if (needMorePoints && step === AmazonRedemptionFormStep.ConfirmDetails) {
      setIsPlaceOrderDisabled(true);
    }
  }, [needMorePoints, step]);

  const handleStepChange = useCallback(
    (newStep: AmazonRedemptionFormStep) => {
      setStep(newStep);
      onStepChange?.(newStep);
      setTitle?.(titleMap[newStep]);
    },
    [onStepChange, setTitle, titleMap]
  );

  useEffect(() => {
    if (
      amazonOrderResponse &&
      step === AmazonRedemptionFormStep.ShippingDetails
    ) {
      handleStepChange(AmazonRedemptionFormStep.ConfirmDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amazonOrderResponse]);

  const handleCountryChange = (country: string) => {
    onCountryChange?.(country);
  };

  const onAmazonFormUpdate = (
    data: AmazonRewardDetailsForm,
    hasErrors: boolean
  ) => {
    handleVariationChange?.(
      getSelectedVariationId(data, variations.variations)
    );
    setAmazonFormData(data);
    setSelectedRewardInfo(data);
    if (!hasErrors) {
      resetRedemptionErrors();
      setIsContinueToShippingDisabled(false);
    } else {
      setIsContinueToShippingDisabled(true);
    }
  };

  const onShippingFormUpdate = (
    data: ShippingDetailsForm,
    hasErrors: boolean
  ) => {
    setShippingDetails(data);
    if (!hasErrors) {
      setIsConfirmDetailsDisabled(false);
    } else {
      setIsConfirmDetailsDisabled(true);
    }
  };

  const onContinueToShippingClick = () => {
    if (!isContinueToShippingDisabled) {
      handleStepChange(AmazonRedemptionFormStep.ShippingDetails);
    }
  };

  const handlePlaceOrderClick = (details: AmazonRedemptionFormDetails) => {
    if (amazonRedemptionError || needMorePoints) {
      resetRedemptionErrors();
    }
    if (showNoReturnAlert) {
      setOpenConfirmModal(false);
    }
    if (confirmDetailsData) {
      const unitPrice = Math.ceil(productDetails?.amount.value ?? amount.value);
      const payload: AmazonOrderPayload = {
        shippingAddress: {
          fullName: details.firstName + ' ' + details.lastName,
          phoneNumber: details.phoneNumber,
          companyName: assemblyName ?? details.company ?? '',
          addressLine1: details.address,
          addressLine2: details.secondaryAddress ?? '',
          city: details.city,
          stateOrRegion: details.state?.id ?? '',
          postalCode: details.zip,
          country: details.country.id,
        },
        price: {
          unitPrice,
          currencyCode:
            productDetails?.amount.currencyCode ?? amount.currencyCode,
          taxes: confirmDetailsData.tax,
          total: confirmDetailsData.totalCost,
          shipping:
            confirmDetailsData.shippingCost === 0
              ? 100
              : confirmDetailsData.shippingCost,
          totalPointsToRedeem: confirmDetailsData.totalCostInCarrots,
        },
        productId: selectedVariationId ?? itemId,
        productDetails: {
          title,
          image: image ?? '',
          variations: [generateDescriptionString(amazonFormData)],
        },
        trialMode: false,
        quantity: selectedQuantity as number,
      };

      onPlaceOrderClick(payload, details);
    }
  };

  const totalCost = rewardCost
    ? (parseInt(rewardCost, 10) * selectedQuantity).toString()
    : rewardCost;

  const onConfirmDetailsClick = (details: AmazonRedemptionFormDetails) => {
    const unitPrice = Math.ceil(productDetails?.amount.value ?? amount.value);
    const taxes = unitPrice * selectedQuantity * 0.3;
    const total = unitPrice * selectedQuantity + taxes;
    const payload: AmazonOrderPayload = {
      shippingAddress: {
        fullName: details.firstName + ' ' + details.lastName,
        phoneNumber: details.phoneNumber,
        companyName: assemblyName ?? details.company ?? '',
        addressLine1: details.address,
        addressLine2: details.secondaryAddress ?? '',
        city: details.city,
        stateOrRegion: details.state?.id ?? '',
        postalCode: details.zip,
        country: details.country.id,
      },
      price: {
        unitPrice,
        currencyCode:
          productDetails?.amount.currencyCode ?? amount.currencyCode,
        taxes,
        total,
        shipping: 100,
        totalPointsToRedeem: parseInt(totalCost, 10),
      },
      productId: selectedVariationId ?? itemId,
      productDetails: {
        title,
        image: image ?? '',
        variations: [generateDescriptionString(amazonFormData)],
      },
      trialMode: true,
      quantity: selectedQuantity as number,
    };
    onFinalizeDetails(payload, amazonFormData);
  };

  const shippingDisclaimer = formatMessage(messages.shippingDisclaimerText);
  const shippingDisclaimerMessageParts = shippingDisclaimer
    .split('.')
    .filter(Boolean);

  const calculatedCostWithShipping =
    parseInt(totalCost, 10) + (shippingCost ?? 0);

  const shippingAndTaxDetails = getShippingAndTaxDetails(
    currencyIcon,
    confirmDetailsData
  );

  const getBannerStatus = () => {
    if (amazonRedemptionError) {
      return 'error';
    }
    if (cannotRedeemReward || isPlaceOrderDisabled) {
      return 'alert';
    }
    return 'info';
  };

  return (
    <div
      className="flex flex-1 flex-col"
      data-testid="amazon-redemption-in-progress-modal"
    >
      <div className="flex-1 px-6">
        <div
          className={twMerge(
            'flex w-full flex-col items-center gap-2 p-4',
            isDetailsLoading && 'opacity-50'
          )}
        >
          <div className="flex w-full justify-center rounded-md">
            <img
              alt="Default placeholder for a Reward card"
              className="h-[125px] w-fit"
              src={image ? image : RewardPlaceholderImage}
            />
          </div>
          <TextStyle
            variant="base-medium"
            data-testid="amazon-selected-values-info"
            className="text-center"
          >
            {title}
          </TextStyle>
          {(step === AmazonRedemptionFormStep.ShippingDetails ||
            step === AmazonRedemptionFormStep.ConfirmDetails) && (
            <TextStyle className="text-center text-gray-9">
              {generateDescriptionString(amazonFormData)}
            </TextStyle>
          )}
          <div className="flex items-center gap-1">
            <TextStyle variant="xs-regular" className="flex items-center gap-1">
              {formatMessage(messages.cost)}:
              <TextStyle
                as="span"
                className="flex items-center"
                variant="xs-bold"
              >
                {confirmDetailsData &&
                step === AmazonRedemptionFormStep.ConfirmDetails
                  ? formatMessage(messages.totalOnConfirm, {
                      currency: currencyIcon,
                      value: confirmDetailsData.totalCostInCarrots,
                    })
                  : formatMessage(messages.total, {
                      currency: currencyIcon,
                      value: calculatedCostWithShipping,
                    })}
              </TextStyle>
              {Boolean(confirmDetailsData) &&
                step === AmazonRedemptionFormStep.ConfirmDetails && (
                  <TextStyle variant="xs-regular">
                    {shippingAndTaxDetails}
                  </TextStyle>
                )}
            </TextStyle>
            {step !== AmazonRedemptionFormStep.ConfirmDetails && (
              <Tooltip
                tooltipText={formatMessage(messages.shippingCostTooltip)}
                textClassName="flex items-center gap-1"
                contentClassName="z-[50]"
              >
                <InformationCircleIcon className="h-4 w-4 cursor-pointer text-gray-8" />
              </Tooltip>
            )}
          </div>
        </div>
        <div
          className={twMerge(
            'mb-4 rounded-lg bg-gray-3 p-4',
            isDetailsLoading && 'opacity-50'
          )}
          data-testid="axomo-form-container"
          style={{
            display:
              step === AmazonRedemptionFormStep.AmazonRewardDetails || // Dont unmount the form when switching steps
              step === AmazonRedemptionFormStep.ShippingDetails
                ? 'block'
                : 'none',
          }}
        >
          <AmazonRedemptionForm
            formStep={step}
            amazonRewardDetails={availableOptions}
            onSubmitForm={handlePlaceOrderClick}
            shippableCountries={shippableCountries}
            stateList={stateList}
            onCountryChange={handleCountryChange}
            onAmazonFormUpdate={onAmazonFormUpdate}
            onShippingFormUpdate={onShippingFormUpdate}
            isStateListLoading={isStateListLoading ?? false}
            isDetailsLoading={isDetailsLoading ?? false}
            selectedCountry={selectedCountry}
            amazonFormData={amazonFormData}
            selectedVariationId={selectedVariationId}
          />
        </div>
        {step === AmazonRedemptionFormStep.AmazonRewardDetails && ( // amazon Details Content
          <>
            <HorizontalRule />
            <div
              className={twMerge(
                'max-h-[200px] overflow-y-auto pb-4',
                isDetailsLoading && 'opacity-50'
              )}
            >
              <div>
                <TextStyle variant="sm-bold" className="py-2">
                  {formatMessage(messages.description)}
                </TextStyle>
                {
                  <TextStyle variant="xs-regular">
                    {productDescription
                      ? productDescription
                      : formatMessage(messages.noDescriptionFound)}
                  </TextStyle>
                }
                {amazonProductDetails ? (
                  <div>
                    <TextStyle variant="sm-bold" className="py-2">
                      {formatMessage(messages.details)}
                    </TextStyle>
                    {amazonProductDetails.map((detail, index) => (
                      <TextStyle key={index} variant="xs-regular">
                        {detail}
                      </TextStyle>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </>
        )}
        {step === AmazonRedemptionFormStep.ConfirmDetails && ( // Confirm Details Content
          <>
            {shippingDetails ? (
              <ShipToDetails details={shippingDetails} />
            ) : null}
            <HorizontalRule className="py-4" />
            <div className="pb-4">
              <TextStyle variant="sm-bold" className="pb-2">
                {formatMessage(messages.aboutShipping)}
              </TextStyle>
              <div className="flex flex-col gap-2">
                {shippingDisclaimerMessageParts.map((part, index) => (
                  <TextStyle variant="xs-regular" key={index}>
                    {part}.
                  </TextStyle>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex-shrink-0">
        <HorizontalRule />
        <footer className="flex flex-col gap-3 px-6 py-4">
          {Boolean(showNoReturnAlert) && (
            <Banner className="rounded-xl bg-error-2" status="error">
              <TextStyle
                variant="xs-regular"
                data-testid="amazon-redemption-modal-no-returns-banner"
              >
                {formatMessage(messages.noReturnsOrCancellations)}
              </TextStyle>
            </Banner>
          )}
          {Boolean(redemptionInfoBannerText) && (
            <Banner
              className={twMerge(
                'rounded-xl',
                cannotRedeemReward && 'bg-warning-2',
                amazonRedemptionError && 'bg-error-2'
              )}
              status={getBannerStatus()}
            >
              <TextStyle
                variant="xs-regular"
                data-testid="amazon-redemption-modal-banner"
              >
                {cannotRedeemReward
                  ? formatMessage(messages.notEnoughPoints, {
                      currencies: currencies,
                    })
                  : redemptionInfoBannerText}
              </TextStyle>
            </Banner>
          )}
          <div>
            {step === AmazonRedemptionFormStep.AmazonRewardDetails && ( // amazon Details Footer
              <Button
                onClick={onContinueToShippingClick}
                disabled={isContinueToShippingDisabled}
                className="w-full"
                variation="primary"
                data-testid="continue-to-shipping-button"
              >
                {formatMessage(messages.continueToShipping)}
              </Button>
            )}
            {step === AmazonRedemptionFormStep.ShippingDetails && ( // Shipping Details Footer
              <div className="flex items-center gap-2">
                <Button
                  onClick={() => {
                    if (amazonRedemptionError || needMorePoints) {
                      resetRedemptionErrors();
                    }
                    handleStepChange(
                      AmazonRedemptionFormStep.AmazonRewardDetails
                    );
                  }}
                  variation="secondaryEmphasized"
                  className="w-full"
                  data-testid="back-to-amazon-details-button"
                >
                  {formatMessage(messages.back)}
                </Button>
                <Button
                  className="w-full"
                  variation="primary"
                  onClick={() => {
                    if (shippingDetails === null) return;
                    if (amazonRedemptionError || needMorePoints) {
                      resetRedemptionErrors();
                    }
                    const combinedData: AmazonRedemptionFormDetails = {
                      ...amazonFormData,
                      ...shippingDetails,
                    };
                    onConfirmDetailsClick(combinedData);
                  }}
                  disabled={
                    isConfirmDetailsDisabled || isFinalizeDetailsInProgress
                  }
                  data-testid="finalize-details-button"
                >
                  {formatMessage(messages.finalizeDetails)}
                  {Boolean(isFinalizeDetailsInProgress) && (
                    <LoadingSpinner className="mr-2" />
                  )}
                </Button>
              </div>
            )}
            {step === AmazonRedemptionFormStep.ConfirmDetails && ( // Confirm Details Footer
              <div className="flex items-center gap-2">
                <Button
                  onClick={() => {
                    if (amazonRedemptionError || needMorePoints) {
                      resetRedemptionErrors();
                    }
                    handleStepChange(AmazonRedemptionFormStep.ShippingDetails);
                  }}
                  variation="secondaryEmphasized"
                  className="w-full"
                  disabled={isPlaceOrderInProgress}
                  data-testid="back-to-shipping-details-button"
                >
                  {formatMessage(messages.back)}
                </Button>
                {showNoReturnAlert ? (
                  <Button
                    className="w-full"
                    variation="primary"
                    disabled={isPlaceOrderInProgress ?? isPlaceOrderDisabled}
                    data-testid="place-order-button"
                    onClick={() => {
                      setOpenConfirmModal(true);
                    }}
                  >
                    {formatMessage(messages.placeOrder)}
                    {Boolean(isPlaceOrderInProgress) && (
                      <LoadingSpinner className="mr-2" />
                    )}
                  </Button>
                ) : (
                  <Button
                    className="w-full"
                    variation="primary"
                    disabled={isPlaceOrderInProgress ?? isPlaceOrderDisabled}
                    type="submit"
                    form="amazon-form"
                    data-testid="place-order-button"
                  >
                    {formatMessage(messages.placeOrder)}
                    {Boolean(isPlaceOrderInProgress) && (
                      <LoadingSpinner className="mr-2" />
                    )}
                  </Button>
                )}
              </div>
            )}
          </div>
        </footer>
      </div>
      <Modal
        isOpen={openConfirmModal}
        onClose={handleConfirmModalClose}
        title="Confirm no returns or exchanges"
        ctaSection={
          <div className="flex justify-between gap-2">
            <Button
              type="button"
              onClick={handleConfirmModalClose}
              variation="secondaryLite"
              className="flex grow"
            >
              {formatMessage(messages.cancel)}
            </Button>
            <Button
              type="submit"
              form="amazon-form"
              variation="danger"
              className="flex grow"
            >
              {formatMessage(messages.confirmAndRedeem)}
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-4">
          {formatMessage(messages.finalReminderText)}
        </div>
      </Modal>
    </div>
  );
}
