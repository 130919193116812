import {
  type InfiniteData,
  type QueryClient,
  useMutation,
  type UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { APIEndpoints } from '../../../APIEndpoints';
import { assemblyAPI } from '../../../assemblyAPI';
import type { ApproveDenyAwardPayload } from '../../../types/award';
import type { PendingAwardApprovalResponse } from '../../../types/awards';
import { trackAdminAwardsEvent } from '../../../utils/adminEvents';

const updateNotificationTable = ({
  queryClient,
  payload,
}: {
  queryClient: QueryClient;
  payload: ApproveDenyAwardPayload;
}) => {
  const queryKey = ['pendingAwardApproval'];
  const pendingApprovalResponse:
    | InfiniteData<PendingAwardApprovalResponse>
    | undefined = queryClient.getQueryData(queryKey);
  if (pendingApprovalResponse) {
    const mutatedPendingApprovalResponse: InfiniteData<PendingAwardApprovalResponse> =
      {
        ...pendingApprovalResponse,
        pages: pendingApprovalResponse.pages.map((page) => {
          return {
            ...page,
            total: page.total - 1,
            data: page.data.filter(
              (pendingApproval) =>
                'awardApprovalId' in pendingApproval &&
                pendingApproval.awardApprovalId !== payload.awardApprovalId
            ),
          };
        }),
      };
    queryClient.setQueryData(queryKey, mutatedPendingApprovalResponse);
  }
};

export function useApproveDenyAwardMutation(
  options?: UseMutationOptions<unknown, unknown, ApproveDenyAwardPayload>
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      awardApprovalId,
      action,
    }: ApproveDenyAwardPayload) => {
      const response = await assemblyAPI.post(
        APIEndpoints.approveDenyAward({
          awardApprovalId,
          action,
        })
      );
      return response.data;
    },
    onMutate: async (payload: ApproveDenyAwardPayload) => {
      updateNotificationTable({ queryClient, payload });
    },
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
    },
    onError: (...args) => {
      const [error] = args;
      const errorInfo = error instanceof Error ? error : undefined;

      options?.onError?.(...args);
      trackAdminAwardsEvent({
        errorType: errorInfo?.message,
      });
    },
    onSettled: (...args) => {
      options?.onSettled?.(...args);
      queryClient.invalidateQueries({ queryKey: ['pendingAwardApproval'] });
    },
  });
}
