import type {
  Quantity,
  RewardColor,
  RewardDetailsForm,
  RewardDetailsOption,
  RewardSize,
  SwagRewardProvider,
} from '@assembly-web/services';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { z } from 'zod';

import { RewardDropdown } from './RewardDropdown';

const messages = defineMessages({
  placeholder: {
    defaultMessage: `Choose {placeholderName}`,
    id: 'poSUD7',
  },
  invalidSize: {
    defaultMessage: 'Size is invalid',
    id: 'bYBTX9',
  },
  invalidQuantity: {
    defaultMessage: 'Quantity is invalid',
    id: 'e3stbV',
  },
  invalidColor: {
    defaultMessage: 'Color is invalid',
    id: 'P1wfyD',
  },
  invalidVariant: {
    defaultMessage: 'Product is invalid',
    id: 'EfxOPv',
  },
});

const selectableOptionSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1),
  value: z.union([z.string().min(1), z.number()]).optional(),
});

export const RewardDetailsFormSchema = z.object({
  size: selectableOptionSchema,
  color: selectableOptionSchema,
  quantity: selectableOptionSchema,
});

export const RewardDetailsCovverFormSchema = z.object({
  product: selectableOptionSchema,
});

export const RewardFormDetails = ({
  rewardDetails,
  onUpdate,
  formErrors,
  formData,
  rewardProvider,
}: {
  rewardDetails: RewardDetailsOption[];
  onUpdate: (data: RewardDetailsForm, errors: Record<string, unknown>) => void;
  formErrors: Record<string, unknown>;
  formData: RewardDetailsForm;
  rewardProvider?: SwagRewardProvider;
}) => {
  const { formatMessage } = useIntl();
  const validateFormData = useCallback(
    (data: typeof formData) => {
      try {
        const schema =
          rewardProvider === 'COVVER'
            ? RewardDetailsCovverFormSchema
            : RewardDetailsFormSchema;

        schema.parse(data);
        return {};
      } catch (error) {
        if (error instanceof z.ZodError) {
          const fieldErrors = error.flatten().fieldErrors;
          if (fieldErrors.size) {
            fieldErrors.size = [formatMessage(messages.invalidSize)];
          }
          if (fieldErrors.quantity) {
            fieldErrors.quantity = [formatMessage(messages.invalidQuantity)];
          }
          if (fieldErrors.color) {
            fieldErrors.color = [formatMessage(messages.invalidColor)];
          }
          if (fieldErrors.variant) {
            fieldErrors.variant = [formatMessage(messages.invalidVariant)];
          }
          return fieldErrors;
        }
        return {};
      }
    },
    [formatMessage, rewardProvider]
  );

  const handleDropdownChange = useCallback(
    (name: string, selectedOption: RewardSize | RewardColor | Quantity) => {
      const newData = { ...formData, [name]: selectedOption };
      const errors = validateFormData(newData);
      onUpdate(newData, errors);
    },
    [onUpdate, validateFormData, formData]
  );

  return (
    <div className="flex flex-col gap-2" data-testid="swag-details-form">
      {rewardDetails.map(({ name, options }, index) => (
        <RewardDropdown
          key={name}
          options={options}
          onChange={(option) => handleDropdownChange(name, option)}
          name={name}
          index={index}
          formError={formErrors[name] as string}
          selectedOption={formData[name]}
        />
      ))}
    </div>
  );
};
