import { SVGReactComponents } from '@assembly-web/assets';
import { CakeIcon } from '@heroicons/react/24/outline';
import { cva, cx } from 'class-variance-authority';
import { forwardRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { TextStyle } from '../../DesignSystem/Feedback/TextStyle';

export type PostTypeTagProps = {
  postType?: 'award' | 'recognition' | 'anniversary' | 'birthday' | 'welcome';
};

const styles = cva(
  'rounded-3xl inline-flex items-center gap-2 px-3 py-1 text-gray-9 [&>svg]:size-5',
  {
    variants: {
      postType: {
        award: 'bg-[linear-gradient(240deg,#FFE58F_29.84%,#FFC069_124.56%)]',
        recognition: 'bg-[linear-gradient(44deg,#BAE7FF_0%,#ADC6FF_100%)]',
        anniversary:
          'bg-[linear-gradient(240deg,#87E8DE_25.42%,#BAE7FF_92.1%)]',
        birthday: 'bg-[linear-gradient(242deg,#FFCCC7_9.81%,#FFADD2_84.23%)]',
        welcome: 'bg-[linear-gradient(240deg,#B7EB8F_9.84%,#B5F5EC_107.68%)]',
      },
    },
  }
);

function getTagIcon(postType: PostTypeTagProps['postType']) {
  switch (postType) {
    case 'award':
      return (
        <SVGReactComponents.AwardMedalIcon
          className="!fill-current stroke-current"
          fill="currentColor"
        />
      );
    case 'recognition':
      return <SVGReactComponents.RecognitionIcon className="stroke-current" />;
    case 'anniversary':
      return (
        <SVGReactComponents.AnniversaryIcon className="stroke-current [&>g>#star\_5]:fill-[#b4e7fb]" />
      );
    case 'birthday':
      return <CakeIcon className="stroke-current" />;
    case 'welcome':
      return <SVGReactComponents.WavingHandIcon />;
  }
}

const messages = defineMessages({
  recognition: {
    defaultMessage: 'recognition',
    id: 'eFH7Nt',
  },
  award: {
    defaultMessage: 'award',
    id: 'qfGkcM',
  },
  birthday: {
    defaultMessage: 'birthday',
    id: 'J4c1l3',
  },
  anniversary: {
    defaultMessage: 'anniversary',
    id: 'HpZYca',
  },
  welcome: {
    defaultMessage: 'welcome',
    id: 'co20iY',
  },
});

export const PostTypeTag = forwardRef<HTMLDivElement, PostTypeTagProps>(
  function PostTypeTag({ postType }, ref) {
    const { formatMessage } = useIntl();

    if (!postType) {
      return null;
    }

    return (
      <div ref={ref} className={cx(styles({ postType }))}>
        {getTagIcon(postType)}
        <TextStyle variant="xs-medium" className="uppercase" as="span">
          {formatMessage(messages[postType])}
        </TextStyle>
      </div>
    );
  }
);
