import type { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

export function EditorContainer({
  children,
  hasError,
  isFocused,
}: PropsWithChildren & { hasError?: boolean; isFocused?: boolean }) {
  return (
    <div
      className={twMerge(
        'relative flex h-full min-h-48 flex-col gap-6 overflow-hidden rounded-2xl border border-gray-5 p-4 md:min-h-44',
        isFocused && 'ring-1 ring-primary-3',
        hasError && 'ring-1 ring-error-6'
      )}
    >
      {children}
    </div>
  );
}
