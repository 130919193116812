import data from '@emoji-mart/data';
import { useMemo } from 'react';

import type { ReactionEmoji } from '../Reactions/ReactionEmoji';

export function Emoji({ reaction }: { reaction: ReactionEmoji }) {
  const emoji = useMemo(() => {
    const emojiCatalog = data as data.EmojiMartData;
    const [id, skinTone] = reaction.name.split('::');
    const skinToneIndex = skinTone
      ? Number(skinTone.replace(/skin-tone-|:/g, '')) - 1
      : 0;
    const key = id.toLowerCase().replace(/:/g, '');
    if (!key) {
      return null;
    }
    const emojiData = emojiCatalog.emojis[key];
    const selectedEmoji = emojiData.skins[skinToneIndex];
    return (
      <span
        aria-label={`${selectedEmoji.native}, ${key}`}
        className="inline-block h-4 w-4"
      >
        {selectedEmoji.native}
      </span>
    );
  }, [reaction]);

  return emoji;
}
