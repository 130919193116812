import { FileType } from '@assembly-web/services';

import reportInsightsLoading from './lib/gifs/report_insights_loading.gif';
import v3Notifications from './lib/gifs/v3Notifications.gif';
import v3Search from './lib/gifs/v3Search.gif';
import v3Streamline from './lib/gifs/v3Streamline.gif';

import activitiesIllustration from './lib/images/activities.svg';
import highFiveIcon from './lib/icons/highFive.svg';
import betaIcon from './lib/icons/beta.svg';
import closeIcon from './lib/icons/close.svg';
import closeIconCircle from './lib/icons/closeCircle.svg';
import emptySearch from './lib/images/emptySearch.svg';
import filterIcon from './lib/icons/filter.svg';
import filterSelectedIcon from './lib/icons/filterSelected.svg';
import filterTemplateIcon from './lib/icons/filterTemplate.svg';
import leftArrowIcon from './lib/icons/left-arrow.svg';
import triangleDownIcon from './lib/icons/triangleDown.svg';
import minusIcon from './lib/icons/minus.svg';
import plusIcon from './lib/icons/plus.svg';
import postIcon from './lib/icons/post.svg';
import puzzlePieceIcon from './lib/icons/puzzlePiece.svg';
import routineIcon from './lib/icons/routine.svg';
import searchIcon from './lib/icons/search.svg';
import searchDoc from './lib/icons/searchDoc.svg';
import sidebarLockIcon from './lib/icons/sidebarLock.svg';
import supportIcon from './lib/icons/support.svg';
import templatesCube from './lib/icons/templatesCube.svg';
import templatesIcon from './lib/icons/templatesIcon.svg';
import trophyIcon from './lib/icons/trophy.svg';
import * as fabIcons from './lib/fabIcons';

import { ReactComponent as DragMove } from './lib/icons/drag-move.svg';
import { ReactComponent as CollapseDrawerIcon } from './lib/icons/collapseDrawer.svg';
import { ReactComponent as OpenDrawerIcon } from './lib/icons/openDrawer.svg';
import { ReactComponent as ExpandIcon } from './lib/icons/expand.svg';
import { ReactComponent as PostIcon } from './lib/icons/post.react.svg';
import { ReactComponent as SparklesColoredIcon } from './lib/icons/sparklesColored.svg';
import { ReactComponent as SparklesAnimatedIcon } from './lib/images/sparklesAnimated.svg';
import { ReactComponent as UnableToPreview } from './lib/images/searching-girl.svg';
import { ReactComponent as GoToPostIcon } from './lib/icons/go-to-post.svg';
import { ReactComponent as HistoryIcon } from './lib/icons/history.svg';
import { ReactComponent as AppConnectionsIcon } from './lib/icons/appConnections.svg';
import RecognitionIcon from './lib/icons/RecognitionIcon';
import { ReactComponent as SendIcon } from './lib/icons/send.svg';
import { ReactComponent as SearchIcon } from './lib/icons/search.svg';
import { ReactComponent as UpgradeDollar } from './lib/icons/upgradeDollar.svg';
import { ReactComponent as RecognitionGrayscale } from './lib/icons/recognition-grayscale.svg';
import { ReactComponent as ActionItem } from './lib/icons/actionItem.svg';
import { ReactComponent as TVIcon } from './lib/icons/tv.svg';
import { ReactComponent as TicketIcon } from './lib/icons/ticket-icon.svg';
import { ReactComponent as AwardMedalIcon } from './lib/icons/awardMedal';
import { ReactComponent as ActionBarAwardIcon } from './lib/icons/actionBarAwardMedal.svg';
import { ReactComponent as ActionBarRecognitionIcon } from './lib/icons/actionBarRecognition.svg';
import { ReactComponent as CheckDisabledIcon } from './lib/icons/checkDisabled.svg';
import { ReactComponent as CheckSuccessIcon } from './lib/icons/checkSuccess.svg';
import { ReactComponent as AnniversaryIcon } from './lib/icons/anniversary.svg';
import { ReactComponent as WavingHandIcon } from './lib/icons/wavingHand.svg';
import { ReactComponent as AwardBadge } from './lib/icons/award.svg';

import announcementsHowToImage2X from './lib/images/announcements_how_to_2x.png';
import defaultAwardImage from './lib/images/default-award.png';
import appConnectionsIllustration from './lib/images/appConnectionsIllustration.svg';
import assemblyMobileAppQRCode from './lib/images/assemblyMobileAppQRCode.svg';
import assemblyMonogramImage from './lib/images/assemblyMonogram.svg';
import browseTemplateGallery from './lib/images/browseTemplateGallery.svg';
import disassembledImage from './lib/images/disassembled.svg';
import internationalDiscountsImage from './lib/images/discounts-international.svg';
import usDiscountsImage from './lib/images/discounts-us.svg';
import errorImage from './lib/images/error.svg';
import errorBrowserCatImage from './lib/images/errorBrowserCat.svg';
import errorCatImage from './lib/images/errorCat.svg';
import extensionModalImage from './lib/images/extensionModal.svg';
import loadingLogoImage from './lib/images/loadingLogo.svg';
import msTeamsConfigurePopupLogo from './lib/images/msTeamsConfigurePopupLogo.svg';
import notFoundImage from './lib/images/notFound.svg';
import pageUnderMaintenance from './lib/images/pageUnderMaintenance.svg';
import pinnedCollection from './lib/images/pinnedCollection.svg';
import searchingImage from './lib/images/searching.svg';
import successImage from './lib/images/success.svg';
import templatesBgGradient from './lib/images/templatesBgGradient.svg';
import templatesGalleryBgImage from './lib/images/templatesGalleryBg.svg';
import templatesGalleryBgMobImage from './lib/images/templatesGalleryBgMob.svg';
import workspacesIllustrationImage from './lib/images/workspacesIllustration.svg';
import assemblyExperiencesImage from './lib/images/assembly-experiences-switchfly.svg';
import experiencesHotel from './lib/images/experiences-hotel.svg';
import experiencesFlight from './lib/images/experiences-flight.svg';
import experiencesActivity from './lib/images/experiences-activity.svg';
import botImage from './lib/images/bot.png';

import adpLogo from './lib/logos/adp.svg';
import assemblyLogo from './lib/logos/assembly.svg';
import gmailLogo from './lib/logos/gmail.svg';
import googleDocsLogo from './lib/logos/googleDocs.svg';
import googleFormsLogo from './lib/logos/googleForms.svg';
import googleJamboardLogo from './lib/logos/googleJamboard.svg';
import googleSheetsLogo from './lib/logos/googleSheets.svg';
import googleSlidesLogo from './lib/logos/googleSlides.svg';
import gSuiteLogo from './lib/logos/gSuite.svg';
import lifionLogo from './lib/logos/adp.svg';
import office365Logo from './lib/logos/office365.svg';
import outlookLogo from './lib/logos/outlook.svg';
import samlLogo from './lib/logos/saml.svg';
import slackLogo from './lib/logos/slack.svg';
import yahooLogo from './lib/logos/yahoo.svg';

import audio from './lib/fileIcons/audio.svg';
import document from './lib/fileIcons/document.svg';
import genericFile from './lib/fileIcons/genericFile.svg';
import image from './lib/fileIcons/image.svg';
import pdf from './lib/fileIcons/pdf.svg';
import presentation from './lib/fileIcons/presentation.svg';
import spreadsheet from './lib/fileIcons/spreadsheet.svg';
import video from './lib/fileIcons/video.svg';
import zip from './lib/fileIcons/zip.svg';
import awardIcon from './lib/icons/award.svg';
import warningCircleIcon from './lib/icons/warning-circle.svg';
import covverLogo from './lib/images/covver-logo.svg';
import axomoLogo from './lib/images/axomo-logo.png';
import swagShirtIcon from './lib/icons/swag-shirt.svg';

export const fileIcons: Record<FileType, string> = {
  [FileType.Document]: document,
  [FileType.GenericFile]: genericFile,
  [FileType.Image]: image,
  [FileType.Pdf]: pdf,
  [FileType.Presentation]: presentation,
  [FileType.Spreadsheet]: spreadsheet,
  [FileType.Video]: video,
  [FileType.Zip]: zip,
  [FileType.Audio]: audio,
};

export const SVGReactComponents = {
  ActionItem,
  AnniversaryIcon,
  HistoryIcon,
  RecognitionIcon,
  SendIcon,
  PostIcon,
  CollapseDrawerIcon,
  DragMove,
  OpenDrawerIcon,
  SparklesAnimatedIcon,
  SparklesColoredIcon,
  ExpandIcon,
  UnableToPreview,
  GoToPostIcon,
  AppConnectionsIcon,
  SearchIcon,
  UpgradeDollar,
  RecognitionGrayscale,
  TVIcon,
  TicketIcon,
  ActionBarAwardIcon,
  ActionBarRecognitionIcon,
  AwardMedalIcon,
  CheckDisabledIcon,
  CheckSuccessIcon,
  WavingHandIcon,
  AwardBadge,
};

export {
  fabIcons,
  activitiesIllustration,
  announcementsHowToImage2X,
  adpLogo,
  appConnectionsIllustration,
  assemblyLogo,
  assemblyMobileAppQRCode,
  assemblyMonogramImage,
  betaIcon,
  highFiveIcon,
  browseTemplateGallery,
  closeIcon,
  closeIconCircle,
  disassembledImage,
  emptySearch,
  errorBrowserCatImage,
  errorCatImage,
  errorImage,
  extensionModalImage,
  filterIcon,
  filterSelectedIcon,
  filterTemplateIcon,
  gmailLogo,
  googleDocsLogo,
  googleFormsLogo,
  googleJamboardLogo,
  googleSheetsLogo,
  googleSlidesLogo,
  gSuiteLogo,
  internationalDiscountsImage,
  leftArrowIcon,
  triangleDownIcon,
  lifionLogo,
  loadingLogoImage,
  minusIcon,
  msTeamsConfigurePopupLogo,
  notFoundImage,
  office365Logo,
  outlookLogo,
  pageUnderMaintenance,
  pinnedCollection,
  plusIcon,
  postIcon,
  puzzlePieceIcon,
  reportInsightsLoading,
  routineIcon,
  samlLogo,
  searchDoc,
  searchIcon,
  searchingImage,
  sidebarLockIcon,
  slackLogo,
  successImage,
  supportIcon,
  templatesCube,
  templatesBgGradient,
  templatesGalleryBgImage,
  templatesGalleryBgMobImage,
  templatesIcon,
  trophyIcon,
  usDiscountsImage,
  v3Notifications,
  assemblyExperiencesImage,
  v3Search,
  v3Streamline,
  workspacesIllustrationImage,
  yahooLogo,
  experiencesHotel,
  experiencesActivity,
  experiencesFlight,
  awardIcon,
  defaultAwardImage,
  warningCircleIcon,
  botImage,
  covverLogo,
  axomoLogo,
  swagShirtIcon,
};
