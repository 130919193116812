import { useClickAway } from '@assembly-web/services';
import {
  ChevronUpIcon,
  EnvelopeIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import type { CSSProperties, ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { defineMessages, type MessageDescriptor, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Chip } from '../../../DesignSystem/Inputs/Chip';
import { TextField } from '../../../DesignSystem/Inputs/TextField';
import { useMeasure } from '../../hooks/useMeasure';
import { VariableSizeVirtualizedList } from '../../Shared/VirtualizedList';
import { CriteriaItemLoader } from './CriteriaItem';

export type Rule<T> = {
  id: string;
  data: T;
};

export type CustomRule = {
  id: string;
  label: string;
};

export type SearchableRulesDropdownProps<T> = {
  rules: Rule<T>[];
  renderRule: (rule: Rule<T>) => ReactNode;
  renderRulesLoader: () => ReactNode;
  customRules: CustomRule[];
  onRuleSelect: (rule: Rule<T>) => void;
  onCustomRuleSelect: (rule: CustomRule) => void;
  hasInvitePermission?: boolean;
  checkIfCurrentEmailCanBeInvited: (email: string) => Promise<boolean>;
  onMemberSearch: (searchTerm: string) => Promise<void> | void;
  isLoading?: boolean;
  toggleDropdownLabel?: string;
  hasMoreMembersToFetch: boolean;
  fetchMoreMembers: () => void;
  searchTerm: string;
  triggerClassName?: string;
  contentClassName?: string;
  isFetchingMoreMembers?: boolean;
  handleOnEmailInvite: (email: string) => void;
  hasUserInvitePermission: boolean;
  addEmailLabel?: MessageDescriptor;
  disabled?: boolean;
  tooltipText?: string;
  placeholder?: MessageDescriptor;
};

const messages = defineMessages({
  search: {
    defaultMessage: 'Search',
    id: 'xmcVZ0',
  },
  addRule: {
    defaultMessage: 'Add a rule:',
    id: '/R4vHa',
  },
  addPeopleOrRules: {
    defaultMessage: 'Add people or rules...',
    id: 'aP909H',
  },
  inviteBannerText: {
    defaultMessage:
      'Don’t see who you’re looking for? Type an email to invite a new teammate.',
    id: 'aKlxke',
  },
  addEmail: {
    defaultMessage: 'Add “{email}” to this collection',
    id: 'xqYAfL',
  },
  noResults: {
    defaultMessage: 'No results for this search.',
    id: 'DeCDPs',
  },
});

export function SearchableRulesDropdown<T>(
  props: SearchableRulesDropdownProps<T>
) {
  const {
    rules,
    isLoading = false,
    customRules,
    hasInvitePermission,
    toggleDropdownLabel,
    hasMoreMembersToFetch,
    searchTerm,
    renderRule,
    onRuleSelect,
    onMemberSearch,
    fetchMoreMembers,
    renderRulesLoader,
    onCustomRuleSelect,
    checkIfCurrentEmailCanBeInvited,
    triggerClassName,
    contentClassName,
    isFetchingMoreMembers,
    handleOnEmailInvite,
    hasUserInvitePermission,
    addEmailLabel,
    disabled,
    tooltipText,
    placeholder,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [canBeInvited, setCanBeInvited] = useState<boolean>(false);
  const [ref, dimension] = useMeasure();

  const containerRef = useClickAway<HTMLDivElement>(() => {
    setIsOpen(false);
  });

  const checkInvitation = useCallback(
    async (email: string) => {
      const result =
        (await checkIfCurrentEmailCanBeInvited(email)) && hasInvitePermission;
      setCanBeInvited(result ?? false);
    },
    [checkIfCurrentEmailCanBeInvited, hasInvitePermission]
  );

  const handleDropdownOpen = () => {
    document.getElementById('searchableDropdown')?.focus();
    setIsOpen(true);
  };

  const handleDropdownClose = () => {
    setIsOpen(false);
    document.getElementById('searchableDropdown')?.blur();
    onMemberSearch('');
  };

  const handleSelectionChange = (selectedRule: Rule<T>) => {
    const currentRule = rules.find((rule) => rule.id === selectedRule.id);
    if (currentRule) {
      onRuleSelect(currentRule);
    }
    setTimeout(() => {
      handleDropdownClose();
    }, 0);
  };

  const toggleDropdown = () => {
    if (isOpen) {
      handleDropdownClose();
    } else {
      handleDropdownOpen();
    }
  };

  const handleOnToggleBlur = () => {
    setTimeout(() => {
      if (
        !document.activeElement?.id.includes('categoryChip') &&
        !document.activeElement?.id.includes('dropdownOption') &&
        !document.activeElement?.id.includes('searchableDropdown')
      ) {
        handleDropdownClose();
      }
    }, 0);
  };

  const handleOnInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      handleDropdownClose();
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      handleDropdownOpen();
    }
  };

  const handleOnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isOpen) {
      handleDropdownOpen();
    }
    const searchTerm = (e.target as HTMLInputElement).value;
    checkInvitation(searchTerm);
    onMemberSearch(searchTerm);
  };

  const handleCustomRuleSelection = (rule: CustomRule) => {
    setIsOpen(false);
    onCustomRuleSelect(rule);
  };

  return (
    <div
      className="relative z-10 w-full"
      style={
        {
          '--trigger-height': `${dimension.height}px`,
          '--trigger-width': `${dimension.width}px`,
        } as CSSProperties
      }
      ref={containerRef}
    >
      <div
        ref={ref}
        className={twMerge(
          'flex w-full flex-col items-start justify-start rounded-lg border border-gray-5 bg-gray-1 py-1',
          isOpen && 'border-primary-6 ring-1 ring-primary-6',
          !isOpen && 'hover:border-primary-6',
          triggerClassName
        )}
      >
        <SearchInput
          disabled={disabled}
          tooltipText={tooltipText}
          id="searchableDropdown"
          value={searchTerm}
          handleSearchInputChange={handleOnInputChange}
          handleSearchInputKeyDown={handleOnInputKeyDown}
          handleSearchInputClick={handleDropdownOpen}
          placeholder={placeholder}
        />
        <ToggleButton
          disabled={disabled}
          tooltipText={tooltipText}
          isOpen={isOpen}
          toggleDropdown={toggleDropdown}
          onBlur={handleOnToggleBlur}
          toggleDropdownLabel={toggleDropdownLabel}
        />
      </div>
      {Boolean(isOpen) && (
        <div
          className={twMerge(
            'absolute top-[calc(var(--trigger-height)+10px)] w-[var(--trigger-width)] rounded-lg bg-gray-1 shadow-md-down ring-1 ring-gray-10 ring-opacity-5',
            contentClassName
          )}
          data-testid="searchableDropdownPopover"
        >
          {isLoading ? (
            renderRulesLoader()
          ) : (
            <>
              {canBeInvited && rules.length === 0 ? (
                <button
                  onClick={() => {
                    handleOnEmailInvite(searchTerm);
                    setTimeout(() => {
                      handleDropdownClose();
                    }, 0);
                  }}
                  className="flex w-full cursor-pointer items-start rounded-lg bg-gray-1 hover:bg-gray-3 focus:bg-gray-3 focus:outline-none"
                  id={`dropdownOption-${searchTerm}`}
                >
                  <AddEmailBanner
                    email={searchTerm}
                    addEmailLabel={addEmailLabel}
                  />
                </button>
              ) : (
                <>
                  <RulesList
                    rules={rules}
                    customRules={customRules}
                    hasInvitePermission={hasUserInvitePermission}
                    handleRuleSelection={handleSelectionChange}
                    handleCustomRuleSelection={handleCustomRuleSelection}
                    renderRule={renderRule}
                    hasMoreMembersToFetch={hasMoreMembersToFetch}
                    fetchMoreMembers={fetchMoreMembers}
                    isFetchingMoreMembers={isFetchingMoreMembers}
                  />
                  {Boolean(hasUserInvitePermission) && rules.length > 0 && (
                    <InviteBanner />
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

function SearchInput({
  value,
  id,
  handleSearchInputChange,
  handleSearchInputKeyDown,
  handleSearchInputBlur,
  handleSearchInputClick,
  disabled,
  tooltipText,
  placeholder = messages.addPeopleOrRules,
}: {
  id: string;
  value: string;
  handleSearchInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchInputKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSearchInputBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleSearchInputClick: () => void;
  disabled?: boolean;
  tooltipText?: string;
  placeholder?: MessageDescriptor;
}) {
  const { formatMessage } = useIntl();
  return (
    <div className="min-w-[100%]">
      <TextField
        disabled={disabled}
        tooltipText={tooltipText}
        shouldUseReactAriaInput
        autoComplete="off"
        hideLabel
        inputClassName="text-ellipsis border-none text-sm py-0 px-3 h-[30px] focus:outline-none focus:ring-0"
        inputSize="md"
        invalidText=""
        isInvalid={false}
        label={formatMessage(messages.search)}
        onChange={handleSearchInputChange}
        onBlur={handleSearchInputBlur}
        onClick={handleSearchInputClick}
        onKeyDown={(e) => handleSearchInputKeyDown(e)}
        value={value}
        placeholder={formatMessage(placeholder)}
        id={id}
      />
    </div>
  );
}

function ToggleButton({
  isOpen,
  toggleDropdown,
  toggleDropdownLabel,
  onBlur,
  disabled,
  tooltipText,
}: {
  isOpen: boolean;
  toggleDropdown: () => void;
  onBlur: () => void;
  toggleDropdownLabel?: string;
  disabled?: boolean;
  tooltipText?: string;
}) {
  const { formatMessage } = useIntl();
  return (
    <Tooltip tooltipText={tooltipText}>
      <button
        aria-label={toggleDropdownLabel}
        onClick={toggleDropdown}
        className="items-top absolute right-3 top-0 flex h-full items-center"
        id="toggleDropdown"
        onBlur={onBlur}
        tabIndex={0}
        disabled={disabled}
      >
        <div className="sr-only">
          {formatMessage(messages.addPeopleOrRules)}
        </div>
        <ChevronUpIcon
          className={twMerge(
            'h-4 w-4 stroke-[2px] text-gray-8',
            !isOpen && '-rotate-180'
          )}
        />
      </button>
    </Tooltip>
  );
}

function AddEmailBanner({
  email,
  addEmailLabel,
}: {
  email: string;
  addEmailLabel?: MessageDescriptor;
}) {
  const { formatMessage } = useIntl();
  return (
    <div className="inline-flex min-h-10 w-full items-start justify-start gap-2 rounded-lg px-5 py-3">
      <EnvelopeIcon className="relative h-4 w-4" />
      <TextStyle
        variant="sm-regular"
        className="shrink grow basis-0 rounded text-start leading-snug text-gray-8"
      >
        {formatMessage(addEmailLabel ?? messages.addEmail, { email })}
      </TextStyle>
    </div>
  );
}

function RulesList<T>({
  rules,
  customRules,
  hasInvitePermission,
  handleRuleSelection,
  handleCustomRuleSelection,
  renderRule,
  handleDropdownBlur,
  hasMoreMembersToFetch,
  fetchMoreMembers,
  isFetchingMoreMembers,
}: {
  rules: Rule<T>[];
  customRules: CustomRule[];
  hasInvitePermission?: boolean;
  handleRuleSelection: (rule: Rule<T>) => void;
  handleCustomRuleSelection: (rule: CustomRule) => void;
  renderRule: (rule: Rule<T>) => ReactNode;
  handleDropdownBlur?: () => void;
  hasMoreMembersToFetch: boolean;
  fetchMoreMembers: () => void;
  isFetchingMoreMembers?: boolean;
}) {
  const { formatMessage } = useIntl();

  const itemCount =
    hasMoreMembersToFetch && rules.length ? rules.length + 1 : rules.length;

  const renderItem = useCallback(
    (rule: Rule<T>, index?: number) => {
      if (index === 0) {
        return (
          <>
            {customRules.length > 0 && (
              <div className="flex h-[78px] max-w-full flex-col items-start justify-start gap-1 overflow-hidden border-b border-gray-5 bg-gray-1 pt-2">
                <div className="px-4 text-sm font-medium leading-snug text-gray-9">
                  {formatMessage(messages.addRule)}
                </div>
                <div className="flex w-full items-start justify-start gap-2 overflow-x-scroll pb-3">
                  {customRules.map((rule, index) => (
                    <Chip.Root
                      key={rule.id}
                      id={`categoryChip-${rule.id}`}
                      data-testid={`categoryChip-${rule.id}`}
                      onClick={() => handleCustomRuleSelection(rule)}
                      intent="filter"
                      className={twMerge(
                        index === 0 && 'ml-4',
                        index === customRules.length - 1 && 'mr-4'
                      )}
                    >
                      <Chip.Text>{rule.label}</Chip.Text>
                    </Chip.Root>
                  ))}
                </div>
              </div>
            )}
            <button
              key={rule.id}
              className="flex w-full cursor-pointer items-start bg-gray-1 px-4 py-2 hover:bg-gray-3 focus:bg-gray-3 focus:outline-none"
              id={`dropdownOption-${rule.id}`}
              onClick={() => handleRuleSelection(rule)}
            >
              {renderRule(rule)}
            </button>
          </>
        );
      }
      if ((index && index >= rules.length) || !rule.id) {
        return null;
      }

      return (
        <>
          <button
            key={rule.id}
            className="flex w-full cursor-pointer items-start bg-gray-1 px-4 py-2 hover:bg-gray-3 focus:bg-gray-3 focus:outline-none"
            id={`dropdownOption-${rule.id}`}
            onClick={() => handleRuleSelection(rule)}
          >
            {renderRule(rule)}
          </button>
          {Boolean(isFetchingMoreMembers) && index === rules.length - 1 && (
            <div className="px-4">
              <CriteriaItemLoader showDropdownLoader={false} />
            </div>
          )}
        </>
      );
    },
    [
      customRules,
      formatMessage,
      handleCustomRuleSelection,
      handleRuleSelection,
      isFetchingMoreMembers,
      renderRule,
      rules.length,
    ]
  );

  if (rules.length === 0) {
    return (
      <div className="py-1">
        <TextStyle
          variant="sm-regular"
          className="shrink grow basis-0 rounded px-5 py-2 leading-snug text-gray-8"
        >
          {formatMessage(messages.noResults)}
        </TextStyle>
      </div>
    );
  }

  return (
    <div
      className={twMerge(
        'relative rounded-lg py-1',
        hasInvitePermission && 'pb-[40px]'
      )}
      role="listbox"
      onBlur={handleDropdownBlur}
    >
      <section
        style={{
          height:
            (rules.length > 3 ? 76 * 3 : rules.length * 76) +
            (customRules.length > 0 ? 78 : 0),
        }}
      >
        <VariableSizeVirtualizedList
          itemSize={(index) =>
            index === 0 && customRules.length > 0 ? 154 : 76
          }
          estimatedItemSize={68}
          itemCount={itemCount}
          items={rules}
          onLoadMore={fetchMoreMembers}
          renderItem={renderItem}
        />
      </section>
    </div>
  );
}

function InviteBanner() {
  const { formatMessage } = useIntl();
  return (
    <div className="absolute bottom-0 z-10 inline-flex min-h-[42px] w-full items-center justify-start gap-2 rounded-b-lg border-t border-gray-4 bg-gray-1 px-5 pb-2 pt-3">
      <UserPlusIcon className="relative h-4 w-4" />
      <TextStyle
        variant="sm-regular"
        className="shrink grow basis-0 font-normal leading-snug text-gray-8"
      >
        {formatMessage(messages.inviteBannerText)}
      </TextStyle>
    </div>
  );
}
