import type { FolderColor } from '@assembly-web/services';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  Content,
  Root as CollapsibleRoot,
  Trigger,
} from '@radix-ui/react-collapsible';
import type {
  FocusEventHandler,
  MouseEventHandler,
  ReactNode,
  RefObject,
} from 'react';
import { isValidElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import {
  Toolbar,
  type ToolbarItem,
} from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { useToolbarState } from '../../../DesignSystem/Feedback/Toolbar/useToolbarState';
import { getFolderStyles } from '../../Shared/EmojiColorPicker/utils';

type URLTarget = { url: string };
type OnClickTarget = { onClick: () => void };

type ActivityCardProps = {
  children?: ReactNode;
  icon: ReactNode;
  text: string | TrustedHTML | ReactNode;
  subText?: string | TrustedHTML;
  connectedRight?: ReactNode;
  primaryToolbarItems?: ToolbarItem[];
  secondaryToolbarItems?: ToolbarItem[];
  onToolbarItemClick?: (args: ToolbarItem) => void;
  preview?: ReactNode;
  wrapperRef?: RefObject<HTMLAnchorElement>;
  detailsRef?: RefObject<HTMLDivElement>;
  onMenuToggle?: (isOpen: boolean) => void;
  collapsibleContent?: ReactNode;
  colorName?: FolderColor;
  target?: string;
  wrapperClassName?: string;
} & Partial<OnClickTarget> &
  Partial<URLTarget>;

type WrapperProps = {
  isOpen: boolean;
  children: ReactNode;
  className?: string;
  onMouseOver: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
  preview?: ReactNode;
  target: string;
  wrapperRef?: RefObject<HTMLAnchorElement> | RefObject<HTMLButtonElement>;
  collapsibleContent?: ReactNode;
  connectedRight: ReactNode;
  wrapperClassName?: string;
  onFocus: FocusEventHandler;
  onBlur: FocusEventHandler;
} & Partial<OnClickTarget> &
  Partial<URLTarget>;

const Wrapper = ({
  children,
  className,
  collapsibleContent,
  isOpen,
  url,
  onClick,
  onMouseOver,
  onMouseLeave,
  preview,
  wrapperRef,
  target,
  connectedRight,
  wrapperClassName,
  onBlur,
  onFocus,
}: WrapperProps) => {
  return (
    <section
      role="presentation"
      className={twMerge(
        'group relative rounded-2xl bg-gray-1 shadow-feed-post @container',
        wrapperClassName
      )}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {collapsibleContent ? (
        <CollapsibleRoot open={isOpen}>
          <div className="text-sm text-gray-9 transition-all">
            <Trigger asChild>
              <button
                className={className}
                onClick={() => onClick?.()}
                ref={wrapperRef as RefObject<HTMLButtonElement>}
              >
                {children}
              </button>
            </Trigger>
            <Content>
              <div className="overflow-hidden pl-10 pr-4">
                {collapsibleContent}
              </div>
            </Content>
          </div>
        </CollapsibleRoot>
      ) : (
        <>
          {url ? (
            <Link
              to={url}
              className={className}
              onClick={() => onClick?.()}
              ref={wrapperRef as RefObject<HTMLAnchorElement>}
              target={target}
            >
              {children}
            </Link>
          ) : (
            <button
              className={className}
              onClick={() => onClick?.()}
              ref={wrapperRef as RefObject<HTMLButtonElement>}
            >
              {children}
            </button>
          )}
          {preview}
        </>
      )}
      {Boolean(connectedRight) && (
        <div
          role="presentation"
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseOver}
          onFocus={onFocus}
          onBlur={onBlur}
          className="absolute right-[12px] top-2 h-[32px] w-auto max-sm:invisible"
        >
          {connectedRight}
        </div>
      )}
    </section>
  );
};

export function ActivityCard(props: ActivityCardProps) {
  const {
    url,
    icon,
    text,
    subText,
    onClick,
    connectedRight,
    onToolbarItemClick,
    primaryToolbarItems,
    secondaryToolbarItems,
    preview,
    wrapperRef,
    detailsRef,
    onMenuToggle,
    collapsibleContent,
    colorName,
    target = '_self',
    wrapperClassName,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const { getContainerProps, getToolbarProps } = useToolbarState();

  const handleOnClick = () => {
    if (collapsibleContent) {
      setIsOpen(!isOpen);
    }
    onClick?.();
  };

  return (
    <Wrapper
      url={url}
      onClick={handleOnClick}
      preview={preview}
      wrapperRef={wrapperRef}
      isOpen={isOpen}
      collapsibleContent={collapsibleContent}
      className="block w-full rounded-lg bg-gray-1 text-left text-gray-9 focus-visible:bg-gray-3 max-md:px-2"
      wrapperClassName={wrapperClassName}
      target={target}
      connectedRight={connectedRight}
      {...getContainerProps()}
    >
      <div
        className="group grid w-full grid-cols-[max-content_minmax(0,1fr)_max-content_max-content] gap-x-3 rounded-t-lg px-4 py-2 text-left text-gray-9 max-md:px-2"
        ref={detailsRef}
      >
        <div className="col-start-1 row-start-1 flex flex-row items-center">
          {icon}
        </div>

        <div
          className={`relative col-start-2 flex items-center ${subText ? 'pb-1' : 'pt-1'}`}
        >
          {isValidElement(text) ? (
            text
          ) : (
            <>
              {colorName ? (
                <TextStyle
                  variant="base-medium"
                  className={twMerge(
                    'truncate rounded px-2',
                    getFolderStyles(colorName).background
                  )}
                  html={text as string | TrustedHTML}
                />
              ) : (
                <TextStyle
                  className="truncate"
                  html={text as string | TrustedHTML}
                />
              )}
            </>
          )}
          {Boolean(collapsibleContent) && (
            <ChevronDownIcon
              className={twMerge(
                'ml-2 inline h-4 w-4',
                isOpen && 'rotate-180 transform'
              )}
            />
          )}
        </div>

        {subText ? (
          <TextStyle
            className="col-start-2 mt-[-4px] line-clamp-2"
            variant="sm-regular"
            html={subText}
            subdued
          />
        ) : null}

        {onToolbarItemClick &&
        ((secondaryToolbarItems && secondaryToolbarItems.length > 0) ||
          (primaryToolbarItems && primaryToolbarItems.length > 0)) ? (
          <div className="col-start-3 row-start-1 row-end-4 h-[34px] max-md:hidden">
            <Toolbar
              {...getToolbarProps({
                onMenuItemClick(args) {
                  onToolbarItemClick(args);
                },
                onToggle: onMenuToggle,
              })}
              primaryToolbarItems={primaryToolbarItems}
              secondaryToolbarItems={[]}
            />
          </div>
        ) : null}
        {Boolean(connectedRight) && (
          <div className="col-start-4 row-start-1 row-end-4 justify-end max-sm:col-start-2 max-sm:row-start-auto max-sm:row-end-auto sm:invisible">
            {connectedRight}
          </div>
        )}
      </div>
    </Wrapper>
  );
}
