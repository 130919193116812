import { isTruthy, type OpenEndedBlockResponse } from '@assembly-web/services';
import parse from 'html-react-parser';
import type { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import type { ToolbarItem } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { sanitizeMessageContent } from '../../Shared/messageUtils';
import { FileBlock } from '../../Shared/PostCard/FileBlock';

export type BodyProps = {
  onMemberClick: (memberId: string) => void;
  response?: OpenEndedBlockResponse;
  handleInternalLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  isSpecialPost?: boolean;
  blockId: string;
  onFileClick?: (responseBlock: {
    blockId: string;
    fileName: string;
    fileType: string;
    location?: string;
  }) => void;
  onToolbarItemClick?: (responseBlock: {
    blockId: string;
    fileName: string;
    itemId: string;
  }) => void;
};

export function Body({
  onMemberClick,
  response,
  handleInternalLinkClick,
  isSpecialPost,
  onFileClick,
  blockId,
  onToolbarItemClick,
}: BodyProps) {
  const { formatMessage } = useIntl();

  const handleFileClick = (
    fileName: string,
    fileType: string,
    location?: string
  ) => {
    onFileClick?.({
      blockId,
      fileName,
      fileType,
      location,
    });
  };

  const handleToolbarItemClick = ({
    args,
    fileName,
  }: {
    args: ToolbarItem;
    fileName: string;
  }) => {
    onToolbarItemClick?.({
      blockId,
      fileName,
      itemId: args.id,
    });
  };

  if (!response) {
    return null;
  }

  return (
    <section className="flex flex-col gap-4 px-6 py-4">
      <div
        className={twJoin(
          'flex flex-col gap-4 rounded-2xl p-4',
          isSpecialPost && 'bg-gray-2'
        )}
      >
        {isTruthy(response) &&
          parse(
            response.valueHtml ?? '',
            sanitizeMessageContent({
              formatMessage,
              onMemberClick,
              taggedUsers: response.taggedUsers,
              handleInternalLinkClick,
            })
          )}
      </div>
      {isTruthy(response.files?.length) && isTruthy(onFileClick) && (
        <div className="grid text-gray-9 transition-all">
          <FileBlock
            files={response.files}
            onFileClick={handleFileClick}
            onToolbarItemClick={handleToolbarItemClick}
          />
        </div>
      )}
    </section>
  );
}
