import {
  type AmazonOrderResponse,
  type AmazonOrderStatus,
  RewardType,
  type ShippingAddress,
  type SwagRewardProvider,
} from '@assembly-web/services';
import parse from 'html-react-parser';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Button } from '../../../DesignSystem/Inputs/Button';

type baseRedemptionDetailsProps = {
  id: string;
  image: string;
  name: string;
};

type GiftCardRedemptionDetailsProps = {
  rewardType: RewardType.GiftCards | RewardType.CashEquivalent;
  rewardInfo?: {
    redemptionInstructions: string;
    type: string;
    code: string;
    label: string;
  };
  giftCardAmount?: {
    value: number;
    currencyCode: string;
    total: number;
  };
} & baseRedemptionDetailsProps;

type SwagRedemptionDetailsProps = {
  rewardType: RewardType.Swag;
  pointsRedeemed?: number;
  shippingInfo?: SwagDetails;
  icon?: string;
  rewardProvider?: SwagRewardProvider;
} & baseRedemptionDetailsProps;

type AmazonRedemptionDetailsProps = {
  rewardType: RewardType.Amazon;
  amazonDetails?: AmazonDetails;
} & baseRedemptionDetailsProps;

type RedemptionDetailsProps =
  | GiftCardRedemptionDetailsProps
  | SwagRedemptionDetailsProps
  | AmazonRedemptionDetailsProps;

const getRewardStatus = (
  key:
    | 'CANCELED'
    | 'DELIVERED'
    | 'ORDER_PLACED'
    | 'SHIPPED'
    | 'TEST_ORDER'
    | 'canceled'
    | 'pending'
    | 'fulfilled'
    | 'delivered'
    | 'outOfStock'
) => {
  if (key === 'CANCELED' || key === 'canceled') return 'Cancelled';
  if (key === 'DELIVERED' || key === 'delivered') return 'Delivered';
  if (key === 'ORDER_PLACED' || key === 'pending') return 'Order placed';
  if (key === 'SHIPPED' || key === 'fulfilled') return 'Shipped';
  return 'Test order';
};

const getAmazonRewardStatus = (orderStatus?: AmazonOrderStatus) => {
  const statusMap: Record<string, string> = {
    CLOSED: 'Delivered',
    CANCELLED: 'Cancelled',
    PENDING: 'Shipped',
  };

  return orderStatus && orderStatus in statusMap
    ? statusMap[orderStatus]
    : 'Pending shipping';
};

const getAmazonStatusColorFromStatus = (orderStatus?: AmazonOrderStatus) => {
  const statusColorMap: Record<string, string> = {
    CLOSED: 'bg-success-6',
    CANCELLED: 'bg-error-6',
    PENDING: 'bg-blue-6',
  };

  return orderStatus ? statusColorMap[orderStatus] : 'bg-gray-4';
};

const getColorFromStatus = (
  status: 'CANCELED' | 'DELIVERED' | 'ORDER_PLACED' | 'SHIPPED' | 'TEST_ORDER'
) => {
  if (status === 'CANCELED') return 'bg-error-6';
  if (status === 'DELIVERED') return 'bg-success-6';
  if (status === 'ORDER_PLACED') return 'bg-gray-4';
  if (status === 'SHIPPED') return 'bg-blue-6';
  return 'bg-gray-4';
};

const messages = defineMessages({
  quantity: {
    defaultMessage: 'Quantity:',
    id: 'VSlYv6',
  },
  size: {
    defaultMessage: 'Size:',
    id: 'WDhfd3',
  },
  seeRedemptionDetails: {
    defaultMessage: 'See redemption details',
    id: 'dqlTf2',
  },
  for: {
    defaultMessage: 'for',
    id: 'wMoBuS',
  },
  redeemed: {
    defaultMessage: 'Redeemed:',
    id: 'i1+08O',
  },
  fulfilled: {
    defaultMessage: 'Fulfilled',
    id: 'jY+f2f',
  },
  resendEmail: {
    defaultMessage: 'Resend email',
    id: '5q4xKF',
  },
  remindAdmin: {
    defaultMessage: 'Remind admin',
    id: 'XG4WgP',
  },
  emailSent: {
    defaultMessage: 'Email resent',
    id: 'lhukCj',
  },
  remindedAdmin: {
    defaultMessage: 'Reminder sent',
    id: 'yM0xRC',
  },
  statusMessage: {
    defaultMessage: '{statusMessage}',
    id: 'rqJX6u',
  },
  variant: {
    defaultMessage: 'Product: {value}',
    id: 'xgk0F4',
  },
});

export type SwagDetails = {
  size?: string;
  quantity?: number;
  firstName: string;
  lastName: string;
  color?: string;
  address: string;
  apartment: string;
  city: string;
  state: string;
  countryCode: string;
  pincode: string;
  company: string;
  orderStatus:
    | 'CANCELED'
    | 'DELIVERED'
    | 'ORDER_PLACED'
    | 'SHIPPED'
    | 'TEST_ORDER';
  variant?: string;
};

export type AmazonDetails = {
  shippingDetails?: ShippingAddress;
  description?: string;
  orderStatus?: AmazonOrderStatus;
  amazonOrderResponse?: AmazonOrderResponse;
};

export type MyRewardCardProps = {
  id: string;
  icon: string;
  name: string;
  disabled?: boolean;
  description?: string;
  redeemDate: string;
  pointsRedeemed: number;
  rewardType:
    | 'gift card'
    | 'cash equivalent'
    | 'axomo'
    | 'donation'
    | 'custom'
    | 'amazon'
    | 'covver';
  quantity?: number;
  rewardInfo?: {
    redemptionInstructions: string;
    type: string;
    code: string;
    label: string;
  };
  image: string;
  resendEmailCount: number;
  handleRemindAdmin: (orderId: string) => void;
  handleShowRedemptionDetails: (reward: RedemptionDetailsProps) => void;
  swagDetails?: SwagDetails;
  giftCardAmount?: {
    value: number;
    currencyCode: string;
    total: number;
  };
  amazonDetails?: AmazonDetails;
};

export function MyRewardCard(props: MyRewardCardProps) {
  const {
    name,
    redeemDate,
    pointsRedeemed,
    rewardType,
    swagDetails,
    image,
    rewardInfo,
    icon,
    description,
    id,
    disabled,
    resendEmailCount,
    giftCardAmount,
    handleRemindAdmin,
    handleShowRedemptionDetails,
    amazonDetails,
  } = props;
  const { formatMessage } = useIntl();
  const disableResend = resendEmailCount > 0;

  const getModalDetails = (): RedemptionDetailsProps => {
    const commonDetails = {
      id,
      image,
      name,
    };

    switch (rewardType) {
      case RewardType.Amazon:
        return {
          ...commonDetails,
          rewardType: RewardType.Amazon,
          amazonDetails,
        };
      case RewardType.Axomo:
        return {
          ...commonDetails,
          rewardType: RewardType.Swag,
          pointsRedeemed,
          shippingInfo: swagDetails,
          rewardProvider: 'AXOMO',
        };
      case RewardType.Covver:
        return {
          ...commonDetails,
          rewardType: RewardType.Swag,
          pointsRedeemed,
          shippingInfo: swagDetails,
          rewardProvider: 'COVVER',
        };
      case RewardType.CashEquivalent:
        return {
          ...commonDetails,
          rewardType: RewardType.CashEquivalent,
          rewardInfo,
          giftCardAmount,
        };
      default:
        return {
          ...commonDetails,
          rewardType: RewardType.GiftCards,
          rewardInfo,
          giftCardAmount,
        };
    }
  };

  const getStatusMessage = () => {
    if (rewardType === 'amazon' && amazonDetails) {
      return `<p className='flex items-center gap-2 text-gray-7 text-sm'><span className="h-2 w-2 inline-block rounded-full ${getAmazonStatusColorFromStatus(
        amazonDetails.orderStatus
      )}"></span> ${getAmazonRewardStatus(amazonDetails.orderStatus)}</p>`;
    }

    if (
      (rewardType === 'axomo' || rewardType === 'covver') &&
      swagDetails?.orderStatus
    ) {
      return `<p className='flex items-center gap-2 text-gray-8 text-sm'><span className="h-2 w-2 inline-block rounded-full ${getColorFromStatus(
        swagDetails.orderStatus
      )}"></span> ${getRewardStatus(swagDetails.orderStatus)}</p>`;
    }

    if (rewardType === 'donation') {
      return `<p className='flex items-center gap-2 text-gray-8 text-sm'><span className="bg-success-6 h-2 w-2 inline-block rounded-full"></span> ${formatMessage(
        messages.fulfilled
      )}</p>`;
    }

    return '';
  };
  return (
    <div className="my-4 flex h-[325px] flex-col overflow-hidden rounded-lg border border-gray-5 bg-gray-1 hover:shadow-md-down sm:h-[156px] sm:flex-row">
      <div
        className={twMerge(
          'h-[160px] shrink-0 overflow-hidden bg-gray-3 sm:h-auto sm:w-[246px] sm:border-r sm:border-gray-5',
          (rewardType === 'gift card' ||
            rewardType === 'cash equivalent' ||
            rewardType === 'donation') &&
            'py-2 sm:py-0'
        )}
      >
        <img
          src={image}
          alt=""
          className={twMerge(
            'h-full w-full object-contain',
            (rewardType === 'gift card' ||
              rewardType === 'cash equivalent' ||
              rewardType === 'donation') &&
              'sm:scale-105'
          )}
        />
      </div>
      <div className="w-full p-6">
        <div className="flex h-8 items-center justify-between">
          <h6 className="mb-1 line-clamp-1 font-medium">{name}</h6>
          <div className="flex-shrink-0">
            {formatMessage(messages.statusMessage, {
              statusMessage: parse(getStatusMessage()),
            })}
            {rewardType === 'gift card' ||
            rewardType === 'cash equivalent' ||
            rewardType === 'custom' ? (
              <Button
                disabled={disableResend || disabled}
                variation="secondaryLite"
                className="h-auto px-[12px] py-[4px]"
                onClick={() => handleRemindAdmin(id)}
              >
                {rewardType === 'gift card' || rewardType === 'cash equivalent'
                  ? disableResend
                    ? formatMessage(messages.emailSent)
                    : formatMessage(messages.resendEmail)
                  : disableResend
                    ? formatMessage(messages.remindedAdmin)
                    : formatMessage(messages.remindAdmin)}
              </Button>
            ) : null}
          </div>
        </div>
        <p className="py-.5 mb-4 w-fit rounded-full bg-gray-4 px-3 text-xs text-gray-8">
          {formatMessage(messages.redeemed)}{' '}
          {new Date(redeemDate).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}{' '}
          {formatMessage(messages.for)} {parse(icon)} {pointsRedeemed}
        </p>

        {rewardType === 'custom' && (
          <p className="text-gray-8">{description}</p>
        )}

        {rewardType === 'amazon' && (
          <p className="text-sm text-gray-8">{amazonDetails?.description}</p>
        )}

        {rewardType === 'axomo' && (
          <p className="text-sm text-gray-8">
            {formatMessage(messages.quantity)} {swagDetails?.quantity},{' '}
            {formatMessage(messages.size)} {swagDetails?.size}
          </p>
        )}
        {rewardType === 'covver' && (
          <p className="text-sm text-gray-8">
            {formatMessage(messages.variant, {
              value: swagDetails?.variant,
            })}
          </p>
        )}

        {rewardType !== 'custom' && rewardType !== 'donation' && (
          <button
            className="text-sm text-primary-6"
            onClick={() => handleShowRedemptionDetails(getModalDetails())}
          >
            {formatMessage(messages.seeRedemptionDetails)}
          </button>
        )}
      </div>
    </div>
  );
}
