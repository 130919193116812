import {
  type GroupOrUserRecipientData,
  MemberState,
} from '@assembly-web/services';
import { MagnifyingGlassIcon, UsersIcon } from '@heroicons/react/24/outline';
import {
  type ChangeEvent,
  Fragment,
  type PropsWithChildren,
  type ReactNode,
  useDeferredValue,
  useMemo,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import { Avatar, AvatarSize } from '../../DesignSystem/Feedback/Avatar';
import { TextField } from '../../DesignSystem/Inputs/TextField';
import { Popover } from '../Shared/Popover';
import {
  PostCardActions,
  type PostCardProps,
} from '../Shared/PostCard/PostCardUI';
import { DepartmentList } from './DepartmentList';
import { ProfileViewer } from './ProfileViewer/ProfileViewer';
import { QuickParticipation } from './QuickParticipation/QuickParticipation';
import { NoUsersFound } from './UsersEmptyStates';

type Base = {
  recipients: GroupOrUserRecipientData[];
  maxRecipients?: number;
  showDetails?: boolean;
  onPostCardHeaderClick?: PostCardProps['onPostCardHeaderClick'];
};

type AvatarProps = {
  type: 'avatar';
  size?: AvatarSize;
};

type NameProps = {
  type: 'name';
};

type RecipientsProps = Base & (AvatarProps | NameProps);

const messages = defineMessages({
  search: {
    defaultMessage: 'Search',
    id: 'xmcVZ0',
  },
  departmentLabel: {
    defaultMessage: '{name} department',
    id: 'AtpxHb',
  },
  others: {
    defaultMessage: '{count, plural, one {1 other} other {{count} others}}',
    id: 'Nk+vT+',
  },
  deactivatedUser: {
    defaultMessage: '{name} <light>(deactivated)</light>',
    id: 'gsUDFs',
  },
});

function ListItem({ children }: PropsWithChildren) {
  return <div role="listitem">{children}</div>;
}

function Item({ recipient }: { recipient: GroupOrUserRecipientData }) {
  return (
    <ListItem>
      <QuickParticipation.ItemWrapper className="cursor-auto hover:bg-gray-3">
        <QuickParticipation.MemberItem
          {...(recipient.type === 'member'
            ? {
                type: 'member',
                id: recipient.memberID,
                isSelected: false,
                memberId: recipient.memberID,
                name: recipient.name,
                image: recipient.image,
                memberState: recipient.memberState,
              }
            : {
                type: 'department',
                id: recipient.id ?? recipient.name,
                name: recipient.name,
                isSelected: false,
              })}
        />
      </QuickParticipation.ItemWrapper>
    </ListItem>
  );
}

function OverflowContent({
  recipients,
}: {
  recipients: GroupOrUserRecipientData[];
}) {
  const { formatMessage } = useIntl();

  const [searchTerm, setSearchTerm] = useState('');
  const [totalMembersCount] = useState(() => recipients.length);
  const differedSearchTerm = useDeferredValue(searchTerm);

  const filteredRecipients = useMemo(
    () =>
      recipients.filter((recipient) =>
        recipient.name
          .toLocaleLowerCase()
          .includes(differedSearchTerm.toLocaleLowerCase())
      ),
    [recipients, differedSearchTerm]
  );

  return (
    <div className="flex max-h-[380px] w-80 flex-col gap-2 overflow-hidden pl-4 pt-4">
      {Boolean(totalMembersCount >= 20) && (
        <TextField
          type="text"
          className="pr-4"
          connectedLeft={
            <MagnifyingGlassIcon className="pointer-events-none h-4 w-4 text-gray-9" />
          }
          value={searchTerm}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.target.value);
          }}
          placeholder={formatMessage(messages.search)}
          aria-label={formatMessage(messages.search)}
        />
      )}
      <div
        role="list"
        className="relative flex flex-col gap-1 overflow-auto pb-4 pr-4"
      >
        {filteredRecipients.length === 0 ? (
          <ListItem>
            <NoUsersFound />
          </ListItem>
        ) : (
          filteredRecipients.map((recipient, index) => (
            <Item key={index} recipient={recipient} />
          ))
        )}
      </div>
    </div>
  );
}

function OverflowPopover({
  children,
  recipients,
}: PropsWithChildren<{
  recipients: GroupOrUserRecipientData[];
}>) {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      trigger={children}
      onTriggerButtonClick={() => setOpen((prev) => !prev)}
      contentClassName="p-0"
      align="center"
      side="bottom"
    >
      <OverflowContent recipients={recipients} />
    </Popover>
  );
}

function AvatarList({
  recipients,
  maxRecipients = 5,
  size = AvatarSize.QuadExtraLarge,
  showDetails,
  onPostCardHeaderClick,
}: AvatarProps & Base) {
  const { formatMessage } = useIntl();

  const hasMoreRecipients = recipients.length > maxRecipients;
  const largerThanLarge =
    size === AvatarSize.DoubleExtraLarge ||
    size === AvatarSize.QuadExtraLarge ||
    size === AvatarSize.HexExtraLarge;

  return (
    <div className="inline-flex w-fit [&>*:not(:first-child)]:-ml-2">
      {recipients.slice(0, maxRecipients).map((recipient, index) => (
        <Fragment key={index}>
          {(() => {
            const showOverlay =
              index === maxRecipients - 1 && hasMoreRecipients;

            const Comp = (() => {
              const overlay = showOverlay && {
                overlay: (
                  <span
                    className={twJoin(
                      largerThanLarge
                        ? 'text-xl font-medium'
                        : 'text-sm font-normal',
                      'select-none text-gray-1'
                    )}
                  >
                    +{recipients.length - maxRecipients + 1}
                  </span>
                ),
              };

              if (recipient.type === 'member') {
                return (
                  <Avatar
                    memberID={recipient.memberID}
                    name={recipient.name}
                    size={size}
                    image={recipient.image}
                    {...overlay}
                  />
                );
              }
              return (
                <Avatar
                  fallback={<UsersIcon className="h-6 w-6 text-gray-9" />}
                  fallbackGradientBackground="cyan"
                  size={size}
                  aria-label={formatMessage(messages.departmentLabel, {
                    name: recipient.name,
                  })}
                  {...overlay}
                />
              );
            })();

            if (!showDetails) {
              return Comp;
            }
            if (showOverlay) {
              return (
                <OverflowPopover
                  recipients={recipients.slice(maxRecipients - 1)}
                >
                  {Comp}
                </OverflowPopover>
              );
            }
            if (recipient.type === 'member') {
              return (
                <ProfileViewer
                  userDetails={recipient}
                  onViewProfileClick={(memberID) =>
                    onPostCardHeaderClick?.(
                      PostCardActions.PersonClicked,
                      memberID
                    )
                  }
                >
                  <button>{Comp}</button>
                </ProfileViewer>
              );
            }
            return (
              <DepartmentList
                id={recipient.id ?? recipient.name}
                criteriaId={recipient.criteriaId}
                postId={recipient.postId}
                name={recipient.name}
                count={recipient.count}
              >
                {Comp}
              </DepartmentList>
            );
          })()}
        </Fragment>
      ))}
    </div>
  );
}

function NamesList({
  recipients,
  maxRecipients = 5,
  showDetails,
  onPostCardHeaderClick,
}: NameProps & Base) {
  const { formatList, formatMessage } = useIntl();

  const hasMoreRecipients = recipients.length > maxRecipients;

  return (
    <p>
      {formatList(
        recipients.slice(0, maxRecipients).map((recipient, index) => {
          const overflowItem = index === maxRecipients - 1 && hasMoreRecipients;
          const isDeactivated =
            recipient.type === 'member' &&
            recipient.memberState === MemberState.Deactivated;
          const Comp = (
            <span
              {...(!overflowItem &&
                recipient.type === 'member' && {
                  tabIndex: 0,
                  role: 'button',
                  onClick: () =>
                    onPostCardHeaderClick?.(
                      PostCardActions.PersonClicked,
                      recipient.memberID
                    ),
                })}
              className={twMerge(
                'cursor-pointer break-words text-base font-medium text-primary-6 hover:text-primary-5',
                isDeactivated && 'text-gray-8 hover:text-gray-8'
              )}
            >
              {overflowItem
                ? formatMessage(messages.others, {
                    count: recipients.length - maxRecipients + 1,
                  })
                : isDeactivated
                  ? formatMessage(messages.deactivatedUser, {
                      name: recipient.name,
                      light: (text: ReactNode) => (
                        <span className="font-normal">{text}</span>
                      ),
                    })
                  : recipient.name}
            </span>
          );
          return !showDetails ? (
            Comp
          ) : overflowItem ? (
            <OverflowPopover recipients={recipients.slice(maxRecipients - 1)}>
              {Comp}
            </OverflowPopover>
          ) : recipient.type === 'member' ? (
            <ProfileViewer
              userDetails={recipient}
              onViewProfileClick={(memberID) =>
                onPostCardHeaderClick?.(PostCardActions.PersonClicked, memberID)
              }
              key={index}
            >
              {Comp}
            </ProfileViewer>
          ) : (
            <DepartmentList
              id={recipient.id ?? recipient.name}
              criteriaId={recipient.criteriaId}
              postId={recipient.postId}
              name={recipient.name}
              count={recipient.count}
            >
              {Comp}
            </DepartmentList>
          );
        }),
        { style: 'long', type: 'conjunction' }
      )}
    </p>
  );
}

export function Recipients({ showDetails = true, ...props }: RecipientsProps) {
  if (props.type === 'avatar') {
    return (
      <AvatarList
        type="avatar"
        recipients={props.recipients}
        maxRecipients={props.maxRecipients}
        size={props.size}
        showDetails={showDetails}
        onPostCardHeaderClick={props.onPostCardHeaderClick}
      />
    );
  }
  return (
    <NamesList
      type="name"
      recipients={props.recipients}
      maxRecipients={props.maxRecipients}
      showDetails={showDetails}
      onPostCardHeaderClick={props.onPostCardHeaderClick}
    />
  );
}
