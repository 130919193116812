import type { TrackEventProps } from '../types/analytics';
import { getPageType, trackAction } from './analytics';

export type AdminAction =
  | 'createAwardClicked'
  | 'publishAwardClicked'
  | 'editAwardClicked'
  | 'saveAwardEditsClicked'
  | 'deleteAwardClicked'
  | 'duplicateAwardClicked'
  | 'awardHelpArticleClicked';

export type adminPage =
  | 'workspaceGeneral'
  | 'workspaceAllowances'
  | 'workspaceIntegrations'
  | 'workspaceInsights'
  | 'recognitionSettings'
  | 'awards'
  | 'flowsPermissions'
  | 'flowsInsights'
  | 'challengesSettings'
  | 'usersManage'
  | 'usersEarnedBalances'
  | 'botsWelcome'
  | 'botsBirthday'
  | 'botsAnniversary'
  | 'botsAutoDeposit'
  | 'rewardsGiftCards'
  | 'rewardsCulture'
  | 'rewardCharities'
  | 'rewardsSwag'
  | 'rewardsAmazon'
  | 'rewardsDiscounts'
  | 'rewardsExperiences'
  | 'reportsActivity'
  | 'reportsEarning'
  | 'reportsParticipation'
  | 'reportsRedemption'
  | 'billingAccountPlan'
  | 'billingPaymentHistory'
  | 'billingPaymentMethod'
  | 'securitySAML';

export function trackAdminShow(props?: TrackEventProps) {
  return trackAction({
    eventName: 'admin.events',
    eventProps: {
      event: 'show',
      ...props,
    },
  });
}

export function trackAdminAction(
  actionName: AdminAction,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'admin.events',
    eventProps: {
      event: 'action',
      action: actionName,
      ...props,
    },
  });
}

export function trackAdminError(props?: TrackEventProps) {
  return trackAction({
    eventName: 'admin.events',
    eventProps: {
      event: 'error',
      pageType: getPageType(),
      ...props,
    },
  });
}

export function trackAdminAwardsEvent(
  event: AdminAction | TrackEventProps,
  props: TrackEventProps = {}
) {
  const eventProps = { ...props, adminPage: 'awards' };
  return typeof event === 'string'
    ? trackAdminAction(event as AdminAction, eventProps)
    : trackAdminError({ ...event, adminPage: 'awards' });
}
