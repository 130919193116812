import type {
  ShippingCountry,
  ShippingDetailsForm as ShippingDetailsFormType,
  ShippingState,
} from '@assembly-web/services';
import { useCallback } from 'react';
import { defineMessages, type IntlShape, useIntl } from 'react-intl';
import { z } from 'zod';

import { InlineError } from '../../../DesignSystem/Feedback/InlineError';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { TextField } from '../../../DesignSystem/Inputs/TextField';
import { useDirtyFields } from '../../hooks/useDirtyFields';
import { SearchableSelect } from '../../Shared/SearchableSelect';

const validatePhoneNumber = (phoneNumber: string) => {
  const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
  return phoneRegex.test(phoneNumber);
};

const createPhoneNumberValidation = (
  formatMessage: IntlShape['formatMessage'],
  isAmazonForm: boolean
) => {
  const amazonFormPhoneNumberSchema = z
    .string()
    .min(1, formatMessage(messages.phoneNumberIsRequired))
    .superRefine((data, ctx) => {
      const trimmedData = data.trim();

      if (trimmedData && !validatePhoneNumber(trimmedData)) {
        ctx.addIssue({
          code: 'custom',
          message: formatMessage(messages.invalidPhoneNumberFormat),
        });
        return;
      }
    });
  const axomoFormPhoneNumberSchema = z.string().superRefine((data, ctx) => {
    const trimmedData = data.trim();

    if (trimmedData && !validatePhoneNumber(trimmedData)) {
      ctx.addIssue({
        code: 'custom',
        message: formatMessage(messages.invalidPhoneNumberFormat),
      });
      return;
    }
  });
  return isAmazonForm
    ? amazonFormPhoneNumberSchema
    : axomoFormPhoneNumberSchema;
};

const selectableOptionSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1),
  value: z.string().min(1),
});

const getShippingDetailsSchema = (
  formatMessage: IntlShape['formatMessage'],
  isAmazonForm: boolean
) => {
  const phoneNumberValidation = createPhoneNumberValidation(
    formatMessage,
    isAmazonForm
  );
  return z.object({
    firstName: z.string().min(1, formatMessage(messages.firstNameIsRequired)),
    lastName: z.string().min(1, formatMessage(messages.lastNameIsRequired)),
    country: selectableOptionSchema,
    state: selectableOptionSchema,
    company: z.string().optional(),
    address: z.string().min(1, formatMessage(messages.addressIsRequired)),
    secondaryAddress: z.string().optional(),
    city: z.string().min(1, formatMessage(messages.cityIsRequired)),
    zip: z.string().min(1, formatMessage(messages.zipIsRequired)),
    phoneNumber: phoneNumberValidation,
  });
};

const initialDirtyFields = {
  firstName: false,
  lastName: false,
  country: false,
  state: false,
  company: false,
  address: false,
  secondaryAddress: false,
  city: false,
  zip: false,
  phoneNumber: false,
};

const TextFieldWrapper = (props: React.ComponentProps<typeof TextField>) => {
  return <TextField {...props} inputClassName="placeholder:text-sm text-sm" />;
};

const messages = defineMessages({
  internationalShippingNotAvailable: {
    id: 'l+dcGL',
    defaultMessage: 'International shipping is not available for this item',
  },
  Country: {
    defaultMessage: 'Country',
    id: 'vONi+O',
  },
  State: {
    defaultMessage: 'State/province',
    id: '8FpaMK',
  },
  ZipCode: {
    defaultMessage: 'Zip Code',
    id: 'Solm6E',
  },
  PhoneNumber: {
    defaultMessage: 'Phone number',
    id: 'jdJhOL',
  },
  FirstName: {
    defaultMessage: 'First name',
    id: 'pONqz8',
  },
  LastName: {
    defaultMessage: 'Last name',
    id: 'txUL0F',
  },
  Company: {
    defaultMessage: 'Company (optional)',
    id: 'tM6Uq8',
  },
  Address: {
    defaultMessage: 'Address',
    id: 'e6Ph5+',
  },
  AptSuite: {
    defaultMessage: 'Apt, suite, etc (optional)',
    id: 'HCelCq',
  },
  City: {
    defaultMessage: 'City',
    id: 'TE4fIS',
  },
  countryIsRequired: {
    defaultMessage: 'Country is required',
    id: 'jlqOPq',
  },
  stateIsRequired: {
    defaultMessage: 'State is required',
    id: 'Lmlys/',
  },
  phoneNumberIsRequired: {
    defaultMessage: 'Phone number is required',
    id: 'UHu1T/',
  },
  invalidPhoneNumberFormat: {
    defaultMessage: 'Invalid phone number format',
    id: 'uli+HD',
  },
  minPhoneNumberLength: {
    defaultMessage: 'Phone number must be at least {min} characters',
    id: '+Gjcr6',
  },
  zipIsRequired: {
    defaultMessage: 'Zip code is required',
    id: 'z7+EXa',
  },
  cityIsRequired: {
    defaultMessage: 'City is required',
    id: 'W435jR',
  },
  addressIsRequired: {
    defaultMessage: 'Address is required',
    id: 'PWe6/f',
  },
  lastNameIsRequired: {
    defaultMessage: 'Last name is required',
    id: 'yos4hi',
  },
  firstNameIsRequired: {
    defaultMessage: 'First name is required',
    id: 'FZI5xl',
  },
});

export const ShippingDetailsForm = ({
  shippableCountries = [],
  onCountryChange,
  stateList = [],
  formErrors = {},
  onUpdate,
  formData,
  isStateListLoading,
  isAmazonForm,
}: {
  shippableCountries: ShippingCountry[] | undefined;
  onCountryChange: (country: ShippingCountry) => void;
  onStateChange?: (state: string) => void;
  stateList: ShippingState[] | undefined;
  formErrors?: Record<string, string>;
  onUpdate: (
    data: ShippingDetailsFormType,
    errors: Record<string, unknown>
  ) => void;
  formData: ShippingDetailsFormType;
  isStateListLoading: boolean;
  isAmazonForm: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { dirtyFields, markFieldAsDirty, resetDirtyFields } =
    useDirtyFields(initialDirtyFields);

  const validateFormData = useCallback(
    (data: typeof formData) => {
      try {
        const ShippingDetailsFormSchema = getShippingDetailsSchema(
          formatMessage,
          isAmazonForm
        );
        ShippingDetailsFormSchema.parse(data);
        return {};
      } catch (error) {
        if (error instanceof z.ZodError) {
          const fieldErrors = error.flatten().fieldErrors;
          if (fieldErrors.country) {
            fieldErrors.country = [formatMessage(messages.countryIsRequired)];
          }
          if (fieldErrors.state) {
            fieldErrors.state = [formatMessage(messages.stateIsRequired)];
          }
          return fieldErrors;
        }
        return {};
      }
    },
    [formatMessage, isAmazonForm]
  );

  const handleTextFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      const newData = { ...formData, [name]: value };
      const errors = validateFormData(newData);
      onUpdate(newData, errors);
      markFieldAsDirty(name);
    },
    [onUpdate, validateFormData, markFieldAsDirty, formData]
  );

  const handleSelectChange = useCallback(
    (name: string, selected: ShippingCountry | ShippingState) => {
      const newData = { ...formData, [name]: selected };
      const errors = validateFormData(newData);
      onUpdate(newData, errors);
      markFieldAsDirty(name);
      if (name === 'country') {
        const { firstName, lastName, ...addressFields } = initialDirtyFields;
        onCountryChange(selected);
        resetDirtyFields(addressFields);
      }
    },
    [
      onUpdate,
      onCountryChange,
      validateFormData,
      markFieldAsDirty,
      formData,
      resetDirtyFields,
    ]
  );

  return (
    <div className="flex flex-col gap-4" data-testid="shipping-details-form">
      <div className="flex flex-row gap-4 text-xs">
        <TextFieldWrapper
          placeholder={formatMessage(messages.FirstName)}
          invalidText={
            dirtyFields.firstName ? (formErrors.firstName as string) : ''
          }
          isInvalid={
            Boolean(dirtyFields.firstName) && Boolean(formErrors.firstName)
          }
          name="firstName"
          onChange={handleTextFieldChange}
          value={formData.firstName}
        />
        <TextFieldWrapper
          placeholder={formatMessage(messages.LastName)}
          invalidText={
            dirtyFields.lastName ? (formErrors.lastName as string) : ''
          }
          isInvalid={
            Boolean(dirtyFields.lastName) && Boolean(formErrors.lastName)
          }
          name="lastName"
          onChange={handleTextFieldChange}
          value={formData.lastName}
        />
      </div>
      <div className="z-40">
        <SearchableSelect
          selectOptions={shippableCountries}
          selectedOption={formData.country}
          onChange={(option) => handleSelectChange('country', option)}
          name="country"
          displayValue={(item) => item.name}
          placeholder={formatMessage(messages.Country)}
          isInvalid={
            Boolean(dirtyFields.country) && Boolean(formErrors.country)
          }
          isDisabled={shippableCountries.length === 1}
          className="rounded-lg bg-gray-1"
        />
        {Boolean(dirtyFields.country) && Boolean(formErrors.country) && (
          <InlineError id={'countryFieldError'} size="xs" as="span">
            {formErrors.country as string}
          </InlineError>
        )}
        {shippableCountries.length === 1 && (
          <TextStyle variant="xs-regular" className="text-gray-8" as="span">
            {formatMessage(messages.internationalShippingNotAvailable)}
          </TextStyle>
        )}
      </div>
      <TextFieldWrapper
        placeholder={formatMessage(messages.Company)}
        invalidText={dirtyFields.company ? (formErrors.company as string) : ''}
        isInvalid={Boolean(dirtyFields.company) && Boolean(formErrors.company)}
        name="company"
        onChange={handleTextFieldChange}
        value={formData.company}
      />
      <TextFieldWrapper
        placeholder={formatMessage(messages.Address)}
        invalidText={dirtyFields.address ? (formErrors.address as string) : ''}
        isInvalid={Boolean(dirtyFields.address) && Boolean(formErrors.address)}
        name="address"
        onChange={handleTextFieldChange}
        value={formData.address}
      />
      <TextFieldWrapper
        placeholder={formatMessage(messages.AptSuite)}
        invalidText={
          dirtyFields.secondaryAddress
            ? (formErrors.secondaryAddress as string)
            : ''
        }
        isInvalid={
          Boolean(dirtyFields.secondaryAddress) &&
          Boolean(formErrors.secondaryAddress)
        }
        name="secondaryAddress"
        onChange={handleTextFieldChange}
        value={formData.secondaryAddress}
      />
      <div className="flex w-full flex-row gap-4">
        <TextFieldWrapper
          placeholder={formatMessage(messages.City)}
          invalidText={dirtyFields.city ? (formErrors.city as string) : ''}
          isInvalid={Boolean(dirtyFields.city) && Boolean(formErrors.city)}
          name="city"
          onChange={handleTextFieldChange}
          value={formData.city}
        />
        <div className="flex w-full flex-col">
          <div className="z-30">
            <SearchableSelect
              selectOptions={stateList}
              selectedOption={formData.state}
              onChange={(option) => handleSelectChange('state', option)}
              name="state"
              displayValue={(item) => item.name}
              placeholder={formatMessage(messages.State)}
              isInvalid={
                Boolean(dirtyFields.state) && Boolean(formErrors.state)
              }
              className="rounded-lg bg-gray-1"
              isLoading={isStateListLoading}
            />
            {Boolean(dirtyFields.state) && Boolean(formErrors.state) && (
              <InlineError id={'stateFieldError'} size="xs" as="span">
                {formErrors.state as string}
              </InlineError>
            )}
          </div>
        </div>
      </div>
      <TextFieldWrapper
        placeholder={formatMessage(messages.ZipCode)}
        invalidText={dirtyFields.zip ? (formErrors.zip as string) : ''}
        isInvalid={Boolean(dirtyFields.zip) && Boolean(formErrors.zip)}
        name="zip"
        onChange={handleTextFieldChange}
        value={formData.zip}
      />
      <TextFieldWrapper
        placeholder={formatMessage(messages.PhoneNumber)}
        invalidText={
          dirtyFields.phoneNumber ? (formErrors.phoneNumber as string) : ''
        }
        isInvalid={
          Boolean(dirtyFields.phoneNumber) && Boolean(formErrors.phoneNumber)
        }
        name="phoneNumber"
        onChange={handleTextFieldChange}
        value={formData.phoneNumber}
      />
    </div>
  );
};
