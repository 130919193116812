import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import {
  type KeyboardEvent,
  type TextareaHTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useDeviceInfo } from '../../UI/hooks/useDeviceInfo';
import { useTouchDevice } from '../../UI/hooks/useTouchDevice';
import { TextStyle } from '../Feedback/TextStyle';
import { IconButton } from './IconButton';

export type OpenTextEditorProps =
  TextareaHTMLAttributes<HTMLTextAreaElement> & {
    /** Auto focus into the input on component mount */
    autoFocusInput?: boolean;

    /** Whether the submit button is disabled */
    isSubmitDisabled?: boolean;

    /** Callback when the submit button is clicked */
    onSubmit: () => void;

    /** Should input be submitted on Enter keystroke */
    shouldSubmitOnEnter?: boolean;

    /** Controlled value of input */
    value?: string;
  };

const messages = defineMessages({
  charLimitReached: {
    defaultMessage:
      'You have reached the max {limit} characters. You will not be able to add anything else.',
    id: 'g36utN',
  },
});

export const OpenTextEditor = (props: OpenTextEditorProps) => {
  const {
    className: textAreaClassName,
    onKeyDown,
    isSubmitDisabled,
    onSubmit,
    shouldSubmitOnEnter,
    autoFocusInput,
    ...textAreaProps
  } = props;

  const { value } = textAreaProps;
  const [isScrollable, setIsScrollable] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { formatMessage, locale } = useIntl();
  const touchDevice = useTouchDevice();
  const device = useDeviceInfo().deviceType;

  const isTouchDevice = touchDevice || device === 'mobile';

  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(locale),
    [locale]
  );

  const isMaxLengthReached = textAreaProps.maxLength
    ? textAreaProps.value &&
      textAreaProps.value.length === textAreaProps.maxLength
    : false;

  const handleOnKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    onKeyDown?.(event);
    if (
      !isTouchDevice &&
      shouldSubmitOnEnter &&
      event.key === 'Enter' &&
      !event.shiftKey
    ) {
      event.preventDefault();
      onSubmit();
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      // Dynamically grow the textarea
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      setIsScrollable(
        textareaRef.current.scrollHeight > textareaRef.current.clientHeight
      );
    }
  }, [value]);

  useEffect(() => {
    if (autoFocusInput) {
      textareaRef.current?.focus();
    }
  }, [autoFocusInput]);

  return (
    <>
      <div
        className={twMerge(
          'flex flex-col rounded-lg border border-gray-7',
          isMaxLengthReached
            ? 'focus-within:border-error-6 focus-within:shadow-[0_0_0_0_#FFF,0_0_0_1px_#F5222D]'
            : 'focus-within:border-primary-6 focus-within:shadow-[0_0_0_0_#FFF,0_0_0_1px_#2F54EB]'
        )}
      >
        <textarea
          className={twMerge(
            'mb-0 ml-3 mt-2 resize-none border-none p-0 pr-3 text-base font-normal leading-6 text-gray-9 placeholder:text-gray-7 focus:border-gray-7 focus:shadow-none focus:ring-0',
            textAreaClassName
          )}
          onKeyDown={handleOnKeyDown}
          ref={textareaRef}
          {...textAreaProps}
        />
        <section
          className={twMerge(
            'flex flex-row-reverse p-2',
            isScrollable && 'shadow-base-up'
          )}
        >
          <IconButton disabled={isSubmitDisabled} onClick={onSubmit}>
            <PaperAirplaneIcon
              className={twMerge(
                'h-6 w-6',
                isSubmitDisabled ? 'text-gray-7' : 'text-gray-1'
              )}
            />
          </IconButton>
        </section>
      </div>
      {isMaxLengthReached && textAreaProps.maxLength ? (
        <TextStyle className="mt-2.5" subdued variant="xs-regular">
          {formatMessage(messages.charLimitReached, {
            limit: numberFormatter.format(textAreaProps.maxLength),
          })}
        </TextStyle>
      ) : null}
    </>
  );
};
