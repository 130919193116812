import { emptySearch } from '@assembly-web/assets';
import {
  type ApprovalInfo,
  ApprovalStatusOption,
  type MemberAPIResponse,
  type SubmitApprovalOptions,
} from '@assembly-web/services';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedNumber, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Chip } from '../../../DesignSystem/Inputs/Chip';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../DesignSystem/Table/Table';
import { ConfirmActionApprovalModal } from '../../Shared/Awards/ConfirmActionApproval/ConfirmActionApprovalModal';
import { useConfirmationApprovalStore } from '../../Shared/Awards/ConfirmActionApproval/useConfirmationApproval';
import { ViewAwardPost } from './ViewAwardPost';

type ListOfApprovalsProps = {
  searchValue?: string;
  statusSelected: string;
  approvals: ApprovalInfo[];
  submitApproval: SubmitApprovalOptions;
  awardPost: {
    navigateToUserFeed: (memberId: string) => void;
    userDetails: MemberAPIResponse;
  };
};
const messages = defineMessages({
  dateGiven: {
    defaultMessage: 'Date given',
    id: 'YgeJsC',
  },
  recipients: {
    defaultMessage: 'Recipient (s)',
    id: '2g8Vei',
  },
  post: {
    defaultMessage: 'Post',
    id: 'eKv7yX',
  },
  approvalStatus: {
    defaultMessage: 'Approval status',
    id: 'Pr1pEI',
  },
  approveAction: {
    defaultMessage: 'Approve?',
    id: 'LnsZ+s',
  },
  viewPost: {
    defaultMessage: 'View post',
    id: 'TDQNax',
  },
  approvalStatusResult: {
    defaultMessage: '{level} of {totalLevel} levels',
    id: 'kalrQd',
  },
  dateDenied: {
    defaultMessage: 'Date denied',
    id: 'Bf3APm',
  },
  dateApproved: {
    defaultMessage: 'Date approved',
    id: 'reLQeO',
  },
  finalApproval: {
    defaultMessage: 'Final approval',
    id: '2Y6/9c',
  },
  posted: {
    defaultMessage: 'Posted',
    id: 'QYYHH3',
  },
  denied: {
    defaultMessage: 'Denied',
    id: '5kp1Ie',
  },
  noneDataSearchResult: {
    defaultMessage:
      'We couldn’t find anything matching your search. Please try again.',
    id: '1x1S+y',
  },
  noneData: {
    defaultMessage: 'Currently, there are no awards records available.',
    id: '9s35B+',
  },
  pending: {
    defaultMessage: 'Pending',
    id: 'eKEL/g',
  },
});

export const ListOfApprovals = ({
  searchValue,
  statusSelected,
  approvals,
  submitApproval,
  awardPost,
}: ListOfApprovalsProps) => {
  const { formatMessage } = useIntl();
  const { getNotShowAgain } = useConfirmationApprovalStore();

  const [selectedAction, setSelectedAction] = useState<
    'approve' | 'deny' | null
  >(null);
  const [showConfirmationApproval, setShowConfirmationApproval] = useState<{
    approve: boolean;
    deny: boolean;
  }>({ approve: false, deny: false });
  const [showViewAward, setShowViewAward] = useState(false);
  const [approvalSelected, setApprovalSelected] = useState<ApprovalInfo | null>(
    null
  );

  const viewAward = (approval: ApprovalInfo) => {
    setApprovalSelected(approval);
    setShowViewAward(true);
  };

  const handleShowConfirmationApprovalModal = useCallback(
    (type: 'approve' | 'deny', approval: ApprovalInfo) => {
      const isNotShowAgain = getNotShowAgain();
      setSelectedAction(type);

      setApprovalSelected(approval);
      if (type === 'approve') {
        if (isNotShowAgain.approve) {
          submitApproval.mutate({
            awardApprovalId: approval.awardApprovalId,
            action: type,
          });
          return;
        }
        setShowConfirmationApproval({ approve: true, deny: false });
        return;
      }
      if (isNotShowAgain.deny) {
        submitApproval.mutate({
          awardApprovalId: approval.awardApprovalId,
          action: type,
        });
        return;
      }
      setShowConfirmationApproval({ approve: false, deny: true });
    },

    [getNotShowAgain, submitApproval]
  );

  const handleHiddenConfirmationApprovalModal = useCallback(() => {
    setShowConfirmationApproval({ approve: false, deny: false });
  }, []);

  useEffect(() => {
    if (
      submitApproval.status === 'success' ||
      submitApproval.status === 'error'
    ) {
      setShowConfirmationApproval({
        approve: false,
        deny: false,
      });
      setShowViewAward(false);
    }
  }, [submitApproval]);

  const columnHelper = createColumnHelper<ApprovalInfo>();
  const columns = useMemo(() => {
    const columnDate = columnHelper.accessor('createdAt', {
      id: 'dateGiven',
      cell: (info) => {
        const value = info.getValue();
        const infoApproval = info.row.original;
        const renderDate = () => {
          switch (infoApproval.state) {
            case 'PENDING':
              return value;
            case 'APPROVED':
              return infoApproval.approvedAt;
            case 'DENIED':
              return infoApproval.deniedAt;
            default:
              return value;
          }
        };
        return (
          <TextStyle>{dayjs(renderDate()).format('MM/DD/YYYY')}</TextStyle>
        );
      },
      header: () => {
        const renderText = () => {
          switch (statusSelected) {
            case ApprovalStatusOption.Approved:
              return messages.dateApproved;
            case ApprovalStatusOption.Denied:
              return messages.dateDenied;
            case ApprovalStatusOption.Pending:
              return messages.dateGiven;
            default:
              return messages.dateGiven;
          }
        };
        return (
          <TextStyle variant="base-bold">
            {formatMessage(renderText())}
          </TextStyle>
        );
      },
    });
    const columnRecipients = columnHelper.accessor('recipient', {
      id: 'recipients',
      cell: (info) => {
        const recipient = info.getValue();
        if (
          Number(recipient?.individuals?.length) > 1 ||
          Number(recipient?.departments?.length) > 0 ||
          Number(recipient?.everyone?.count) > 0
        ) {
          const departmentCount = recipient?.departments?.reduce(
            (curr, acc) => {
              return curr + acc.count;
            },
            0
          );
          const count =
            Number(recipient?.individuals?.length) +
            Number(departmentCount) +
            Number(recipient?.everyone?.count ?? 0);
          return (
            <Chip.Root
              intent="badge"
              color="blue"
              className="border border-primary-3 px-2"
            >
              <Chip.Text>{<FormattedNumber value={count} />}</Chip.Text>
            </Chip.Root>
          );
        }
        return (
          <TextStyle variant="base-medium">
            {recipient?.individuals?.[0]?.name}
          </TextStyle>
        );
      },
      header: () => (
        <TextStyle variant="base-bold">
          {formatMessage(messages.recipients)}
        </TextStyle>
      ),
    });
    const columnPost = columnHelper.accessor('post', {
      id: 'post',
      cell: (info) => {
        const awardInfo = info.row.original;
        return (
          <TextStyle
            variant="base-medium"
            className="cursor-pointer text-primary-6"
            onClick={() => viewAward(awardInfo)}
          >
            {formatMessage(messages.viewPost)}
          </TextStyle>
        );
      },
      header: () => (
        <TextStyle variant="base-bold">
          {formatMessage(messages.post)}
        </TextStyle>
      ),
    });
    const columnStatus = columnHelper.accessor('approvals.levels', {
      id: 'levels',
      cell: (info) => {
        const levels = info.getValue();
        const awardInfo = info.row.original;
        if (awardInfo.state === 'PENDING') {
          return (
            <TextStyle variant="base-medium">
              {formatMessage(
                levels.current >= levels.total
                  ? messages.finalApproval
                  : messages.approvalStatusResult,
                {
                  level: levels.current,
                  totalLevel: levels.total,
                }
              )}
            </TextStyle>
          );
        }
        const isApproved = awardInfo.state === 'APPROVED';
        return (
          <Chip.Root
            intent="badge"
            color="gold"
            className={twMerge(
              'border',
              isApproved
                ? '!border-success-5 !bg-success-1'
                : '!border-error-4 !bg-error-1'
            )}
          >
            <Chip.Text>
              {formatMessage(isApproved ? messages.posted : messages.denied)}
            </Chip.Text>
          </Chip.Root>
        );
      },
      header: () => (
        <TextStyle variant="base-bold">
          {formatMessage(messages.approvalStatus)}
        </TextStyle>
      ),
    });
    const columnsForDenied = [columnDate, columnRecipients, columnStatus];

    const columnsForApproved = [...columnsForDenied, columnPost];
    const columnsForPending = [
      columnDate,
      columnRecipients,
      columnPost,
      columnStatus,
    ];

    switch (statusSelected) {
      case ApprovalStatusOption.Pending:
      case ApprovalStatusOption.All:
        return columnsForPending;
      case ApprovalStatusOption.Approved:
        return columnsForApproved;
      case ApprovalStatusOption.Denied:
        return columnsForDenied;
      default:
        return columnsForPending;
    }
  }, [columnHelper, formatMessage, statusSelected]);

  const table = useReactTable<ApprovalInfo>({
    columns,
    data: approvals,
    rowCount: approvals.length,
    manualSorting: true,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <>
      <Table className={twMerge(Number(approvals.length) <= 0 && 'h-[35vh]')}>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className="!border-b border-gray-5">
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id} className="py-2.5">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableHead>
              ))}
              {(statusSelected === ApprovalStatusOption.Pending ||
                statusSelected === ApprovalStatusOption.All) && (
                <TableHead className="py-2.5">
                  <TextStyle variant="base-bold">
                    {formatMessage(messages.approveAction)}
                  </TextStyle>
                </TableHead>
              )}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          {approvals.length <= 0 ? (
            <TableRow className="relative h-20">
              <TableCell>
                <p className="absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center space-y-4 rounded-2xl bg-gray-2 py-4">
                  <img
                    alt={formatMessage(messages.noneDataSearchResult)}
                    src={emptySearch}
                    className="mx-auto w-fit"
                  />
                  <TextStyle variant="base-medium">
                    {formatMessage(
                      searchValue && searchValue.length > 0
                        ? messages.noneDataSearchResult
                        : messages.noneData
                    )}
                  </TextStyle>
                </p>
              </TableCell>
            </TableRow>
          ) : (
            <>
              {table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <TableCell key={cell.id} className="py-3">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    );
                  })}
                  {(statusSelected === ApprovalStatusOption.Pending ||
                    statusSelected === ApprovalStatusOption.All) && (
                    <TableCell className="py-3">
                      {row.original.state === 'APPROVED' ||
                      row.original.state === 'DENIED' ? (
                        <TextStyle
                          variant="base-medium"
                          className="max-w-20 text-center"
                        >
                          -
                        </TextStyle>
                      ) : (
                        <>
                          {row.original.canApprove ? (
                            <div className="flex items-center justify-start gap-4">
                              <IconButton
                                variation="secondaryLite"
                                className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg border-none bg-success-1 p-0"
                                onClick={() =>
                                  handleShowConfirmationApprovalModal(
                                    'approve',
                                    row.original
                                  )
                                }
                              >
                                <CheckIcon className="h-4 w-4 text-success-7" />
                              </IconButton>
                              <IconButton
                                variation="secondaryLite"
                                className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg border-none bg-error-1 p-0"
                                onClick={() =>
                                  handleShowConfirmationApprovalModal(
                                    'deny',
                                    row.original
                                  )
                                }
                              >
                                <XMarkIcon className="h-4 w-4 text-error-7" />
                              </IconButton>
                            </div>
                          ) : (
                            <Chip.Root
                              intent="awardGold"
                              color="gold"
                              className="border-upgrade-4"
                            >
                              <Chip.Text>
                                {formatMessage(messages.pending)}
                              </Chip.Text>
                            </Chip.Root>
                          )}
                        </>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>

      <ConfirmActionApprovalModal
        type="approve"
        handleClose={handleHiddenConfirmationApprovalModal}
        isOpen={showConfirmationApproval.approve}
        awardApprovalId={approvalSelected?.awardApprovalId ?? ''}
        submitApproval={submitApproval}
        isFinalApprovals={
          Number(approvalSelected?.approvals.levels) >=
          Number(approvalSelected?.approvals.levels.total)
        }
      />

      <ConfirmActionApprovalModal
        type="deny"
        handleClose={handleHiddenConfirmationApprovalModal}
        isOpen={showConfirmationApproval.deny}
        awardApprovalId={approvalSelected?.awardApprovalId ?? ''}
        submitApproval={submitApproval}
      />
      {approvalSelected != null && (
        <ViewAwardPost
          key={approvalSelected.awardApprovalId}
          award={approvalSelected}
          isOpen={showViewAward}
          onClose={() => {
            setShowViewAward(false);
          }}
          handleApproval={(type: 'approve' | 'deny') =>
            handleShowConfirmationApprovalModal(type, approvalSelected)
          }
          {...awardPost}
          approvalState={{
            action: selectedAction,
            status: submitApproval.status,
          }}
        />
      )}
    </>
  );
};
