import { isTruthy } from '@assembly-web/services';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useComposedRefs } from '@radix-ui/react-compose-refs';
import {
  Close,
  Content,
  Overlay,
  Portal,
  Root,
  Title,
} from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import type { ReactNode } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { RefProvider } from '../../../context/RefContext';
import { OverflowText } from '../../../DesignSystem/Feedback/OverflowText';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';

export type ModalProps = {
  /**
   * If true, the modal will focus on an interactive element
   * with the `data-focus-on-first-mount` attribute within
   * the modal's content area on the first mount.
   * @default false
   */
  focusElementOnFirstMount?: boolean;

  dataTestId?: string;
  isOpen: boolean;
  onClose: () => void;
  title: ReactNode;
  icon?: ReactNode;
  children: ReactNode;
  ctaSection?: ReactNode;
  className?: string;
  ctaSectionClassName?: string;
  bodyClassName?: string;
  headerClassName?: string;
  subtitle?: ReactNode;
  showHeaderSeparator?: boolean;
  closeButtonSize?: 'small' | 'large' | 'xSmall';
  theme?: 'cyan' | '';
  size?: 'small' | 'medium' | 'large';
  secondaryTitle?: string;
  showOverlay?: boolean;
  disableInteractOutside?: boolean;
  bodyStyle?: Record<string, string>;
  onOpenAutoFocus?: (e: Event) => void;
};

const messages = defineMessages({
  close: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
});

export const V3Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      closeButtonSize = 'large',
      size = 'small',
      focusElementOnFirstMount,
      disableInteractOutside = false,
      onOpenAutoFocus,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useIntl();

    const [showOverlay, setShowOverlay] = useState(false);

    const [bodyRef, setBodyRef] = useState<HTMLDivElement | null>(null);
    const combinedRef = useComposedRefs(ref, setBodyRef);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const prevIsOpenRef = useRef(false);

    const device = useDeviceInfo();

    const notMobile = device.deviceType !== 'mobile';

    useEffect(() => {
      const wasJustOpened = !prevIsOpenRef.current && props.isOpen;
      prevIsOpenRef.current = props.isOpen;

      if (focusElementOnFirstMount && wasJustOpened) {
        setTimeout(() => {
          if (contentRef.current) {
            const itemToFocus = contentRef.current.querySelector<HTMLElement>(
              '[data-focus-on-first-mount]'
            );

            itemToFocus?.focus();
          }
        }, 0);
      }
    }, [focusElementOnFirstMount, props.isOpen]);

    useEffect(() => {
      const handleScroll = () => {
        if (contentRef.current && props.showOverlay) {
          const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
          setShowOverlay(
            scrollHeight > clientHeight &&
              scrollTop < scrollHeight - clientHeight - 1
          );
        }
      };

      const ref = contentRef.current;
      if (ref) {
        ref.addEventListener('scroll', handleScroll);
        handleScroll();
      }
      return () => ref?.removeEventListener('scroll', handleScroll);
      // intentional dependency on props.children to re-run the effect when the children change
    }, [props.children, props.showOverlay]);

    return (
      <RefProvider value={bodyRef}>
        <Root
          open={props.isOpen}
          onOpenChange={(open) => {
            if (!open) {
              props.onClose();
            }
          }}
        >
          <AnimatePresence>
            {Boolean(props.isOpen) && (
              <Portal forceMount>
                <Overlay
                  asChild
                  forceMount
                  className="fixed inset-0 bg-neutral-secondary bg-opacity-75"
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{
                      opacity: 0,
                      transition: { duration: 0.2, ease: 'easeIn' },
                    }}
                    transition={{ duration: 0.3, ease: 'easeOut' }}
                  />
                </Overlay>
                <Content
                  {...(disableInteractOutside
                    ? {
                        onInteractOutside: (e) => {
                          e.preventDefault();
                        },
                      }
                    : {})}
                  asChild
                  forceMount
                  onOpenAutoFocus={onOpenAutoFocus}
                >
                  <motion.div
                    initial={{
                      opacity: 0,
                      x: '-50%',
                      y: '-50%',
                      ...(notMobile && { scale: 0.95 }),
                    }}
                    animate={{
                      opacity: 1,
                      x: '-50%',
                      y: '-50%',
                      ...(notMobile && { scale: 1 }),
                    }}
                    exit={{
                      opacity: 0,
                      x: '-50%',
                      y: '-50%',
                      ...(notMobile && { scale: 0.95 }),
                      transition: { duration: 0.2, ease: 'easeIn' },
                    }}
                    transition={{ duration: 0.3, ease: 'easeOut' }}
                    className={twMerge(
                      'fixed left-[50%] top-[50%] max-w-[90vw] -translate-x-1/2 -translate-y-1/2 transform overflow-hidden rounded-2xl bg-gray-1 p-6 pt-4 text-left shadow-xl-down focus:outline-none sm:w-full',
                      size === 'small' && 'sm:max-w-lg',
                      size === 'medium' && 'sm:max-w-xl',
                      size === 'large' && 'sm:max-w-[849px]',
                      props.theme === 'cyan' && 'header-bg-modal-gradient',
                      props.className
                    )}
                    ref={combinedRef as never}
                  >
                    <header
                      className={twMerge(
                        'sticky top-0 flex h-fit w-full items-start justify-between',
                        props.headerClassName
                      )}
                    >
                      <Title asChild className="text-left leading-6">
                        <div className="flex items-center gap-x-2">
                          {isTruthy(props.icon) && (
                            <div className="flex-shrink-0">{props.icon}</div>
                          )}

                          <div className="flex w-full items-center gap-x-4">
                            <TextStyle
                              variant="xl-bold"
                              className="flex-shrink-0 text-gray-9"
                              as="span"
                            >
                              {props.title}
                            </TextStyle>
                            {Boolean(props.secondaryTitle) && (
                              <>
                                <div className="h-8 w-0.5 bg-gray-5"></div>
                                <OverflowText
                                  variant="xl-medium"
                                  className="truncate text-gray-9"
                                  tooltipClassName="h-auto break-all overflow-hidden"
                                  tooltipText={props.secondaryTitle}
                                >
                                  {props.secondaryTitle}
                                </OverflowText>
                              </>
                            )}
                          </div>
                        </div>
                      </Title>

                      <Close
                        asChild
                        className="flex-shrink-0"
                        onClick={props.onClose}
                      >
                        <IconButton
                          variation="tertiaryLite"
                          size={closeButtonSize}
                          data-testid="modalCloseButton"
                        >
                          <span className="sr-only">
                            {formatMessage(messages.close)}
                          </span>
                          <XMarkIcon
                            className={twMerge(
                              'h-4 w-4 flex-shrink-0',
                              closeButtonSize === 'large' && 'h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                        </IconButton>
                      </Close>
                    </header>
                    {Boolean(props.subtitle) && (
                      <TextStyle
                        variant="sm-regular"
                        className="py-4 text-start leading-[22px] text-gray-9"
                      >
                        {props.subtitle}
                      </TextStyle>
                    )}

                    <div
                      style={props.bodyStyle}
                      className={twMerge(
                        'max-h-[60vh] flex-1 overflow-y-auto pt-4 text-gray-9',
                        props.ctaSection && 'pt-2',
                        props.bodyClassName,
                        !notMobile ? 'flex-1' : ''
                      )}
                      ref={contentRef}
                    >
                      {props.children}
                      {Boolean(showOverlay) && (
                        <div className="pointer-events-none absolute bottom-14 left-0 right-0 h-36 bg-gradient-to-t from-gray-1 to-transparent" />
                      )}
                    </div>
                    {Boolean(props.ctaSection) && (
                      <div
                        className={twMerge(
                          'sticky bottom-0 flex h-fit w-full justify-between bg-gray-1 pt-6',
                          props.ctaSectionClassName
                        )}
                      >
                        {props.ctaSection}
                      </div>
                    )}
                  </motion.div>
                </Content>
              </Portal>
            )}
          </AnimatePresence>
        </Root>
      </RefProvider>
    );
  }
);

V3Modal.displayName = 'V3Modal';
