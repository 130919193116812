import { SVGReactComponents, swagShirtIcon } from '@assembly-web/assets';
import {
  type AssemblyCurrency,
  AssemblyCurrencyType,
  isTruthy,
} from '@assembly-web/services';
import { GiftIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import type { Dayjs } from 'dayjs';
import type { PropsWithChildren, ReactNode } from 'react';
import {
  defineMessages,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TooltipEnabledContext } from '../../../context/TooltipEnabledContext';
import { ProgressBar } from '../../../DesignSystem/Feedback/ProgressBar';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { mapHexCodeToEmoticon } from '../PostCard/PostCardHeader';

const messages = defineMessages({
  toSpend: {
    defaultMessage:
      '<label>To redeem</label><value>{currencyIcon} {earnedBalance}</value>',
    id: 'zbM/KH',
  },
  toGive: {
    defaultMessage:
      '<label>To give</label><value>{currencyIcon} {remainingAllowance}</value>',
    id: 'WekoaN',
  },
  swag: {
    defaultMessage:
      '<label>Swag</label><value>{currencyIcon} {swagPoints}</value>',
    id: 'V7C8hj',
  },
  progress: {
    defaultMessage: '{spentAllowance}/{totalAllowance} Given',
    id: 'krdOtW',
  },
  browseRewards: {
    defaultMessage: 'Browse rewards',
    id: '9oFXbF',
  },
  pointsExpiringSoon: {
    defaultMessage:
      'Points to give expire in {noOfDays, plural, one {{noOfDays, number} day} other {{noOfDays, number} days}}',
    id: 'MkYGR7',
  },
  heading: {
    defaultMessage: 'My {currency}',
    id: 'KjdnaW',
  },
});

export type PointsWidgetProps = {
  currentDate: Dayjs;
  nextCycleDate: Dayjs;
  canRedeem: boolean;
  currency: AssemblyCurrency;
  earnedBalance: number;
  hasAllowance: boolean;
  hasEarnedPoints: boolean;
  remainingAllowance: number;
  spentAllowance: number;
  totalAllowance: number;
  variant: 'compact' | 'full';
  onBrowseRewardsClick?: () => void;
  navigateToGiveRecognition: () => void;
  isPlanSwagEnable?: boolean;
  swagPoints?: number;
};

function PointsItemWrapper({
  children,
  isFullVersion,
}: PropsWithChildren & { isFullVersion: boolean }) {
  return (
    <div
      className={twMerge(
        'inline-flex w-full items-center justify-between rounded-lg bg-gray-3 p-2',
        !isFullVersion && 'flex-col items-baseline gap-4'
      )}
    >
      {children}
    </div>
  );
}

export function PointsWidget({
  currentDate,
  nextCycleDate,
  canRedeem,
  currency,
  earnedBalance,
  hasAllowance,
  hasEarnedPoints,
  remainingAllowance,
  spentAllowance,
  totalAllowance,
  variant,
  onBrowseRewardsClick,
  navigateToGiveRecognition,
  isPlanSwagEnable,
  swagPoints,
}: PointsWidgetProps) {
  const percentage = parseInt(`${(spentAllowance / totalAllowance) * 100}`);
  const { formatMessage } = useIntl();

  const isFullVersion = variant === 'full';

  const currencyIcon =
    currency.type === AssemblyCurrencyType.Custom ? (
      <img
        alt={currency.name}
        className="mr-0.5 h-3 w-3"
        src={currency.value}
      />
    ) : (
      mapHexCodeToEmoticon(currency.value)
    );

  const noOfDaysTillPointsExpiry = nextCycleDate.diff(currentDate, 'day') + 1;

  const isPointsExpiringSoon = noOfDaysTillPointsExpiry <= 7;

  return (
    <div className="flex w-full flex-col gap-2 rounded-2xl bg-gray-1 p-4 shadow-md-down @container/root">
      {isTruthy(isFullVersion) && (
        <TextStyle variant="xl-medium" className="pb-3 text-gray-9">
          {formatMessage(messages.heading, {
            currency: currency.pluralName,
          })}
        </TextStyle>
      )}
      <div
        className={twMerge(
          'flex w-full items-start justify-start gap-4',
          isFullVersion && 'flex-col'
        )}
      >
        {isTruthy(hasAllowance) && (
          <PointsItemWrapper isFullVersion={isFullVersion}>
            {formatMessage(messages.toGive, {
              currencyIcon,
              remainingAllowance: (
                <FormattedNumber value={remainingAllowance} />
              ),
              label: (text: ReactNode) => (
                <TextStyle className="text-gray-9">{text}</TextStyle>
              ),
              value: (text: ReactNode) => (
                <div
                  className={twMerge(
                    'flex items-center gap-2',
                    !isFullVersion && 'w-full flex-col items-baseline gap-4'
                  )}
                >
                  <TextStyle
                    variant="lg-medium"
                    className="inline-flex items-center gap-x-1 text-right text-gray-9"
                  >
                    {text}
                  </TextStyle>
                  {isTruthy(navigateToGiveRecognition) &&
                    isTruthy(hasAllowance) && (
                      <TooltipEnabledContext.Provider value={isFullVersion}>
                        <Tooltip
                          tooltipText={
                            <FormattedMessage
                              defaultMessage="Give recognition"
                              id="7HU4UR"
                            />
                          }
                        >
                          <IconButton
                            size="small"
                            className={twMerge(
                              'text-gray-1',
                              !isFullVersion && 'h-11 w-full px-3 py-1'
                            )}
                            onClick={navigateToGiveRecognition}
                          >
                            <SVGReactComponents.RecognitionIcon stroke="#fff" />
                            {!isFullVersion && (
                              <TextStyle variant="sm-medium">
                                <FormattedMessage
                                  defaultMessage="Recognize"
                                  id="wTlSk7"
                                />
                              </TextStyle>
                            )}
                          </IconButton>
                        </Tooltip>
                      </TooltipEnabledContext.Provider>
                    )}
                </div>
              ),
            })}
          </PointsItemWrapper>
        )}
        <div className="w-full rounded-lg bg-gray-3">
          {isTruthy(hasEarnedPoints) && (
            <PointsItemWrapper isFullVersion={isFullVersion}>
              {formatMessage(messages.toSpend, {
                currencyIcon,
                earnedBalance: <FormattedNumber value={earnedBalance} />,
                label: (text: ReactNode) => (
                  <TextStyle className="text-gray-9">{text}</TextStyle>
                ),
                value: (text: ReactNode) => (
                  <div
                    className={twMerge(
                      'flex items-center gap-2',
                      !isFullVersion && 'w-full flex-col items-baseline gap-4'
                    )}
                  >
                    <TextStyle
                      variant="lg-medium"
                      className="inline-flex items-center gap-x-1 text-right text-gray-9"
                    >
                      {text}
                    </TextStyle>
                    {isTruthy(onBrowseRewardsClick) &&
                      isTruthy(canRedeem && earnedBalance > 0) && (
                        <TooltipEnabledContext.Provider value={isFullVersion}>
                          <Tooltip
                            tooltipText={
                              <FormattedMessage
                                defaultMessage="Browse rewards"
                                id="9oFXbF"
                              />
                            }
                          >
                            <IconButton
                              size="small"
                              variation="secondaryEmphasized"
                              className={twMerge(
                                !isFullVersion && 'h-11 w-full px-3 py-1'
                              )}
                              onClick={onBrowseRewardsClick}
                            >
                              <GiftIcon className="h-4 w-4" />
                              {!isFullVersion && (
                                <TextStyle variant="sm-medium">
                                  <FormattedMessage
                                    defaultMessage="Rewards"
                                    id="y0EYpw"
                                  />
                                </TextStyle>
                              )}
                            </IconButton>
                          </Tooltip>
                        </TooltipEnabledContext.Provider>
                      )}
                  </div>
                ),
              })}
            </PointsItemWrapper>
          )}
          {isTruthy(isPlanSwagEnable) && (
            <PointsItemWrapper isFullVersion={isFullVersion}>
              {formatMessage(messages.swag, {
                currencyIcon,
                swagPoints: <FormattedNumber value={swagPoints ?? 0} />,
                label: (text: ReactNode) => (
                  <TextStyle className="text-gray-9">{text}</TextStyle>
                ),
                value: (text: ReactNode) => (
                  <div
                    className={twMerge(
                      'flex items-center gap-2',
                      !isFullVersion && 'w-full flex-col items-baseline gap-4'
                    )}
                  >
                    <TextStyle
                      variant="lg-medium"
                      className="inline-flex items-center gap-x-1 text-right text-gray-9"
                    >
                      {text}
                    </TextStyle>

                    <TooltipEnabledContext.Provider value={isFullVersion}>
                      <Tooltip
                        tooltipText={
                          <FormattedMessage
                            defaultMessage="Swag points"
                            id="GKffcm"
                          />
                        }
                      >
                        <IconButton
                          size="small"
                          variation="secondaryEmphasized"
                          className={twMerge(
                            !isFullVersion && 'h-11 w-full px-3 py-1'
                          )}
                        >
                          <img
                            src={swagShirtIcon}
                            className="h-4 w-4"
                            alt={''}
                          />
                          {!isFullVersion && (
                            <TextStyle variant="sm-medium">
                              <FormattedMessage
                                defaultMessage="Swag"
                                id="vHr5oJ"
                              />
                            </TextStyle>
                          )}
                        </IconButton>
                      </Tooltip>
                    </TooltipEnabledContext.Provider>
                  </div>
                ),
              })}
            </PointsItemWrapper>
          )}
        </div>
      </div>
      {isTruthy(hasAllowance) && isTruthy(isFullVersion) && (
        <>
          <ProgressBar
            progress={percentage}
            aria-label={`${spentAllowance}/${totalAllowance}`}
            indicatorBgColor={percentage >= 100 ? 'bg-error-6' : undefined}
            className="w-full min-w-[unset]"
          />
          <div className="w-full text-right">
            <TextStyle variant="sm-regular" className="text-gray-9">
              {formatMessage(messages.progress, {
                spentAllowance: <FormattedNumber value={spentAllowance} />,
                totalAllowance: <FormattedNumber value={totalAllowance} />,
              })}
            </TextStyle>
          </div>
        </>
      )}
      {isTruthy(hasAllowance) &&
        isTruthy(isPointsExpiringSoon) &&
        isTruthy(remainingAllowance > 0) && (
          <div className="inline-flex w-full flex-col items-center justify-start gap-3">
            <div className="inline-flex w-full items-start justify-start gap-1 rounded bg-upgrade-2 px-2 py-1.5">
              <div className="flex items-start justify-start gap-2.5 py-0.5">
                <InformationCircleIcon className="h-4 w-4 text-upgrade-6" />
              </div>
              <TextStyle variant="xs-regular">
                {formatMessage(messages.pointsExpiringSoon, {
                  noOfDays: noOfDaysTillPointsExpiry,
                })}
              </TextStyle>
            </div>
          </div>
        )}
    </div>
  );
}
