import { SplitFactory } from '@splitsoftware/splitio';
import type { SplitKey } from '@splitsoftware/splitio/types/splitio';
import type SplitIO from '@splitsoftware/splitio/types/splitio';

import { config } from '../config';
import { logger } from '../logger';

const defaultKey = 'anonymous';

let factory = SplitFactory({
  core: {
    authorizationKey: config.splitAuthKey,
    key: defaultKey,
  },
});

export function getSplitIOFactory(key?: string): SplitIO.IBrowserSDK {
  const splitFactory = SplitFactory({
    core: {
      authorizationKey: config.splitAuthKey,
      key: key ?? defaultKey,
    },
  });

  if (key) {
    factory = splitFactory;
  }

  return splitFactory;
}

export function getFeatureFlagTreatment(featureFlagName: string) {
  return factory.client().getTreatment(featureFlagName);
}

export async function getFeatureFlagTreatmentAsync(
  featureFlagName: string,
  key?: SplitKey
) {
  const splitClient = key ? factory.client(key) : factory.client();

  try {
    await splitClient.ready().catch((e) => {
      // The error needs to be propagated like this based on the documentation below.
      // https://github.com/splitio/javascript-client/blob/10.22.5/types/splitio.d.ts#L433-L452
      throw e;
    });
  } catch (error) {
    logger.warn(
      'Split SDK timed out',
      { featureFlagName, key },
      error instanceof Error ? error : undefined
    );
  }

  return splitClient.getTreatment(featureFlagName);
}
