import { forwardRef, Suspense } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { OverflowText } from '../../../DesignSystem/Feedback/OverflowText';

const messages = defineMessages({
  title: {
    defaultMessage: 'Give award',
    id: 'tXj5MO',
  },
});

function DefaultTitle() {
  const { formatMessage } = useIntl();

  return formatMessage(messages.title);
}

function TitleBlock(
  { name }: { name: string },
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <span className="inline-flex w-full items-baseline gap-4" ref={ref}>
      <OverflowText
        variant="xl-bold"
        as="span"
        className="w-64 text-wrap break-words text-gray-9 sm:w-[439px]"
      >
        {name ? (
          <Suspense fallback={<DefaultTitle />}>{name}</Suspense>
        ) : (
          <DefaultTitle />
        )}
      </OverflowText>
    </span>
  );
}

export const Title = forwardRef(TitleBlock);
