import {
  type AssemblyCurrency,
  AssemblyCurrencyType,
  formatToLocalDateTime,
  isTruthy,
  mapHexCodeToEmoticon,
  type MemberDetails,
  MemberState,
  type ReactionDetails,
} from '@assembly-web/services';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import type React from 'react';
import {
  type ElementRef,
  type MouseEvent,
  type MutableRefObject,
  type ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Avatar, AvatarSize } from '../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import type { ToolbarItem } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { useToolbarState } from '../../../DesignSystem/Feedback/Toolbar/useToolbarState';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { AnonymousAvatarIcon, LoaderIcon } from '../../assets/icons';
import { useIntersecting } from '../../hooks/useIntersecting';
import {
  AuthorButton,
  type MemberDetailsForViewProfile,
} from '../../Shared/AuthorButton';
import { sanitizeMessageContent } from '../../Shared/messageUtils';
import type { BoostOptions } from '../Editors/RepliesEditor/RepliesEditor';
import { ConversationCardToolbar } from './ConversationCardToolbar';
import { messages } from './messages';
import { ReactionsBar, type ReactionsBarProps } from './ReactionsBar';

export type ConversationCardMemberDetails<T> = (
  | T
  | {
      isAnonymous: true;
      memberID: string;
    }
) & {
  createdAt: string;
};

type BaseConversationCardProps = {
  isDynamicTranslationsAvailable?: boolean;
  translationElement?: ReactNode;
  cardId: string;
  isActiveCard?: boolean;
  isEdited?: boolean;
  isEditing?: boolean;
  isDeleting?: boolean;
  messageNode?: React.ReactNode;
  currentMemberId: string;
  canShowToolbar?: boolean;
  toolbarItems: ToolbarItem[];
  onMenuItemClick: (args: ToolbarItem) => void;
  onReactionClick: (emoji: ReactionDetails) => void;
  reactions?: ReactionDetails[];
  isGroupedReplyCard?: boolean;
  gifURL?: string;
  taggedUsers?: MemberDetails[];
  messageContent: string;
  resetScroll?: () => void;
  pointsEach?: {
    icon: AssemblyCurrency;
    points?: number;
  };
  index?: number;
  boostOptions?: BoostOptions;
  currencyDetails?: AssemblyCurrency;
  onMemberClick: (memberID: string) => void;
  boost?: { member: MemberDetails; points: number }[];
  containerRef?: MutableRefObject<HTMLElement | null>;
  handleInternalLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
};

type SingleConversationCardProps = BaseConversationCardProps & {
  variant: 'single';
  memberDetails: ConversationCardMemberDetails<
    MemberDetailsForViewProfile & {
      isAnonymous: false;
      name: string;
    }
  >;
};

type GroupedConversationCardProps = BaseConversationCardProps & {
  variant: 'grouped';
};

export type ConversationCardProps =
  | SingleConversationCardProps
  | GroupedConversationCardProps;

const fadeInAnimation = {
  hidden: { background: '#D6E4FF', borderColor: '#2F54EB' },
  visible: {
    background: '#FAFAFA',
    borderColor: '#D9D9D9',
  },
};

const ReactionsBarWrapper = (
  props: ReactionsBarProps & {
    reactedFromToolbar: boolean;
    cardRef: MutableRefObject<HTMLDivElement | null>;
    containerRef?: MutableRefObject<HTMLElement | null>;
    isCardInViewport: boolean;
  }
) => {
  const {
    reactedFromToolbar,
    cardRef,
    containerRef,
    isCardInViewport,
    ...tail
  } = props;

  const runOnce = useRef(false);

  useEffect(() => {
    if (!reactedFromToolbar || isCardInViewport || runOnce.current) {
      return;
    }

    runOnce.current = true;
    cardRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [cardRef, containerRef, isCardInViewport, reactedFromToolbar]);

  return <ReactionsBar {...tail} />;
};

export function ConversationCard(props: ConversationCardProps) {
  const cardRef = useRef<ElementRef<'div'>>(null);
  const observerElRef = useRef<ElementRef<'div'>>(null);
  const [reactedFromToolbar, setReactedFromToolbar] = useState(false);

  const { formatMessage } = useIntl();
  const {
    cardId,
    variant,
    messageContent = '',
    canShowToolbar,
    onMenuItemClick,
    onMemberClick,
    toolbarItems,
    onReactionClick,
    currentMemberId,
    isDeleting,
    isEditing,
    isEdited,
    isActiveCard,
    reactions,
    isGroupedReplyCard = false,
    gifURL,
    messageNode,
    resetScroll,
    pointsEach,
    index,
    containerRef,
    taggedUsers,
    currencyDetails,
    handleInternalLinkClick,
    boostOptions,
    boost,
    translationElement,
    isDynamicTranslationsAvailable,
    className,
  } = props;

  const isIntersecting = useIntersecting(
    observerElRef,
    useMemo(
      () => ({
        threshold: 0,
        root: containerRef?.current,
        rootMargin: '0px 0px -60px',
      }),
      [containerRef]
    )
  );

  const { getContainerProps, getToolbarProps } = useToolbarState();

  const isOptimisticCard = cardId.includes('comment');
  let currencyIcon;

  useEffect(() => {
    if (isOptimisticCard || isActiveCard) {
      cardRef.current?.scrollIntoView({ behavior: 'instant', block: 'start' });

      // Reset Scroll, resets the commentId to `undefined`
      // Delaying it by 2 seconds, so the fade in animation is not affected.
      setTimeout(() => {
        resetScroll?.();
      }, 2000);
    }
  }, [cardId, isActiveCard, isOptimisticCard, resetScroll]);

  const createdTimestamp = dayjs(
    new Date(variant === 'single' ? props.memberDetails.createdAt : '')
  ).fromNow();

  const getSubContentMessage = () => {
    if (isOptimisticCard) {
      return messages.ago;
    } else if (isEditing) {
      return messages.editingLabel;
    }

    return messages.ago;
  };

  if (pointsEach?.points)
    currencyIcon =
      pointsEach.icon.type === AssemblyCurrencyType.Custom ? (
        <img
          alt={pointsEach.icon.name}
          className="mr-0.5 h-3 w-3"
          src={pointsEach.icon.value}
        />
      ) : (
        mapHexCodeToEmoticon(pointsEach.icon.value)
      );

  return (
    <div
      {...getContainerProps()}
      className={twMerge(
        'relative',
        variant === 'single' && index !== 0 ? 'mt-4' : 'mt-1',
        isGroupedReplyCard && 'mt-0',
        isOptimisticCard && 'opacity-[0.9]',
        className
      )}
    >
      <motion.div
        ref={cardRef}
        className={twMerge(
          'grid grid-cols-[32px,1fr] gap-2 overflow-hidden',
          isGroupedReplyCard && 'grid grid-cols-[40px,1fr]'
        )}
        exit={
          isOptimisticCard || isGroupedReplyCard
            ? undefined
            : {
                height: 0,
                opacity: 0,
                transition: { duration: 0.5, delay: 0.2 },
              }
        }
        initial={false}
        id={cardId}
      >
        {variant === 'single' &&
          (!props.memberDetails.isAnonymous ? (
            <Avatar
              className={twMerge(
                props.memberDetails.memberState === MemberState.Deactivated &&
                  'grayscale',
                !isGroupedReplyCard && 'my-2'
              )}
              size={isGroupedReplyCard ? AvatarSize.Large : AvatarSize.Medium}
              name={props.memberDetails.name}
              image={props.memberDetails.image}
              memberID={props.memberDetails.memberID}
            />
          ) : (
            <Avatar
              name=""
              memberID=""
              image={AnonymousAvatarIcon}
              className={!isGroupedReplyCard ? 'my-2' : ''}
              size={isGroupedReplyCard ? AvatarSize.Large : AvatarSize.Medium}
            />
          ))}
        <motion.div
          initial={isActiveCard ? 'hidden' : false}
          animate={isActiveCard ? 'visible' : undefined}
          variants={fadeInAnimation}
          transition={{ delay: 0.5, duration: 1.5 }}
          className={twMerge(
            'relative col-start-2 flex min-w-[96px] cursor-default flex-col items-start gap-2 overflow-hidden rounded-lg border border-gray-5 bg-gray-2 px-4 py-3 [word-break:break-word] hover:!bg-gray-4',
            isGroupedReplyCard && 'cursor-pointer',
            isDeleting && 'bg-error-2',
            isEditing && 'border-primary-6 bg-primary-1'
          )}
        >
          <div>
            {variant === 'single' && (
              <div className="flex items-center">
                <div className="flex flex-nowrap items-center">
                  <AuthorButton
                    boostOptions={boostOptions}
                    respondent={
                      !props.memberDetails.isAnonymous
                        ? {
                            memberID: props.memberDetails.memberID,
                            firstName: props.memberDetails.firstName,
                            lastName: props.memberDetails.lastName,
                            memberState: props.memberDetails.memberState,
                            pronouns: props.memberDetails.pronouns,
                            email: props.memberDetails.email,
                            department: props.memberDetails.department,
                            jobTitle: props.memberDetails.jobTitle,
                            image: props.memberDetails.image,
                          }
                        : null
                    }
                    onClick={() => {
                      if (!isOptimisticCard && !props.memberDetails.isAnonymous)
                        props.onMemberClick(props.memberDetails.memberID);
                    }}
                    textVariant="base-medium"
                  />
                  {pointsEach?.points ? (
                    <div className="flex gap-1 text-gray-8">
                      <TextStyle variant="xs-regular">
                        {formatMessage(messages.pointsGiven)}
                      </TextStyle>
                      <TextStyle variant="xs-regular">{currencyIcon}</TextStyle>
                      <TextStyle variant="xs-regular">
                        {pointsEach.points}
                      </TextStyle>
                      <TextStyle variant="xs-regular" className="mr-1">
                        •
                      </TextStyle>
                    </div>
                  ) : null}
                  {!isOptimisticCard && (
                    <Tooltip
                      tooltipText={formatToLocalDateTime(
                        props.memberDetails.createdAt
                      )}
                    >
                      <TextStyle
                        variant="xs-regular"
                        className="flex-shrink-0 text-gray-8"
                      >
                        {formatMessage(getSubContentMessage(), {
                          timeCreatedAt: createdTimestamp,
                        })}
                      </TextStyle>
                    </Tooltip>
                  )}
                  {isTruthy(translationElement) &&
                    isTruthy(isDynamicTranslationsAvailable) && (
                      <>
                        <TextStyle variant="xs-regular" className="mx-1">
                          •
                        </TextStyle>
                        {translationElement}
                      </>
                    )}
                </div>
                {Boolean(isOptimisticCard) && (
                  <img
                    alt=""
                    src={LoaderIcon}
                    className="mx-1 h-4 w-4 animate-spin"
                  />
                )}
              </div>
            )}
            <div className="flex w-fit">
              {messageNode ? (
                <TextStyle className="flex-1 break-words text-gray-9">
                  {messageNode}
                </TextStyle>
              ) : (
                <TextStyle className="flex-1 cursor-text break-words text-gray-9">
                  {parse(
                    messageContent,
                    sanitizeMessageContent({
                      boostedUsers: boost?.length
                        ? boost.map((x) => x.member)
                        : undefined,
                      taggedUsers,
                      boostOptions,
                      formatMessage,
                      onMemberClick,
                      currencyDetails,
                      handleInternalLinkClick,
                    })
                  )}
                </TextStyle>
              )}
              {Boolean(isEditing) && variant === 'grouped' && (
                <TextStyle
                  variant="xs-regular"
                  className="ml-1 flex items-center text-gray-8"
                >
                  {formatMessage(messages.editingLabel)}
                </TextStyle>
              )}
              {variant === 'grouped' && Boolean(isOptimisticCard) && (
                <img
                  alt=""
                  src={LoaderIcon}
                  className="m-1 h-4 w-4 animate-spin"
                />
              )}
            </div>
            {Boolean(gifURL) && (
              <div className="flex items-center">
                <img src={gifURL} alt="" className="object-contain" />
              </div>
            )}
            {Boolean(isEdited) && (
              <TextStyle variant="xs-regular" className="text-gray-8">
                ({formatMessage(messages.edited)})
              </TextStyle>
            )}
            {reactions?.length ? (
              <ReactionsBarWrapper
                cardRef={observerElRef}
                containerRef={containerRef}
                isCardInViewport={isIntersecting}
                reactedFromToolbar={reactedFromToolbar}
                currentMemberId={currentMemberId}
                reactions={reactions}
                variant="compact"
                onReactionClick={onReactionClick}
              />
            ) : null}
            <div
              ref={observerElRef}
              className="absolute bottom-0 left-0 h-10"
            />
          </div>
        </motion.div>
      </motion.div>
      {Boolean(canShowToolbar) && (
        <ConversationCardToolbar
          {...getToolbarProps({
            onMenuItemClick(args) {
              onMenuItemClick(args);
            },
          })}
          className="absolute right-2 top-[-12px] w-auto"
          secondaryToolbarItems={toolbarItems}
          currentMemberId={currentMemberId}
          onReactionClick={(reaction) => {
            onReactionClick(reaction);
            setReactedFromToolbar(true);
          }}
        />
      )}
    </div>
  );
}
