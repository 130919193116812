import {
  type AssemblyCurrency,
  getIconElement,
  type GroupOrUserRecipientData,
  isTruthy,
} from '@assembly-web/services';
import { EyeSlashIcon } from '@heroicons/react/24/outline';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { AvatarSize } from '../../../DesignSystem/Feedback/Avatar';
import { OverflowText } from '../../../DesignSystem/Feedback/OverflowText';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Chip } from '../../../DesignSystem/Inputs/Chip';
import type { PostCardProps } from '../../Shared/PostCard/PostCardUI';
import { Recipients } from '../Recipients';

export type MetaProps = {
  recipients?: GroupOrUserRecipientData[];
  coreValue?: string;
  points?: number;
  hiddenPoints?: boolean;
  currency: AssemblyCurrency;
  onPostCardHeaderClick?: PostCardProps['onPostCardHeaderClick'];
};

const messages = defineMessages({
  hiddenPoints: {
    defaultMessage:
      'Points are hidden from public view and only visible to the recipient(s), the giver, and admins.',
    id: 'Khk/de',
  },
  pointsChipText: {
    defaultMessage: '+<icon></icon>{points}',
    id: 'azm0Zv',
  },
});

export function Meta({
  coreValue,
  hiddenPoints,
  points,
  recipients,
  currency,
  onPostCardHeaderClick,
}: MetaProps) {
  const { formatMessage } = useIntl();

  if (!recipients && !coreValue && !points) {
    return null;
  }

  return (
    <section className="flex flex-col gap-4 px-6 pt-4">
      <div
        className={twJoin(
          'flex flex-wrap items-center gap-x-2 gap-y-4 @md:flex-nowrap @md:gap-2',
          isTruthy(recipients) && '[&>*]:first:flex-shrink-0 [&>*]:first:pr-2'
        )}
      >
        {isTruthy(recipients) && (
          <Recipients
            recipients={recipients}
            type="avatar"
            size={AvatarSize.Large}
            onPostCardHeaderClick={onPostCardHeaderClick}
          />
        )}
        {isTruthy(coreValue) && (
          <Chip.Root intent="badge" color="purple" className="break-words py-2">
            <Chip.Text asChild className="max-w-[32ch] text-base !font-medium">
              <OverflowText
                as="span"
                className="truncate"
                tooltipText={coreValue}
              >
                {coreValue}
              </OverflowText>
            </Chip.Text>
          </Chip.Root>
        )}
        {isTruthy(points) && (
          <Tooltip
            tooltipText={
              hiddenPoints ? formatMessage(messages.hiddenPoints) : undefined
            }
          >
            <Chip.Root intent="badge" color="gold" className="py-2">
              <Chip.Text className="inline-flex select-none items-center gap-2 text-base font-medium">
                <span>
                  {formatMessage(messages.pointsChipText, {
                    icon: () => getIconElement(currency),
                    points,
                  })}
                </span>
                {Boolean(hiddenPoints) && (
                  <EyeSlashIcon className="h-6 w-6 text-gray-9" />
                )}
              </Chip.Text>
            </Chip.Root>
          </Tooltip>
        )}
      </div>
      {isTruthy(recipients) && (
        <Recipients
          recipients={recipients}
          type="name"
          onPostCardHeaderClick={onPostCardHeaderClick}
        />
      )}
    </section>
  );
}
