import {
  useCurrency,
  useDisplayCurrency,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import type React from 'react';
import { type ReactNode, useCallback, useMemo } from 'react';
import { defineMessages, FormattedNumber, useIntl } from 'react-intl';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Checkbox } from '../../../DesignSystem/Inputs/Checkbox';
import { TextField } from '../../../DesignSystem/Inputs/TextField';

const messages = defineMessages({
  paymentDetails: {
    defaultMessage: 'Payment details:',
    id: 'S/usWt',
  },
  useSwagDesc: {
    defaultMessage:
      'Use swag {currencyName} = <bold>{currencyEmoji}{pointsAvailable} available</bold>',
    id: 'AEAWmn',
  },
  amountPointsUsage: {
    defaultMessage:
      '<bold>Swag {currencyName}</bold> of {currencyEmoji}{pointsApplied} applied',
    id: 'XuX1kj',
  },
  remainingPointsMustPaid: {
    defaultMessage:
      '+ <bold>{currencyEmoji}{remainingPointsMustPaid} {currencyName}</bold> of {currencyEmoji}{pointsApplied} applied / {currencyEmoji}{pointsRemaining} remaining',
    id: 'gL+52T',
  },
  totalPoints: {
    defaultMessage: '= <bold>{currencyEmoji}{totalPoints}</bold>',
    id: '8wiSwH',
  },
  youNotEnoughPoints: {
    defaultMessage: 'You do not have enough swag points to redeem.',
    id: 'WdWHYh',
  },
});

type SwagPointUsageProps = {
  isUsagePoints: boolean;
  setIsUsagePoints: React.Dispatch<React.SetStateAction<boolean>>;
  pointsUsage: string | number;
  setPointsUsage: React.Dispatch<React.SetStateAction<string | number>>;
  totalPoints: number;
};
export const SwagPointsUsage = ({
  isUsagePoints,
  setIsUsagePoints,
  pointsUsage,
  setPointsUsage,
  totalPoints,
}: SwagPointUsageProps) => {
  const { formatMessage } = useIntl();
  const { data: userDetails } = useSuspenseUserDetails();
  const currency = useCurrency();
  const preventDecimalNumber = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    return ['e', 'E', ',', '.'].includes(evt.key) && evt.preventDefault();
  };
  const currencyEmoji = useDisplayCurrency();
  const currencyName = useMemo(() => {
    return currency.pluralName;
  }, [currency.pluralName]);

  const handleChangeIsUsagePoints = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsUsagePoints(e.target.checked);
    },
    [setIsUsagePoints]
  );
  const pointsAvailable = useMemo(() => {
    return userDetails.member.swagPoints ?? 0;
  }, [userDetails.member.swagPoints]);

  const pointsApplied = useMemo(() => {
    return userDetails.member.pointsEarned;
  }, [userDetails.member.pointsEarned]);

  const remainingPointsMustPaid = useMemo(() => {
    if (totalPoints - Number(pointsUsage) < 0) {
      return 0;
    }
    return totalPoints - Number(pointsUsage);
  }, [pointsUsage, totalPoints]);

  const pointsRemaining = useMemo(() => {
    return pointsApplied - remainingPointsMustPaid;
  }, [pointsApplied, remainingPointsMustPaid]);

  const handleChangePointsUsage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const valueNumerical = Number(value);
      if (isNaN(valueNumerical)) {
        return;
      }
      if (valueNumerical > pointsAvailable || valueNumerical > totalPoints) {
        return;
      }
      setPointsUsage(value);
    },
    [pointsAvailable, setPointsUsage, totalPoints]
  );

  return (
    <div className="mb-4 space-y-4 text-gray-9">
      <TextStyle variant="base-medium">
        {formatMessage(messages.paymentDetails)}
      </TextStyle>

      <div className="flex items-center justify-start gap-2">
        <Checkbox
          className="h-6 w-6 cursor-pointer checked:before:!ml-[7px] checked:before:!mt-[1px] checked:before:h-4 checked:before:w-2 indeterminate:before:!ml-2.5 indeterminate:before:!mt-[6px] indeterminate:before:!border-t-[10px]"
          checked={isUsagePoints}
          onChange={handleChangeIsUsagePoints}
          disabled={pointsAvailable <= 0}
        />
        <TextStyle variant="base-regular">
          {formatMessage(messages.useSwagDesc, {
            currencyName,
            currencyEmoji,
            pointsAvailable: <FormattedNumber value={pointsAvailable} />,
            bold: (text: ReactNode) => {
              return <strong>{text}</strong>;
            },
          })}
        </TextStyle>
      </div>
      {pointsAvailable <= 0 && (
        <TextStyle variant="xs-medium" className="!mt-1 text-error-6">
          {formatMessage(messages.youNotEnoughPoints)}
        </TextStyle>
      )}
      {Boolean(isUsagePoints) && (
        <div className="space-y-4">
          <div className="flex items-center justify-start gap-2">
            <TextStyle variant="base-regular">+</TextStyle>
            <TextField
              value={pointsUsage}
              onChange={handleChangePointsUsage}
              type="text"
              className="max-w-36"
              onKeyDown={preventDecimalNumber}
            />
            <TextStyle variant="base-regular">
              {formatMessage(messages.amountPointsUsage, {
                currencyName,
                currencyEmoji,
                pointsApplied: <FormattedNumber value={Number(pointsUsage)} />,
                bold: (text: ReactNode) => {
                  return <strong>{text}</strong>;
                },
              })}
            </TextStyle>
          </div>
          <TextStyle variant="base-regular">
            {formatMessage(messages.remainingPointsMustPaid, {
              currencyName,
              currencyEmoji,
              remainingPointsMustPaid: (
                <FormattedNumber value={remainingPointsMustPaid} />
              ),
              pointsApplied: <FormattedNumber value={pointsApplied} />,
              pointsRemaining: <FormattedNumber value={pointsRemaining} />,
              bold: (text: ReactNode) => {
                return <strong>{text}</strong>;
              },
            })}
          </TextStyle>
          <TextStyle variant="base-regular">
            {formatMessage(messages.totalPoints, {
              currencyEmoji,
              totalPoints: <FormattedNumber value={totalPoints} />,
              bold: (text: ReactNode) => {
                return <strong>{text}</strong>;
              },
            })}
          </TextStyle>
        </div>
      )}
    </div>
  );
};
