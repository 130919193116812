import type { ReactionDetails } from '@assembly-web/services';

import { ReactionsBar } from '../PostAndReplies/ReactionsBar';

export type ReactionsProps = {
  currentMemberId: string;
  onReactionClick: (emoji: ReactionDetails) => void;
  reactions: ReactionDetails[];
};

export function Reactions({
  currentMemberId,
  onReactionClick,
  reactions,
}: ReactionsProps) {
  return (
    <section className="p-4 pl-6">
      <ReactionsBar
        currentMemberId={currentMemberId}
        onReactionClick={onReactionClick}
        reactions={reactions}
        variant="default-next"
        className="m-0"
      />
    </section>
  );
}
