import type { AssemblyCurrency, MemberDetails } from '@assembly-web/services';
import { forwardRef, type HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

import { Toolbar } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { useToolbarState } from '../../../DesignSystem/Feedback/Toolbar/useToolbarState';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';
import { PostCardBody } from '../../Shared/PostCard/PostCardBody';
import type { PostCardToolbarProps } from '../../Shared/PostCard/PostCardUI';
import { Divide } from './Divide';
import { Header, type HeaderProps } from './Header';
import { Reactions, type ReactionsProps } from './Reactions';
import { Replies, type RepliesProps } from './Replies';

type Base = {
  containerProps?: HTMLAttributes<HTMLDivElement>;
  headerProps?: Omit<HeaderProps, 'toolbar' | 'postType'>;
  currency: AssemblyCurrency;
  currentMember: MemberDetails;
  bodyProps: Omit<
    Parameters<typeof PostCardBody>[0],
    'currency' | 'isExpandedOnLoad'
  >;
  repliesProps?: Omit<RepliesProps, 'currentMember'>;
  readOnly?: boolean;
  reactionProps?: Pick<ReactionsProps, 'onReactionClick' | 'reactions'>;
  toolbarProps?: PostCardToolbarProps;
  showDivide?: boolean;
};

export const FlowPost = forwardRef<HTMLDivElement, Base>(function FlowPost(
  {
    containerProps: { className, ...containerProps } = {},
    headerProps,
    bodyProps,
    currency,
    currentMember,
    repliesProps,
    readOnly,
    reactionProps,
    toolbarProps = {},
    showDivide = true,
  },
  ref
) {
  const { getContainerProps, getToolbarProps } = useToolbarState({
    isAlwaysVisible: true,
    supportsTouch: true,
  });
  const isMobile = useDeviceInfo().isMobileDevice;
  const { onToolbarMenuItemClick, primaryToolbarItems, secondaryToolbarItems } =
    toolbarProps;

  return (
    <section ref={ref} {...containerProps} className="w-full">
      <div
        className={twMerge(
          'flex flex-col rounded-2xl bg-gray-1 pb-2 shadow-feed-post @container',
          readOnly && 'pointer-events-none select-none',
          className
        )}
        {...getContainerProps()}
      >
        {headerProps != null && (
          <Header
            {...headerProps}
            toolbar={
              !readOnly &&
              Boolean(secondaryToolbarItems ?? primaryToolbarItems) &&
              Boolean(onToolbarMenuItemClick) && (
                <>
                  {Boolean(!isMobile) && (
                    <Toolbar
                      className="w-fit"
                      secondaryToolbarItems={secondaryToolbarItems ?? []}
                      primaryToolbarItems={primaryToolbarItems}
                      hideWhenDetached
                      {...getToolbarProps({
                        onMenuItemClick: onToolbarMenuItemClick,
                      })}
                    />
                  )}
                  {Boolean(isMobile) && (
                    <Toolbar
                      className="w-fit"
                      secondaryToolbarItems={[
                        ...(primaryToolbarItems ?? []),
                        ...(secondaryToolbarItems ?? []),
                      ]}
                      hideWhenDetached
                      {...getToolbarProps({
                        onMenuItemClick: onToolbarMenuItemClick,
                      })}
                    />
                  )}
                </>
              )
            }
          />
        )}
        <div className="px-6">
          <PostCardBody
            currency={currency}
            onFileClick={bodyProps.onFileClick}
            flowResponse={bodyProps.flowResponse}
            onMemberClick={bodyProps.onMemberClick}
            onPostCardBodyClick={bodyProps.onPostCardBodyClick}
            onBlockToolbarItemClick={bodyProps.onBlockToolbarItemClick}
            isExpandedOnLoad
            canHidePoints={bodyProps.canHidePoints}
            handleInternalLinkClick={bodyProps.handleInternalLinkClick}
          />
        </div>
        {Boolean(showDivide) && <Divide />}
        {reactionProps != null && (
          <Reactions
            currentMemberId={currentMember.memberID}
            {...reactionProps}
          />
        )}
        {repliesProps != null && (
          <Replies currentMember={currentMember} {...repliesProps} />
        )}
      </div>
    </section>
  );
});
